import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { AccountService } from '../account.service';
import { CpUser } from '@squidcloud/console-common/types/account.types';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSettingsComponent {
  readonly userObs = this.accountService.observeUser();

  constructor(
    private readonly accountService: AccountService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
  ) {}

  signOut(): void {
    this.accountService.signOut();
  }

  showUpdateProfileNameDialog(user: CpUser): void {
    this.globalUiService
      .showDialogWithForm<{ name: string }>({
        title: 'Profile name',
        autoFocus: true,
        onSubmit: async res => {
          const name = res['name'];
          await this.accountService.updateName(name);
          this.snackBar.success('Profile name updated');
        },
        textLines: ['This will show in your profile'],
        submitButtonText: 'Update',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            label: 'Enter your profile name',
            defaultValue: user.name,
          },
        ],
      })
      .then();
  }
}
