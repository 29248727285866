<ng-container *ngVar="triggersDataSubject | async as triggersData">
  <ng-container *ngVar="transformersDataSubject | async as transformersData">
    <ng-container *ngVar="securityRulesDataSubject | async as securityRulesData">
      <div
        *ngIf="(triggersData?.headerRow?.length || transformersData?.headerRow?.length || securityRulesData?.headerRow?.length)"
        class="functions_container">
        <div class="functions_title_container">
          <div class="icon_and_title">
            <div class="icon_wrapper">
              <mat-icon class="icon" svgIcon="faders_icon"></mat-icon>
            </div>
            <div class="title">
              {{ title }}<span class="separator">//</span><span class="subtitle">Backend functions</span>
            </div>
          </div>
          <div class="readonly_and_chevron">
            <div class="read_only_badge">READ ONLY</div>
            <button (click)="expanded = !expanded"
                    [ngClass]="{ expanded: expanded }"
                    class="secondary_icon_button"
                    mat-flat-button>
              <mat-icon class="chevron_icon" svgIcon="chevron_right_icon"></mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="expanded"
             class="functions">
          <div *ngIf="triggersData?.headerRow?.length || transformersData?.headerRow?.length"
               [ngClass]="{ grid_2_container: triggersData?.headerRow?.length && transformersData?.headerRow?.length}"
               class="grid_container">
            <mini-bundle-data-table *ngIf="triggersData"
                                    [data]="triggersData"
                                    class="function_metadata"></mini-bundle-data-table>
            <mini-bundle-data-table *ngIf="transformersData"
                                    [data]="transformersData"
                                    class="function_metadata"></mini-bundle-data-table>
          </div>
          <mini-bundle-data-table *ngIf="securityRulesData"
                                  [data]="securityRulesData"
                                  class="function_metadata"></mini-bundle-data-table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
