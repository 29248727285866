<form
  class="magic_form"
  [ngClass]="variant"
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
>
  <div
    class="fields"
    [matTooltip]="data.disabled ? data.disabledText || '' : ''"
    [matTooltipPosition]="'above'"
  >
    @for (formElement of data.formElements; track formElement.nameInForm) {
      @switch (formElement.type) {
        @case ('input') {
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            appearance="outline"
            class="form_field"
          >
            <mat-label>{{ formElement.label }}</mat-label>
            <input
              [formControlName]="formElement.nameInForm"
              [name]="formElement.nameInForm"
              [type]="FormUtils.getInputType(formElement)"
              [readonly]="formElement.readonly"
              [matTooltip]="formElement.showErrorInTooltip ? this.getTooltip(form.controls[formElement.nameInForm]) : ''"
              [attr.data-testid]="getFormElementDataTestId(formElement)"
              autocomplete="off"
              matInput
            />
            <ng-container *ngIf="formElement.required" matSuffix>
              <div class="suffix_required_text">Required</div>
              <mat-icon
                class="suffix_check_icon"
                svgIcon="check_icon"
              ></mat-icon>
            </ng-container>
            <mat-hint *ngIf="formElement.hint">{{ formElement.hint }}</mat-hint>
          </mat-form-field>
        }
        @case ('textarea') {
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            appearance="outline"
            class="form_field textarea_form_field"
          >
            <mat-label>{{ formElement.label }}</mat-label>
            <textarea
              [formControlName]="formElement.nameInForm"
              [name]="formElement.nameInForm"
              [placeholder]="formElement.placeholder || ''"
              [cdkTextareaAutosize]="
                FormUtils.getTextAreaAttributes(formElement).autosize
              "
              [cdkAutosizeMinRows]="
                FormUtils.getTextAreaAttributes(formElement).minRows
              "
              [cdkAutosizeMaxRows]="
                FormUtils.getTextAreaAttributes(formElement).maxRows
              "
              [style.min-height]="
                (FormUtils.getTextAreaAttributes(formElement).minHeight || 0) +
                'px'
              "
              [attr.data-testid]="getFormElementDataTestId(formElement)"
              autocomplete="off"
              matInput
            ></textarea>
          </mat-form-field>
        }
        @case ('file') {
          <div
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            class="form_field textarea_form_field"
          >
            <file-upload
              [fileTypes]="FormUtils.getFileTypes(formElement)"
              (fileChange)="fileChanged(formElement, $event)"
            ></file-upload>
          </div>
        }
        @case ('select') {
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            appearance="outline"
            class="form_field"
          >
            <mat-label>{{ formElement.label }}</mat-label>
            <mat-select
              [formControlName]="formElement.nameInForm"
              class="select_box"
              [attr.data-testid]="getFormElementDataTestId(formElement)"
            >
              @for (option of FormUtils.getSelectOptions(formElement); track option.name) {
                <mat-option [value]="option.value" [attr.data-testid]="getSelectOptionDataTestId(option)">
                  {{ option.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @case ('boolean') {
          <div
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            class="form_field"
          >
            <div class="boolean_form_field">
              <div class="text_container">
                <div class="hint_wrapper">
                  <div class="label">{{ formElement.label }}</div>
                  <div
                    *ngIf="formElement.hint"
                    class="hint"
                    [innerHTML]="formElement.hint"
                  ></div>
                </div>
                <mat-slide-toggle
                  [formControlName]="formElement.nameInForm"
                  class="toggle_button"
                  color="primary"
                >
                </mat-slide-toggle>
              </div>

              <div
                *ngIf="formElement.description"
                class="description"
                [innerHTML]="formElement.description"
              ></div>
            </div>
          </div>
        }
        @case ('secret') {
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{
              valid:
                form.controls[formElement.nameInForm].valid &&
                form.controls[formElement.nameInForm].touched,
              hidden: formElement.hidden,
              grouped: formElement.grouped
            }"
            [attr.data-testid]="getFormElementDataTestId(formElement)"
            appearance="outline"
            class="form_field"
          >
            <select-secret
              [formControlName]="formElement.nameInForm"
              [placeholder]="formElement.placeholder || 'Select secret'"
              [defaultValue]="getAsStringOrUndefined(formElement.defaultValue)"
              [required]="!formElement.hidden && formElement.required"
            ></select-secret>
          </mat-form-field>
        }
      }
    }
  </div>
  <div *ngIf="errorMessage" class="magic_form__error">{{ errorMessage }}</div>
  <div class="magic_form__buttons">
    <div
      *ngIf="data.onDelete"
      class="inline_block delete_button_wrapper"
      [matTooltip]="data.disabled ? data.disabledText || '' : ''"
    >
      <button
        (click)="onDelete()"
        class="delete_button warning_button xl_button"
        [disabled]="form.disabled || functionIsRunning"
        mat-stroked-button
        type="button"
      >
        Delete
      </button>
    </div>
    <button
      *ngIf="data.onCancel"
      (click)="onCancel()"
      [disabled]="functionIsRunning"
      class="close_button outline_button xl_button"
      mat-stroked-button
      type="button"
      [attr.data-testid]="getCancelButtonDataTestId()"
    >
      {{ data.cancelButtonText ?? 'Cancel' }}
    </button>
    <div
      class="inline_block"
      [matTooltip]="data.disabled ? data.disabledText || '' : ''"
    >
      <button
        [disabled]="form.disabled || form.invalid || functionIsRunning"
        class="confirm_button primary_button xl_button"
        mat-flat-button
        type="submit"
        [attr.data-testid]="getSubmitButtonDataTestId()"
      >
        <loading-indicator-overlay [isLoading]="functionIsRunning">
          {{ data.submitButtonText }}
        </loading-indicator-overlay>
      </button>
    </div>
  </div>
</form>
