<div *ngIf="paymentDetailsObs | async as paymentDetailsEnvelope" class="billing_details">
  <ng-container *ngVar="paymentDetailsEnvelope.paymentDetails as paymentDetails">
    <div class="input_containers">
      <div class="description_and_input_container" *ngIf="billingPlanObs | async as billingPlan">
        <div class="left">
          <div class="title">Your Business Plan</div>
          <div class="description">
            {{ getBillingPlanDescription(billingPlan.templateId) }}
          </div>
        </div>
        <div class="right">
          <input-card class="billing_plan_block">
            <div class="plan_text">{{ billingPlan.name === 'Standard' ? 'Free' : billingPlan.name }}</div>
            <div class="buttons">
              @if (isBillingEditable) {
                <button class="button secondary_button medium_button" mat-flat-button
                        (click)="showBillingPlansModal(billingPlan)">
                  Update
                </button>
              }
            </div>
          </input-card>
        </div>
      </div>
      <div class="description_and_input_container" *ngIf="isBillingEditable">
        <div class="left">
          <div class="title">Payment method</div>
          <div class="description">
            Add a credit card to continue using Squid after you have reached your request limit
          </div>
        </div>
        <div class="right">
          <div class="payment_details_block no_payment_details_wrapper" *ngIf="!paymentDetails">
            <div class="icon_and_payment_details">
              @for (cardIcon of cardIconsToShow; track cardIcon) {
                <mat-icon class="card_icon" [svgIcon]="cardIcon" />
              }
            </div>
            <div class="buttons">
              <button class="button primary_button medium_button" mat-flat-button
                      (click)="showSavePaymentDetailsDialog()">
                Add Credit Card
              </button>
            </div>
          </div>

          <input-card *ngIf="paymentDetails" class="payment_details_block payment_details_wrapper">
            <div class="icon_and_payment_details">
              <mat-icon class="card_icon" [svgIcon]="getBrandIcon(paymentDetails)"></mat-icon>
              <div class="card_text">**** **** **** {{ paymentDetails.last4 }}</div>
            </div>

            <div class="buttons">
              <button class="button secondary_button medium_button" mat-flat-button
                      (click)="showSavePaymentDetailsDialog()">
                Update
              </button>
            </div>
          </input-card>


        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="organizationService.currentOrganization$ | async as organization">
  <div *ngIf="billingService.showBillsFlag$ | async" class="bills_block">
    <organization-bills-list [organizationId]="organization.id"></organization-bills-list>
  </div>
</ng-container>
