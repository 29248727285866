import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { CopyCodeComponent } from '../copy-code/copy-code.component';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocComponent implements OnInit {
  readonly CopyCodeComponent = CopyCodeComponent;
  @Input()
  markdownFileUrl!: string;

  @Input()
  replaceableParams: Record<string, string | undefined> = {};

  @Input()
  markdownData?: string;

  @Input()
  showCopyButton = true;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    markdownService: MarkdownService,
  ) {
    markdownService.renderer.link = (href: string | null, _title: string | null, text: string): string => {
      return `<a href="${href}" target="_blank">${text}</a>`;
    };
  }

  async ngOnInit(): Promise<void> {
    if (this.markdownData) {
      this.markdownData = this.replaceParams(this.markdownData);
      return;
    }
    let body = await firstValueFrom(this.httpClient.get(this.markdownFileUrl, { responseType: 'text' }));
    body = this.replaceParams(body);
    this.markdownData = body;
    this.cdr.markForCheck();
  }

  private replaceParams(markdownData: string): string {
    for (const [key, value] of Object.entries(this.replaceableParams)) {
      markdownData = markdownData.replace(new RegExp('\\${' + key + '}', 'g'), value || '');
    }
    return markdownData;
  }
}
