import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationType } from '@squidcloud/client';
import { Observable } from 'rxjs';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { IntegrationService } from '../integration.service';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaComponent extends OnDestroyComponent {
  readonly integrationObs: Observable<CpIntegration>;
  IntegrationType = IntegrationType;
  isNewSchema: boolean;

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly integrationService: IntegrationService,
  ) {
    super();
    const integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    this.isNewSchema = !!snapshot.paramMap.get('new');
    this.integrationObs = this.integrationService.observeIntegration(integrationId);
  }

  handleHeaderTemplateChange(template: TemplateRef<unknown>): void {
    this.headerTemplateChange.emit(template);
  }
}
