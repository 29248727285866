/** The list of regions in Squid. */
export const SQUID_REGIONS = ['us-east-1.aws', 'ap-south-1.aws', 'us-central1.gcp'] as const;

/** Region type used by Squid. */
export type SquidRegion = (typeof SQUID_REGIONS)[number];

/** @deprecated: Use 'SQUID_REGIONS'. */
export const SUPPORTED_SQUID_REGIONS = SQUID_REGIONS;

/** @deprecated: Use 'SquidRegion'. */
export type SupportedSquidRegion = SquidRegion;
