import { OrganizationId } from './organization.types';
import { QuotaName, RateLimitName } from '@squidcloud/internal-common/quota.types';
import { M3Bill } from '@squidcloud/console-common/types/m3-types';
import Stripe from 'stripe';

/** List of all billing plans IDs known by Squid Console app. */
export const ALL_BILLING_PLAN_TEMPLATE_IDS = [
  'AI_STANDARD_NO_PAYMENT_DETAILS',
  'AI_STANDARD',
  'DAILY_V1',
  'UNLIMITED',
] as const;
export type BillingPlanTemplateId = (typeof ALL_BILLING_PLAN_TEMPLATE_IDS)[number];

/** Billing plan selected by default for every new organization in Squid. */
export const DEFAULT_BILLING_PLAN_ID: BillingPlanTemplateId = 'AI_STANDARD_NO_PAYMENT_DETAILS';

/**
 * An object that describes a billing plan.
 * It describes basic metadata about the plan, such as the name, price, and description and also
 * describes the quotas that are associated with the plan.
 * For the quotas, if the value is `undefined`, then there is no limit on that quota.
 *
 * Each entry in the quotas will be copied to the organization's quotas when the organization is assigned with the
 * plan.
 * Each time the organization performs an action, the quota will be checked and updated with the new value.
 * For example, if the organization is assigned with a plan that has a quota of 100 chat requests, and the organization
 * performs a chat request, the quota will be updated to 99.
 *
 * ----------------------------------------------------------------------------------------------
 * Important: Once a billing plan becomes available, it can never change. If a change is needed, please create a new
 * plan.
 * ----------------------------------------------------------------------------------------------
 */
export interface BillingPlanTemplate {
  templateId: BillingPlanTemplateId;
  name: string;
  description: string;
  price: number;
  /**
   * Quotas renewed every billing period to their max values.
   *
   * - For organization quotas the quotas are renewed every first day of the billing period.
   * - For applications quotas the quotas are renewed every at the billing period rate aligned by the application
   *   creation date.
   */
  quotas: BillingPlanQuotas;
  /** Quotas provided once and never renewed. Must not intersect with `quotas` today within the same billing plan. */
  lifetimeQuotas?: BillingPlanQuotas;
  /** Per application rate limits. */
  rateLimits: BillingPlanRateLimits;
  billingPeriod: BillingPlanPeriod;
}

/**
 * Set of all quotas in a billing plans.
 * If a value for a quota is not defined, there is no quota: the usage is unlimited.
 */
export type BillingPlanQuotas = Partial<Record<QuotaName, number>>;

/**
 * Set of all limits in a billing plans.
 * If a value for a limit is not defined, there is no rate limit.
 */
export type BillingPlanRateLimits = Partial<Record<RateLimitName, number>>;

export const BILLING_PLAN_PERIOD_INTERVALS = ['day', 'month'] as const;
/**
 * Types of billing period intervals used by Squid.
 * - `day`: calendar day.
 * - `month`: calendar month. Example: Feb-3 to Mar-3.
 */
export type BillingPeriodInterval = (typeof BILLING_PLAN_PERIOD_INTERVALS)[number];

export interface BillingPlanPeriod {
  interval: BillingPeriodInterval;
  /** Count of intervals (days, months) in the period. */
  intervalCount: number;
}

export interface GetSavePaymentDetailsClientSecretRequest {
  organizationId: OrganizationId;
}

export interface GetPaymentDetailsRequest {
  organizationId: OrganizationId;
}

export interface GetPaymentDetailsResponse {
  type: 'card';
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

export interface SubscribeToBillingPlanRequest {
  organizationId: OrganizationId;
  planTemplateId: BillingPlanTemplateId;
}

export interface BillingPlan extends BillingPlanTemplate {
  updateDate: Date;
  creationDate: Date;
}

/** List of all billing types for organizations. */
export const ORGANIZATION_BILLING_TYPES = ['stripe', 'aws-marketplace', 'gcp-marketplace'] as const;
export type OrganizationBillingType = (typeof ORGANIZATION_BILLING_TYPES)[number];

export function isMarketplaceBillingType(billingType: OrganizationBillingType | undefined): boolean {
  if (!billingType) {
    return false;
  }
  switch (billingType) {
    case 'stripe':
      return false;
    case 'aws-marketplace':
    case 'gcp-marketplace':
      return true;
  }
}

export interface StripeInvoiceMetadata {
  billId: string;
  organizationId: string;
}

/** Bills < 50 cents are not sent to Stripe. */
export const MIN_BILL_TOTAL_IN_USD_TO_INVOICE_WITH_STRIPE = 0.5;

/** A bill will be approved and sent to Stipe in `BILL_APPROVE_DAYS` after the bill date. */
export const BILL_APPROVE_DAYS = 1;

/** A locked M3ter bill is sent to Stripe with the `BILL_DUE_DAYS` due date. */
export const BILL_DUE_DAYS = 7;

/** Name of the collection where Console app stores bills. */
export const BILLS_COLLECTION = 'bill';

/**
 * Console copy of the M3ter bill.
 * Used for logging & state tracking when the bill is sent to Stripe (M3ter does not support custom fields in M3Bill).
 *
 * The invoice is stored in Mongo only after it was sent to Stripe.
 * Important: not all M3 bills are sent to Stipe:
 *  - Bills with total amount < `MIN_BILL_TOTAL_IN_USD_TO_INVOICE_WITH_STRIPE`.
 *  - Bills for accounts with invalid payment methods.
 *  - Bills with other unexpected errors.
 */
export interface CpBill {
  /** Same as M3Bill.id. */
  id: string;
  /** Squid org this bill is for. Same as `m3Bill.accountCode`. */
  organizationId: string;
  /** Date the invoice was sent to Stripe. Same as `stripeInvoice.created`. */
  invoiceDate: Date;
  /** M3Bill state at the moment we sent the invoice to Stripe. */
  m3Bill: M3Bill;
  /** Invoice created in Stripe. */
  stripeInvoice: Stripe.Invoice;
}
