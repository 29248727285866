import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { truthy } from 'assertic';

export interface DocumentationDialogData {
  params: Record<string, string | undefined>;
  mdFilePath: string;
}

@Component({
  selector: 'app-documentation-dialog',
  templateUrl: './documentation-dialog.component.html',
  styleUrl: './documentation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentationDialogComponent {
  readonly params: Record<string, string | undefined>;
  readonly mdFilePath: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentationDialogData) {
    this.params = data.params;
    this.mdFilePath = truthy(data.mdFilePath, 'mdFilePath is required');
  }
}
