<mat-dialog-content class="contact_us_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_title">Contact Us</div>
  <div class="dialog_normal_text">Looking to talk to our team, see a live demo, or learn more?</div>
  <form (ngSubmit)="captchaRef.execute();" [formGroup]="form" class="form">
    <div class="form_fields">
      <mat-form-field *ngVar="form.controls['email'] as control"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Your email</mat-label>
        <input autofocus formControlName="email" matInput>
      </mat-form-field>

      <mat-form-field *ngVar="form.controls['name'] as control"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Your name</mat-label>
        <input formControlName="name" matInput>
      </mat-form-field>

      <mat-form-field *ngVar="form.controls['phone'] as control"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Contact number</mat-label>
        <input formControlName="phone" matInput>
      </mat-form-field>

      <mat-form-field *ngVar="form.controls['message'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field">
        <mat-label>Tell us more about your project, question, or timeline</mat-label>
        <textarea autocomplete="off" class="textarea" formControlName="message" matInput></textarea>
      </mat-form-field>

      <div class="request_demo_row">
        <div class="request_demo_label">Request A Demo</div>
        <mat-slide-toggle formControlName="requestDemo" color="primary"></mat-slide-toggle>
      </div>
    </div>
    <div class="dialog_buttons">
      <button [disabled]="form.invalid" class="confirm_button primary_button xl_button" mat-flat-button type="submit">
        Send
      </button>
    </div>
    <re-captcha
      #captchaRef="reCaptcha"
      id="recaptcha"
      size="invisible"
      (resolved)="captchaResolved($event)"
      (errored)="captchaError($event)"
    >
    </re-captcha>
  </form>
</mat-dialog-content>
