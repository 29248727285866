<div class="list_title">List of Bills</div>
<div *ngIf="!(bills$ | async)">Loading...</div>
<ng-container *ngIf="bills$ | async as bills">
  <div *ngIf="bills">

    <div *ngIf="bills.length === 0">
      <div class="bills_not_found_text">Bills not found</div>
    </div>

    <table mat-table [dataSource]="bills" [trackBy]="trackById" *ngIf="bills.length > 0" class="bills_table">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let bill; typedBy: bills">
          {{ bill.billDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let bill; typedBy: bills">
          {{ bill.formattedBillId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="billTotal">
        <th mat-header-cell *matHeaderCellDef class="bill_total_cell_header">Amount (USD)</th>
        <td mat-cell *matCellDef="let bill; typedBy: bills" class="bill_total_cell">
          {{ bill.billTotal }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="orderedTableColumnIds"></tr>
      <tr mat-row *matRowDef="let row; columns: orderedTableColumnIds;"></tr>
    </table>
  </div>
</ng-container>
