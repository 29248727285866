<ng-container *ngIf="integrationObs | async as integration">
  <ng-container *ngVar="collectionListObs | async as collectionList">
    <ng-container *ngVar="documentsObs | async as documents">
      <div class="db_browser_page">
        <div class="container">
          <portal class="flex_shrink_0" [emitter]="headerTemplateChange" [teleport]="true"
                  *ngIf="Integrations[integration.type] as integrationContent">
            <integration-header [integrationType]="integration.type"
                                [title]="integration.id"
                                [description]="integrationContent.description"
                                [withTabs]="true">
            </integration-header>
          </portal>
        </div>
        <div class="container browser_container">
          <div class="browser">
            <div class="pane collections_pane" [ngClass]="{expanded: collectionsExpanded}">
              <div class="pane_header">
                <div class="pane_left">
                  <mat-icon svgIcon="browser_collections_icon"></mat-icon>
                  <div class="pane_title" [hidden]="!collectionsExpanded">Collections</div>
                </div>
              </div>

              <div class="content" *ngVar="selectedCollectionNameSubject | async as selectedCollectionName">
                @for (collectionName of collectionList; track collectionName) {
                  <db-browser-row [label]="collectionName"
                                  [selected]="selectedCollectionName === collectionName"
                                  [expanded]="collectionsExpanded"
                                  (click)="selectCollection(collectionName)" />
                }
              </div>

              <div class="collapse_or_expand_container">
                <div class="background_overlay"></div>
                <div class="collapse_or_expand_button"
                     (click)="collectionsExpanded = !collectionsExpanded">
                  <mat-icon class="collapse_or_expand_icon" svgIcon="browser_expand_icon"
                            [ngClass]="{expanded: collectionsExpanded}"></mat-icon>
                </div>
              </div>
            </div>
            <ng-container *ngVar="filtersSubject | async as filters">
              <div class="pane documents_pane" [ngClass]="{expanded: documentsExpanded}"
                   *ngVar="sortOrdersSubject | async as sortOrders">
                <div class="pane_header">
                  <div class="pane_left">
                    <mat-icon svgIcon="browser_documents_icon"></mat-icon>
                    <div class="pane_title" [hidden]="!documentsExpanded">Documents</div>
                  </div>
                  <div class="pane_right" [ngClass]="{expanded: documentsExpanded}">
                    <div class="doc_button filter_action" title="Filter" (click)="showFilterDialog()"
                         [ngClass]="{active: !!filters.length}">
                      <mat-icon svgIcon="browser_filter_icon"></mat-icon>
                    </div>
                    <div class="doc_button sort_action" title="Sort" (click)="showSortDialog()"
                         [ngClass]="{active: !!sortOrders.length}">
                      <mat-icon svgIcon="browser_sort_icon"></mat-icon>
                    </div>
                  </div>
                </div>
                <div class="filters_and_sort" *ngIf="filters.length || sortOrders.length">
                  @for (filter of filters; track filter.name + ' ' + filter.operator + ' ' + filter.value; let i = $index) {
                    <db-browser-filter-bubble [label]="filter.name + ' ' + filter.operator + ' ' + filter.value"
                                              (clicked)="handleFilterClicked(filter, i, $event)"
                    ></db-browser-filter-bubble>
                  }
                  @for (sortOrder of sortOrders; track sortOrder.field + ' ' + sortOrder.sortOrder; let i = $index) {
                    <db-browser-filter-bubble [label]="sortOrder.field"
                                              (clicked)="handleSortOrderClicked(sortOrder, i, $event)"
                                              [icon]="sortOrder.sortOrder ? 'browser_ascending_icon' : 'browser_descending_icon'"
                    ></db-browser-filter-bubble>
                  }
                </div>
                <div class="content" *ngIf="selectedDocumentSubject | async as selectedDocument">
                  @for (document of documents; track getSquidDocumentId(document)) {
                    <db-browser-row
                      [label]="integration.type === IntegrationType.built_in_db ? document['__id'] : getSquidDocumentId(document)"
                      [selected]="getSquidDocumentId(document) === getSquidDocumentId(selectedDocument)"
                      [expanded]="documentsExpanded"
                      (click)="selectDocument(document)" />
                  }
                </div>
                <div class="collapse_or_expand_container">
                  <div class="background_overlay"></div>
                  <div class="collapse_or_expand_button"
                       (click)="documentsExpanded = !documentsExpanded">
                    <mat-icon class="collapse_or_expand_icon" svgIcon="browser_expand_icon"
                              [ngClass]="{expanded: documentsExpanded}"></mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="pane data_pane">
              <div class="pane_header">
                <div class="pane_left">
                  <mat-icon svgIcon="browser_data_icon"></mat-icon>
                  <div class="pane_title">Data</div>
                </div>
                <div class="pane_right" [ngClass]="{expanded: true}"
                     *ngIf="selectedDocumentSubject | async as selectedDocument">
                  <div class="view_as_json_toggle_wrapper">
                    <div class="text">View as JSON</div>
                    <mat-checkbox class="checkbox" [(ngModel)]="jsonViewEnabled"></mat-checkbox>
                  </div>

                  <button mat-flat-button class="copy_button button secondary_button medium_button"
                          (click)="copyJsonDate(selectedDocument)">
                    <mat-icon class="copy_icon" svgIcon="copy_icon"></mat-icon>
                    <div class="text">Copy</div>
                  </button>
                </div>
              </div>

              <div class="content" *ngIf="selectedDocumentSubject | async as selectedDocument">
                @if (jsonViewEnabled) {
                  <markdown [clipboard]="false" [data]="getJsonMarkdown(selectedDocument)" />
                } @else {
                  <app-json-editor [options]="editorOptions" [data]="selectedDocument"></app-json-editor>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
