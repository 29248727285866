import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalUiService } from '../global/services/global-ui.service';
import { SnackBarService } from '../global/services/snack-bar.service';
import { OrganizationService } from './organization.service';
import { truthy } from 'assertic';
import { getPageParameter, TAB_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { OrganizationId, OrganizationRole } from '@squidcloud/console-common/types/organization.types';
import { Location } from '@angular/common';
import { checkOrganizationHasQuotasInBillingPlan } from '@squidcloud/console-web/app/usage/quotas/quotas.component';

/** Tabs of the organization component. Order is important and should match the order in the template. */
const ORGANIZATION_COMPONENT_TAB_NAMES = ['details', 'members', 'usage', 'billing'] as const;
type OrganizationComponentTabName = (typeof ORGANIZATION_COMPONENT_TAB_NAMES)[number];

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationComponent {
  organizationObs = this.organizationService.observeCurrentOrganization();
  orgRoleObs = this.organizationService.observeRoleInCurrentOrg();

  isUsageTabVisible = true;
  selectedTabIndex = 0;
  protected tabRouteByIndex: ReadonlyArray<OrganizationComponentTabName> = [];

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly globalUiService: GlobalUiService,
    private readonly organizationService: OrganizationService,
    private readonly snackBar: SnackBarService,
    private readonly location: Location,
  ) {
    const organization = this.organizationService.getCurrentOrganization();
    this.isUsageTabVisible = !!organization && checkOrganizationHasQuotasInBillingPlan(organization.billingPlan);
    this.tabRouteByIndex =
      organization === undefined
        ? []
        : this.isUsageTabVisible
          ? ORGANIZATION_COMPONENT_TAB_NAMES
          : ORGANIZATION_COMPONENT_TAB_NAMES.filter(tab => tab !== 'usage');
    const tabIdParameter = getPageParameter(TAB_ID_PARAMETER, snapshot);
    this.selectedTabIndex = Math.max(this.tabRouteByIndex.indexOf(tabIdParameter as OrganizationComponentTabName), 0);
  }

  async showInviteMemberDialog(): Promise<void> {
    await this.globalUiService.showDialogWithForm<{ email: string; role: OrganizationRole }>({
      title: 'Invite Member',
      autoFocus: true,
      textLines: [],
      submitButtonText: 'Invite',
      formElements: [
        {
          type: 'input',
          required: true,
          nameInForm: 'email',
          label: 'Enter email',
          inputType: 'email',
        },
        {
          type: 'select',
          required: true,
          nameInForm: 'role',
          label: 'Choose role',
          defaultValue: 'ADMIN',
          options: [
            {
              name: 'Admin',
              value: 'ADMIN',
            },
            {
              name: 'Developer',
              value: 'DEVELOPER',
            },
          ],
        },
      ],
      onSubmit: async res => {
        const email = truthy(res.email);
        const role = truthy(res.role);
        try {
          await this.organizationService.createInvitation(email, role);
          this.snackBar.success('Member invited');
          return;
        } catch (error) {
          console.log('Error while creating invitation', error);
          const message = getMessageFromError(error);
          switch (message) {
            case 'ALREADY_MEMBER':
              return `${email} is already a part of the organization`;
            case 'ALREADY_INVITED':
              return `${email} had already been invited to the organization`;
            default:
              return `Unable to send invitation, please try again later`;
          }
        }
      },
    });
  }

  get organizationId(): OrganizationId {
    return this.organizationService.getCurrentOrganizationOrFail().id;
  }

  updateLocation(): void {
    let newPath = `/organization/${this.organizationId}`;
    if (this.tabRouteByIndex[this.selectedTabIndex]?.length > 0) {
      newPath += `/${this.tabRouteByIndex[this.selectedTabIndex]}`;
    }
    if (newPath !== this.location.path()) {
      this.location.go(newPath);
    }
  }
}
