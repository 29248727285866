import { isPlaywrightTestMode } from '@squidcloud/internal-common/utils/e2e-test-utils';

export function logE2eTestMessage(message: string | (() => string)): void {
  if (!isPlaywrightTestMode()) {
    return;
  }
  if (typeof message === 'function') {
    message = message();
  }
  console.log('[e2e]', message);
}
