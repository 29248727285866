<div *ngIf="currentApplicationObs | async as currentApplication">
  <div *ngIf="integrationsObs | async as integrations" class="current_integrations_page">
    <div class="current_and_popular_integrations">
      <div class="left">
        <div class="current_integrations">
          @for (integration of integrations; track integration.id) {
            <ng-container
              *ngVar="isBuiltInIntegrationType(integration.type) as isBuiltInIntegration">
              <ng-container *ngVar="showSchemaEmpty(integration) as showSchemaEmpty">
                <div
                  [ngClass]="{ built_in_integration: isBuiltInIntegration }"
                  class="integration_card">
                  <div class="top_part">
                    <div class="left_metadata">
                      <img [src]="'/assets/integrations/' + integration.type + '.svg'" class="integration_icon logo" />
                      <div class="id_and_state_container">
                        <integration-id class="id" [integrationId]="integration.id"></integration-id>
                        <div
                          [ngClass]="{built_in_integration: isBuiltInIntegration, empty_schema: showSchemaEmpty}"
                          class="state">
                          {{ isBuiltInIntegration ? 'DEFAULT' : showSchemaEmpty ? 'EMPTY SCHEMA' : 'ACTIVE' }}
                        </div>
                      </div>
                    </div>
                    <div class="right_metadata">
                      <button [matMenuTriggerData]="{element: integration}" [matMenuTriggerFor]="integrationMenu"
                              *ngIf="integration.type !== IntegrationType.built_in_queue && integration.type !== IntegrationType.built_in_s3 && integration.type !== IntegrationType.built_in_gcs"
                              class="secondary_icon_button"
                              mat-flat-button
                              [attr.data-testid]="integration.id + '-integration-menu-dots-button'"
                      >
                        <mat-icon class="menu_icon" svgIcon="menu_dots_icon"></mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="bottom_part">
                    <div class="created_date">Created: {{ integration.creationDate | date: 'medium' }}</div>
                    <div class="updated_date">Updated: {{ integration.updateDate | date: 'medium' }}</div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          }
        </div>
      </div>
      <div class="right">
        <div class="popular_integrations">
          <div class="popular_integrations_title">Popular Integrations</div>
          @for (integration of popularIntegrations; track integration.name) {
            <div class="popular_integration">
              <div class="logo_and_name">
                <img [src]="'/assets/integrations/' + integration.type + '.svg'" class="integration_icon logo" />
                <div class="name">{{ integration.name }}</div>
              </div>
              <div class="add_integration_button" *ngVar="('ADMIN' | minRole) as isAdmin"
                   [matTooltip]="!isAdmin ? 'You need to be an admin to add integrations' : ''">
                <button
                  [routerLink]="['/application', currentApplication.appId, 'integrations', 'add', integration.type]"
                  [disabled]="!isAdmin"
                  (click)="handleAddIntegration(integration.type)"
                  class="plus secondary_icon_button medium_button"
                  [attr.data-testid]="'popular-integration-' + integration.type + '-plus-button'"
                  mat-flat-button>
                  <mat-icon class="plus" svgIcon="plus_icon" />
                </button>
                <button
                  [routerLink]="['/application', currentApplication.appId, 'integrations', 'add', integration.type]"
                  [disabled]="!isAdmin"
                  (click)="handleAddIntegration(integration.type)"
                  class="add secondary_button medium_button" mat-flat-button>
                  Add
                </button>
              </div>
            </div>
          }
          <button
            [routerLink]="['/application', currentApplication.appId, 'integrations', 'available']"
            class="more_integrations_button primary_button large_button" mat-flat-button
            data-testid="more-intergrations-button"
          >
            More Integrations
          </button>
        </div>
      </div>
    </div>

    <mat-menu #integrationMenu="matMenu"
              class="integration_menu"
              xPosition="before">
      <ng-template let-element="element" matMenuContent [typeGuard]="{element: CpIntegration}">
        <ng-container *ngVar="getTabs(element) as tabs">
          <button *ngIf="tabs.includes('details')"
                  [routerLink]="['/application', currentApplication.appId, 'integration', element.id, 'edit']"
                  class="menu_item" mat-menu-item
                  data-testid="edit-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="edit_icon"></mat-icon>
            Edit Integration
          </button>
          <button *ngIf="tabs.includes('schema') || tabs.includes('readonly-schema')"
                  [routerLink]="['/application', currentApplication.appId, 'integration',element.id, 'schema']"
                  class="menu_item" mat-menu-item
                  data-testid="schema-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Schema
          </button>
          <button *ngIf="tabs.includes('db-browser')"
                  [routerLink]="['/application', currentApplication.appId, 'integration',element.id, 'db-browser']"
                  class="menu_item" mat-menu-item
                  data-testid="browse-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="browser_collections_icon"></mat-icon>
            Browse
          </button>
          <button *ngIf="tabs.includes('profiles')"
                  [routerLink]="['/application', currentApplication.appId, 'integration',element.id, 'profiles']"
                  class="menu_item" mat-menu-item
                  data-testid="profiles-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Profiles
          </button>
          <button *ngIf="tabs.includes('show-api-script')"
                  class="menu_item" mat-menu-item
                  data-testid="show-api-script-menu-button"
                  (click)="showApiScriptDialog(element.id)"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Show Creation Script
          </button>
          <ng-container
            *ngIf="element.type !== IntegrationType.built_in_db && ('ADMIN' | minRole)">
            <div class="menu_divider"
                 *ngIf="element.type !== IntegrationType.s3 && element.type !== IntegrationType.gcs"></div>
            <button
              (click)="showDeleteIntegrationDialog(element.id)"
              class="delete_integration menu_item red_menu_item" mat-menu-item
              data-testid="delete-integration-menu-button"
            >
              <mat-icon class="icon" svgIcon="trash_icon"></mat-icon>
              Delete
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-menu>
  </div>
</div>
