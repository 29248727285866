import { UserFeatureId } from '@squidcloud/internal-common/features/user-features';

/** Unique user id. Same as Auth0 user id. */
export type CpUserId = string;

/** Collection name where CpUser record is stored in the local db (mongo). */
export const USER_COLLECTION = 'user';

export interface CpUserUiPreferences {
  dismissedPaymentDetailsBanner?: boolean;
  sideBarCollapsed?: boolean;
}

export const USER_DETAILS_ROLES = [
  'fullstack-developer',
  'backend-developer',
  'frontend-developer',
  'product-manager',
  'engineering-manager',
  'sales-engineer',
] as const;

export type UserDetailsRole = (typeof USER_DETAILS_ROLES)[number];

export interface CpUserDetails {
  role?: UserDetailsRole | string;
}

/** Console user model. */
export interface CpUser {
  /** Unique user id. Provided by Auth0. */
  id: CpUserId;
  /** Unique user email. Stored in DB in a lower-case (normalized) form. */
  email: string;
  /** User display name in any form. The preferred form is a 'FirstName LastName'. Example: Sam Loudermilk.*/
  name: string;
  /** User avatar URL. An image resource. Example: https://www.gravatar.com/avatar/00000000000000000000000000000000?d=robohash. */
  picture?: string;
  /**
   * The date the user was registered in Squid Console.
   * If not defined the user was registered before May 2024.
   */
  creationDate?: Date;
  /** User UI settings. */
  uiPreferences?: CpUserUiPreferences;
  squidDeveloperId: string;
  /** List of features enabled for the user. */
  features?: Array<UserFeatureId>;
  /** List of attributes providing more details about the user */
  details?: CpUserDetails;
}

/**  A request model to create a new Console user. */
export interface CreateUserRequest {
  /** Unique user id. Same as in Auth0. */
  id: CpUserId;
  /** User display name. Derived from Auth0 attributes or email. It can be changed later. */
  name: string;
}

/** Request the backend to send a new email with an email verification link for a non-verified Auth0 user account. */
export interface SendEmailVerificationLinkRequest {
  /** Auth0 user 'sub'. */
  auth0Sub: string;
}
