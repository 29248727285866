import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ThemeService } from '../global/services/theme.service';
import { OnboardingService } from './onboarding.service';
import { fadeAnimation } from '@squidcloud/console-web/app/aminations';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { ONBOARDING_PAGE_PATH, ONBOARDING_TYPES } from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OnboardingService],
  animations: [fadeAnimation],
})
export class OnboardingComponent {
  index: number = 0;
  progress: number = 0;

  constructor(
    readonly themeService: ThemeService,
    readonly onboardingService: OnboardingService,
    private readonly analytics: AnalyticsService,
    private readonly organizationService: OrganizationService,
    private readonly navigationService: NavigationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly contexts: ChildrenOutletContexts,
    private readonly router: Router,
  ) {
    this.activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
      const { type, oid } = params;
      if (type && ONBOARDING_TYPES.includes(type)) {
        this.onboardingService.setType(type, this.router.url);
      }
      if (oid) {
        this.onboardingService.onboardingOrganizationId = oid;
      }
    });

    this.onboardingService.setPath(this.router.url);
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe(({ urlAfterRedirects }) => {
        this.onboardingService.setPath(urlAfterRedirects);
      });

    this.onboardingService
      .observeProgress()
      .pipe(takeUntilDestroyed())
      .subscribe(progress => {
        if (progress === this.progress) return;
        if (progress < this.progress) {
          this.index--;
        } else {
          this.index++;
        }
        this.progress = progress;
        this.cdr.markForCheck();
      });
  }

  getProgress(progress: number): string {
    return `${progress}%`;
  }

  async handleSkip(): Promise<void> {
    const navGuard = this.navigationService.newNavigationGuard();
    await this.organizationService.createFirstUserOrganizationIfNotExists();
    await navGuard.navigate(['/organization']);
    void this.analytics.track('CTA Clicked', { title: 'Skip', location: ONBOARDING_PAGE_PATH });
  }

  async handleBack(): Promise<void> {
    await this.router.navigate(['/organization']);
    void this.analytics.track('CTA Clicked', { title: 'Back to Console', location: ONBOARDING_PAGE_PATH });
  }
}
