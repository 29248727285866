import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import deepDiff from 'deep-diff';
import { assertTruthy } from 'assertic';
import merge from 'lodash/merge';
import { kebabCase } from 'change-case';
import { FormCallbackResponse, FormElement, FormSelectOption, FormUtils } from '@squidcloud/console-web/app/utils/form';

type FormVariant = 'dialog';

export interface FormConfig<FormDetailsType = Record<string, unknown>> {
  onSubmit?: (formDetails: FormDetailsType) => FormCallbackResponse;
  onDelete?: () => FormCallbackResponse;
  onCancel?: () => FormCallbackResponse;
  formElements: Array<FormElement>;
  submitButtonText: string;
  cancelButtonText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  disabledText?: string;
  variant?: FormVariant;
  // For Testing
  testPrefix?: string;
  submitButtonTestId?: string;
  cancelButtonTestId?: string;
}

@Component({
  selector: 'magic-form',
  templateUrl: './magic-form.component.html',
  styleUrls: ['./magic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagicFormComponent implements OnInit, OnChanges {
  errorMessage?: string;
  functionIsRunning = false;

  FormUtils = FormUtils;

  @Input({ required: true }) data!: FormConfig;

  form!: FormGroup;
  variant!: string;

  protected readonly trackByName = (_: number, item: FormElement): string => item.nameInForm;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    // The form is created in ngOnInit (after the first `ngOnChange` is called).
    if (this.form) {
      if (this.data.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  getTooltip(control: AbstractControl): string {
    if (control.valid || !control.touched) return '';

    for (const control of Object.values(this.form.controls)) {
      if (control.errors) {
        for (const error of Object.values(control.errors)) {
          return error;
        }
      }
    }
    return '';
  }

  ngOnInit(): void {
    const formControls: Record<string, FormControl> = {};

    for (const formElement of this.data.formElements) {
      const validators = FormUtils.getValidators(formElement);
      const defaultValue = FormUtils.getDefaultValue(formElement);
      formControls[formElement.nameInForm] = new FormControl(defaultValue, validators);
    }

    this.variant = this.data.variant || '';

    this.form = this.formBuilder.group(formControls);
    this.form.valueChanges.subscribe(data => {
      this.data.formElements.forEach((formElement, i) => {
        if (formElement.onChange) {
          const newElement = formElement.onChange({ ...formElement }, data);
          const diffs = deepDiff(formElement, newElement) || [];

          if (!diffs.length) return;

          merge(this.data.formElements[i], newElement);

          let typeChanged = false;
          let resetValidators = false;

          diffs.forEach(diff => {
            if (diff.path?.[0] === 'type') {
              typeChanged = true;
              resetValidators = true;
            }
            if (diff.path?.[0] === 'hidden' || diff.path?.[0] === 'extraValidators') {
              resetValidators = true;
            }
          });

          const control = formControls[newElement.nameInForm];

          if (resetValidators) {
            control.setValidators(FormUtils.getValidators(newElement));
          }
          if (typeChanged) {
            control.setValue(FormUtils.getDefaultValue(newElement), {
              emitEvent: false,
            });
          }

          control.updateValueAndValidity();
          this.cdr.markForCheck();
        }
      });
    });
  }

  async onCancel(): Promise<void> {
    if (!this.data.onCancel) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onCancel();
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  async onDelete(): Promise<void> {
    if (!this.data.onDelete) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onDelete();
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  async onSubmit(): Promise<void> {
    if (!this.form.valid) return;
    if (!this.data.onSubmit) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onSubmit(this.form.value);
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  fileChanged(formElement: FormElement, file: File | undefined): void {
    this.form.controls[formElement.nameInForm].setValue(file);
  }

  getAsStringOrUndefined(value: unknown): string | undefined {
    assertTruthy(value === undefined || typeof value === 'string', () => `Value not a string: ${value}`);
    return value;
  }

  getFormElementDataTestId(formElement: FormElement): string {
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-${kebabCase(formElement.nameInForm)}-${formElement.type}`;
  }

  getSelectOptionDataTestId(option: FormSelectOption): string {
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-${kebabCase(option.name)}-select-option`;
  }

  getSubmitButtonDataTestId(): string {
    if (this.data.submitButtonTestId) return this.data.submitButtonTestId;
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-submit-button`;
  }

  getCancelButtonDataTestId(): string {
    if (this.data.cancelButtonTestId) return this.data.cancelButtonTestId;
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-cancel-button`;
  }
}
