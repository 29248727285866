import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppId, EnvironmentId, IntegrationId, Squid } from '@squidcloud/client';
import {
  ALL_SQUID_ENVIRONMENTS,
  convertToSquidRegion,
  Environment,
} from '@squidcloud/console-common/clouds-and-regions';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { CreateApplicationDialogComponent } from '@squidcloud/console-web/app/application/create-application-dialog/create-application-dialog.component';
import { IpAddressesDocComponent } from '@squidcloud/console-web/app/application/ip-addresses-doc/ip-addresses-doc.component';
import { Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { environment } from '@squidcloud/console-web/environments/environment';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { AuthIntegrationConfig, isAuthIntegration } from '@squidcloud/internal-common/types/integrations/schemas';
import { AccountService } from '../../account/account.service';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { IntegrationUiService } from '../../integrations/integration-ui.service';
import { OrganizationService } from '../../organization/organization.service';
import { QuickStartService } from '../../quick-start/quick-start.service';
import { copyText } from '../../utils/copy-utils';
import { ApplicationService } from '../application.service';

@Component({
  selector: 'app-application-overview',
  templateUrl: './application-overview.component.html',
  styleUrls: ['./application-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationOverviewComponent implements OnInit {
  applicationObs = this.applicationService.observeCurrentApplication();
  organizationObs = this.organizationService.observeCurrentOrganization();
  userObs = this.accountService.observeUser();
  Integrations = Integrations;

  protected readonly squidSupportedRegionMap: {
    [p: string]: Environment;
  } = Object.fromEntries(
    ALL_SQUID_ENVIRONMENTS.map(r => [convertToSquidRegion(r.cloudId, r.cloudRegion, r.shard, r.stage), r]),
  );
  protected readonly environment = environment;
  protected readonly convertToSquidRegion = convertToSquidRegion;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly accountService: AccountService,
    private readonly organizationService: OrganizationService,
    private readonly snackBar: SnackBarService,
    private readonly globalUiService: GlobalUiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly integrationUiService: IntegrationUiService,
    private readonly router: Router,
    private readonly quickStartService: QuickStartService,
    private readonly dialog: MatDialog,
    private readonly analytics: AnalyticsService,
    private readonly squid: Squid,
  ) {}

  ngOnInit(): void {
    const firstPath = this.activatedRoute.snapshot.url?.[0]?.path;
    if (firstPath === 'quick-start') {
      setTimeout(() => {
        this.quickStartService.toggleExpanded();
      }, 100);
    }
  }

  showUpdateApplicationNameDialog(application: CpApplication): void {
    this.globalUiService
      .showDialogWithForm<{ name: string }>({
        title: 'Application Name',
        autoFocus: true,
        onSubmit: async res => {
          const name = res['name'];
          try {
            await this.applicationService.updateApplicationName(application.appId, name);
          } catch (e) {
            this.snackBar.warning('Unable to update application name');
            return;
          }
          this.snackBar.success('Application name updated');
        },
        textLines: ['The name of your application. This name can be updated.'],
        submitButtonText: 'Update',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            label: 'Enter your application name',
            defaultValue: application.name,
          },
        ],
      })
      .then();
  }

  showDeleteApplicationDialog(application: CpApplication): void {
    this.globalUiService
      .showDeleteDialog(
        `Unlike other actions, deleting an application cannot be undone or recovered. This will permanently delete the <span class="bold">${application.name}</span> application.`,
        async () => {
          try {
            await this.applicationService.deleteApplication(application.appId);
          } catch (error) {
            console.error('Failed to delete application', error);
            this.snackBar.warning('Failed to delete application. Please try again later');
            return;
          }

          // Ensure a new page title is visible. Otherwise, when current app is deleted and a page for the
          // next app in the organization is rendered it may look like nothing is changed on the page.
          document.getElementsByTagName('app-header')[0].scrollIntoView({ block: 'start' });

          this.snackBar.success('Application deleted');
        },
      )
      .then();
  }

  async copyId(id: AppId, message: string): Promise<void> {
    await copyText(id);
    this.snackBar.success(message);
  }

  async redirectToAuthProviderDialog(): Promise<void> {
    await this.router.navigate(['dialog/auth'], { relativeTo: this.activatedRoute });
  }

  async showDeleteAuthIntegrationConfirmation(integrationId: IntegrationId): Promise<void> {
    await this.integrationUiService.showDeleteIntegrationDialog(integrationId);
  }

  async regenerateApiKey(): Promise<void> {
    await this.globalUiService.showDeleteDialog(
      `Are you sure you want to regenerate your API key?`,
      async () => {
        try {
          await this.applicationService.regenerateApiKey();
          this.snackBar.success('API key regenerated');
        } catch (error) {
          console.error('Failed to regenerate a key', error);
          this.snackBar.warning('Unable to regenerate API key. Please try again later.');
        }
      },
      'Regenerate API key',
      'anchorsaweigh',
      'Regenerate',
    );
  }

  async copyApiKey(): Promise<void> {
    const apiKey = await this.applicationService.getApiKey();
    if (!apiKey) {
      this.snackBar.warning('Unable to copy API');
    }
    await copyText(apiKey);
    this.snackBar.success('API key copied to clipboard');
  }

  showInitializeBackendDialog(): void {
    this.applicationService.showInitializeBackendDialog();
    this.trackCtaClicked('Initialize Backend');
  }

  showCreateEnvFileDialog(): void {
    this.applicationService.showCreateEnvFileDialog();
    this.trackCtaClicked('Create .env file');
  }

  showEnvironmentVariablesDialog(): void {
    this.applicationService.showEnvironmentVariablesDialog();
    this.trackCtaClicked('Show env vars');
  }

  showDeployBackendDialog(): void {
    this.applicationService.showDeployBackendDialog();
    this.trackCtaClicked('Deploy Backend');
  }

  showUndeployBackendConfirmation(application: CpApplication): void {
    this.globalUiService
      .showDeleteDialog(
        `Undeploying your backend will delete all deployed resources, including security functionality. Any applications connected to your backend will be unable to access data and services connected to Squid.`,
        async () => {
          await this.squid.executeFunction('deleteApplicationCode', application.appId);
          this.snackBar.success('Backend undeployed');
        },
        undefined,
        undefined,
        'Undeploy',
      )
      .then();
    this.trackCtaClicked('Undeploy Backend');
  }

  getAuthIntegration(application: CpApplication): AuthIntegrationConfig | undefined {
    return Object.values(application.integrations).filter(isAuthIntegration)?.[0];
  }

  getCurrentEnvironment(application: CpApplication): EnvironmentId {
    return parseAppId(application.appId).environmentId;
  }

  showCreateApplicationDialog(): void {
    CreateApplicationDialogComponent.show(this.dialog);
  }

  trackCtaClicked(title: string): void {
    void this.analytics.track('CTA Clicked', { title, location: 'application-overview' });
  }

  showIpAddressesDialog(): void {
    this.globalUiService.showDocWithComponentDialog(IpAddressesDocComponent, false);
  }
}
