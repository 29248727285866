import { assertTruthy, truthy } from 'assertic';

const SPLIT_REGEX_FOR_GET_IN_PATH = /[.\[\]]/;

/** Returns a value by the `path`. Works with array indexes, like a.b[0]. */
export function getInPath(obj: unknown, path: string): any {
  if (!obj) {
    return undefined;
  }
  const splitPath = path.split(SPLIT_REGEX_FOR_GET_IN_PATH);
  let value: unknown = undefined;
  let currentObj: unknown = obj;
  while (currentObj && splitPath.length) {
    const key = splitPath.shift();
    if (!key) {
      continue;
    }
    if (typeof currentObj !== 'object' || !(key in currentObj)) {
      return undefined;
    }
    value = (currentObj as Record<string, unknown>)[key];
    currentObj = value;
  }
  return value;
}

function isJsObject(obj: unknown): boolean {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function isDateObject(value: unknown): value is Date {
  return Object.prototype.toString.call(value) === '[object Date]';
}

/** Sets a value by path . Does not support array indexes. */
export function setInPath(obj: object, path: string, value: unknown, delimiter = '.'): void {
  const splitPath = path.split(delimiter);
  let currentObj = obj as Record<string, unknown>;
  while (splitPath.length) {
    const key = truthy(splitPath.shift());
    if (splitPath.length) {
      const fieldValue = currentObj[key];
      const newCurrentObj = isJsObject(fieldValue) ? cloneShallow(fieldValue) ?? {} : {};
      currentObj[key] = newCurrentObj;
      currentObj = newCurrentObj as Record<string, unknown>;
    } else {
      currentObj[key] = value;
    }
  }
}

export function deleteInPath(obj: object, path: string, delimiter = '.'): void {
  const splitPath = path.split(delimiter);
  let currentObj = obj as Record<string, unknown>;
  while (splitPath.length) {
    const key = truthy(splitPath.shift());
    if (splitPath.length) {
      const newCurrentObj = isJsObject(currentObj[key]) ? cloneShallow(currentObj[key]) ?? {} : {};
      currentObj[key] = newCurrentObj;
      currentObj = newCurrentObj as Record<string, unknown>;
    } else {
      delete currentObj[key];
    }
  }
}

export function replaceKeyInMap<K, T>(map: Map<K, T | undefined>, a: K, b: K): void {
  if (map.has(a)) {
    const value = map.get(a);
    map.delete(a);
    map.set(b, value);
  }
}

export function replaceKeyInRecord<K extends keyof any, T>(record: Record<K, T>, a: K, b: K): void {
  const value = record[a];
  if (typeof value !== 'undefined') {
    record[b] = value;
    delete record[a];
  }
}

export function isNil(obj: unknown): obj is null | undefined {
  return obj === undefined || obj === null;
}

export function isEqual(a: unknown, b: unknown): boolean {
  if (a === b) return true;
  if (a === null || b === null) return false;

  const typeOfA = typeof a;
  if (typeOfA !== typeof b) return false;

  if (typeOfA !== 'object') return a === b;

  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  const keysA = Object.keys(a as object);
  const keysB = Object.keys(b as object);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !isEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key]))
      return false;
  }

  return true;
}

export function isEmpty(a: unknown): boolean {
  if (a === null || a === undefined) {
    return true;
  }

  if (typeof a === 'function') {
    return Object.keys(a).length === 0;
  }

  if (ArrayBuffer.isView(a) && !(a instanceof DataView)) {
    return a.byteLength === 0;
  }

  if (typeof a !== 'object' && !Array.isArray(a) && typeof a !== 'string') {
    return true;
  }

  if (Array.isArray(a) || typeof a === 'string' || (typeof a === 'object' && 'length' in a)) {
    return a.length === 0;
  }

  if (a instanceof Map || a instanceof Set) {
    return a.size === 0;
  }

  if (typeof a === 'object') {
    return Object.keys(a).length === 0;
  }

  return false;
}

export function omit<T extends object, K extends PropertyKey[]>(
  object: T | null | undefined,
  ...fieldsToRemove: K
): Pick<T, Exclude<keyof T, K[number]>> {
  if (object === null || object === undefined) {
    return {} as Pick<T, Exclude<keyof T, K[number]>>;
  }
  if (fieldsToRemove.length === 0) {
    return object;
  }
  const result = { ...object };
  for (const fieldToRemove of fieldsToRemove) {
    if (result.hasOwnProperty(fieldToRemove)) {
      delete (result as Record<PropertyKey, unknown>)[fieldToRemove];
    }
  }
  return result;
}

function cloneDeepArray<E = unknown>(array: Array<E>, customizer?: CloneCustomizer): Array<E> {
  const resultArray = new Array(array.length);
  for (let i = 0; i < array.length; i++) {
    resultArray[i] = cloneDeep(array[i], customizer);
  }
  return resultArray;
}

function copyBuffer(bufferLike: any): any {
  if (bufferLike instanceof Buffer) {
    return Buffer.from(bufferLike);
  }
  return new bufferLike.constructor(bufferLike.buffer.slice(), bufferLike.byteOffset, bufferLike.length);
}

export type CloneCustomizer = (value: unknown) => unknown | undefined;

/**
 * Creates a deep copy of the specified object, including all nested objects and specifically handling Date, Map, and
 * Set fields.
 *
 * The customizer function can modify the cloning process for the object and its fields. If the customizer
 * returns 'undefined' for any input, the function falls back to the standard cloning logic.
 *
 * The cloning process is recursive, ensuring deep cloning of all objects.
 *
 * Note: This function does not support cloning objects with circular dependencies and will throw a system stack
 * overflow error if encountered.
 */
export function cloneDeep<R = unknown>(value: R, customizer?: CloneCustomizer): R {
  // Can't use 'structuredClone' function here because it does not process prototype chains:
  // array fields of the object cloned with the 'structuredClone' have prototype different from Array.prototype,
  // and it cases some tests to fail.
  const customized = customizer ? customizer(value) : undefined;
  if (customized !== undefined) return customized as R;
  if (typeof value !== 'object' || value === null) return value;
  if (value instanceof Date) return new Date(value) as R;
  if (Array.isArray(value)) return cloneDeepArray(value, customizer) as R;
  if (value instanceof Map) return new Map(cloneDeepArray(Array.from(value), customizer)) as R;
  if (value instanceof Set) return new Set(cloneDeepArray(Array.from(value), customizer)) as R;
  if (ArrayBuffer.isView(value)) return copyBuffer(value);
  const result = {} as Record<string, unknown>;
  for (const k in value) {
    if (!Object.hasOwnProperty.call(value, k)) continue;
    result[k] = cloneDeep(value[k], customizer);
  }
  return result as R;
}

/** Creates a shallow clone of the object. */
export function cloneShallow<T>(value: T): T {
  if (typeof value !== 'object' || value === null) return value;
  if (value instanceof Date) return new Date(value) as T;
  if (Array.isArray(value)) return [...value] as T;
  if (value instanceof Map) return new Map(Array.from(value)) as T;
  if (value instanceof Set) return new Set(Array.from(value)) as T;
  return { ...value };
}

/** Compares 2 values. 'null' and 'undefined' values are considered equal and are less than any other values. */
export function compareValues(v1: unknown, v2: unknown): number {
  if (v1 === v2 || (isNil(v1) && isNil(v2))) {
    return 0;
  } else if (isNil(v1)) {
    return -1;
  } else if (isNil(v2)) {
    return 1;
  }
  const v1Type = typeof v1;
  const v2Type = typeof v2;
  if (v1Type !== v2Type) {
    return v1Type < v2Type ? -1 : 1;
  }
  if (typeof v1 === 'number') {
    assertTruthy(typeof v2 === 'number');
    if (isNaN(v1) && isNaN(v2)) return 0; // Consider NaNs as equal.
    if (isNaN(v1)) return -1; // NaN is considered less than any number.
    if (isNaN(v2)) return 1; // Any number is considered greater than NaN.
    return v1 < v2 ? -1 : 1;
  }
  if (typeof v1 === 'boolean') {
    assertTruthy(typeof v2 === 'boolean');
    return v1 < v2 ? -1 : 1;
  }
  if (typeof v1 === 'bigint') {
    assertTruthy(typeof v2 === 'bigint');
    return v1 < v2 ? -1 : 1;
  }
  if (typeof v1 === 'string') {
    assertTruthy(typeof v2 === 'string');
    return v1 < v2 ? -1 : 1;
  }
  if (v1 instanceof Date && v2 instanceof Date) {
    return Math.sign(v1.getTime() - v2.getTime());
  }
  return 0; // Fallback if types are not comparable.
}

/** Returns a new object with all top-level object fields re-mapped using `valueMapperFn`. */
export function mapValues<
  ResultType extends object = Record<string, unknown>,
  InputType extends Record<string, unknown> = Record<string, unknown>,
>(obj: InputType, valueMapperFn: (value: any, key: keyof InputType, obj: InputType) => unknown): ResultType {
  const result = {} as Record<string, unknown>;
  const keys = Object.keys(obj);
  for (const key of keys) {
    const value = obj[key];
    result[key] = valueMapperFn(value, key, obj);
  }
  return result as ResultType;
}

/** Groups elements of the array by key. See _.groupBy for details. */
export function groupBy<T, K extends PropertyKey>(array: T[], getKey: (item: T) => K): Record<K, T[]> {
  return array.reduce(
    (result, item) => {
      const key = getKey(item);
      if (!result[key]) {
        result[key] = [item];
      } else {
        result[key].push(item);
      }
      return result;
    },
    {} as Record<K, T[]>,
  );
}

/**
 * Picks selected fields from the object and returns a new object with the fields selected.
 * The selected fields are assigned by reference (there is no cloning).
 */
export function pick<T extends object, K extends keyof T>(obj: T, keys: ReadonlyArray<K>): Pick<T, K> {
  const result = {} as Pick<T, K>;
  for (const key of keys) {
    if (key in obj) {
      result[key] = obj[key];
    }
  }
  return result;
}

/** Inverts the record: swaps keys and values. */
export function invert<K extends string | number, V extends string | number>(record: Record<K, V>): Record<V, K> {
  const inverted: Record<V, K> = {} as Record<V, K>;
  for (const [key, value] of Object.entries(record)) {
    inverted[value as V] = key as K;
  }
  return inverted;
}

/**
 * Creates an array of numbers (positive and/or negative) progressing from start up to, but not including, end.
 * If end is less than start a zero-length range is created unless a negative step is specified.
 *
 * Same as lodash range but with an additional parameter: `maximumNumberOfItems`.
 */
export function range(start: number, end: number, step: number, maximumNumberOfItems = Infinity): number[] {
  const result: number[] = [];
  if (step === 0) {
    throw new Error('Step cannot be zero');
  }
  if ((step > 0 && start >= end) || (step < 0 && start <= end)) {
    return result;
  }
  for (let i = start; (step > 0 ? i < end : i > end) && result.length < maximumNumberOfItems; i += step) {
    result.push(i);
  }
  return result;
}
