import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { truthy } from 'assertic';

export interface DocumentationWithComponentDialogData {
  docComponent: unknown;
}

@Component({
  selector: 'app-documentation-with-component-dialog',
  templateUrl: './documentation-with-component-dialog.component.html',
  styleUrls: ['./documentation-with-component-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentationWithComponentDialogComponent {
  // eslint-disable-next-line
  readonly docComponent!: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentationWithComponentDialogData) {
    this.docComponent = truthy(data.docComponent);
  }
}
