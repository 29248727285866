<ng-container *ngIf="applicationObs | async as application">
  <ng-container *ngVar="bundleDataTableDataObs | async as bundleDataTableData">
    <div *ngIf="bundleDataTableData" class="table_container">
      <bundle-data-table [bundleDataTableData]="bundleDataTableData"></bundle-data-table>
      <div class="spec_link">
        The generated OpenAPI specification is available <a [href]="getOpenApiBaseUrl(application)+'/spec.json'"
                                                            target="_blank">here</a>
      </div>

    </div>
    <div class="functions_doc_wrapper" *ngIf="!bundleDataTableData">
      <openapi-tutorial />
    </div>
  </ng-container>
</ng-container>
