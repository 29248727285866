<div *ngIf="integrationObs | async as integration" class="graphql-schema">
  <ng-container *ngVar="schemaObs | async as schema">
    <portal class="flex_shrink_0" [emitter]="headerTemplateChange">
      <integration-header [integrationType]="integration.type"
                          [title]="integration.id">
        <ng-container buttons>
          <button (click)="showRediscoverSchemaDialog()"
                  [disabled]="!schema"
                  class="discover_schema secondary_button large_button"
                  mat-flat-button
                  type="submit">Rediscover Schema
          </button>
        </ng-container>
      </integration-header>
    </portal>

    <ng-container *ngIf="schema && graphqlUrl">
      <ng-container *ngIf="headersPromise | async as headers">
        <react-graphiql class="react-graphiql"
                        *ngIf="activatedSubject | async"
                        [url]="graphqlUrl"
                        [headers]="headers"
                        [integrationId]="integration.id"
                        [schema]="schema.introspection">
        </react-graphiql>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
