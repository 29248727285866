import { Injectable } from '@angular/core';
import { AppId, SecretEntry, SetSecretRequestEntry, Squid } from '@squidcloud/client';
import { filter, map, Observable } from 'rxjs';
import { ApplicationService } from '../application.service';
import { CpSecret } from '@squidcloud/console-common/types/application.types';
import { DeleteSecretRequest, SetSecretRequest } from '@squidcloud/internal-common/types/secret.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { serializeObjForBashCommand } from '@squidcloud/console-web/app/utils/serialization';

@Injectable({
  providedIn: 'root',
})
export class SecretService {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly squid: Squid,
  ) {}

  observeSecrets(): Observable<Record<string, CpSecret>> {
    return this.applicationService.observeCurrentApplication().pipe(
      filter(Boolean),
      map(application => application.secrets),
    );
  }

  async upsertSecret(key: string, value: string): Promise<void> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    const request: SetSecretRequest & { appId: AppId } = { entries: [{ key, value }], appId: application.appId };
    await callBackendExecutable(this.squid, 'setCustomSecret', request);
  }

  async deleteSecret(key: string): Promise<void> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    const request: DeleteSecretRequest & { appId: AppId } = { keys: [key], appId: application.appId };
    await callBackendExecutable(this.squid, 'deleteCustomSecret', request);
  }

  async getCreationScript(): Promise<string> {
    const appId = this.applicationService.getCurrentApplicationOrFail().appId;
    const appApiKey = await this.applicationService.getApiKey();
    const baseUrl = getApplicationUrl(this.squid.options.region, this.squid.options.appId, 'openapi');
    const { body: allSecrets } = await this.squid.api().get<Array<SecretEntry>>('consoleIac', 'ListSecrets', {
      pathParams: { appId },
      headers: { 'x-app-api-key': appApiKey },
    });
    const upsertRequest: Array<SetSecretRequestEntry> = allSecrets.map(secret => ({
      key: secret.key,
      value: secret.value,
    }));

    return `curl -X PUT ${baseUrl}/iac/applications/${appId}/secrets/upsert -H "x-app-api-key: ${appApiKey}" -H "Content-Type: application/json" -d '${serializeObjForBashCommand(
      upsertRequest,
    )}'`;
  }
}
