import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationRole } from '@squidcloud/console-common/types/organization.types';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';

export interface DialogWithForm<FormDetailsType = Record<string, unknown>>
  extends Omit<FormConfig<FormDetailsType>, 'onCancel' | 'variant' | 'disabled' | 'disabledText'> {
  title: string;
  textLines: string[];
  minRole?: OrganizationRole;
}

@Component({
  templateUrl: './dialog-with-form.component.html',
  styleUrls: ['./dialog-with-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWithFormComponent {
  formConfig: FormConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DialogWithForm,
    private readonly dialogRef: MatDialogRef<DialogWithFormComponent>,
  ) {
    const minRole = data.minRole || 'DEVELOPER';
    const {
      formElements,
      cancelButtonText,
      submitButtonText,
      autoFocus,
      testPrefix,
      submitButtonTestId,
      cancelButtonTestId,
    } = data;

    this.formConfig = {
      formElements,
      cancelButtonText,
      submitButtonText,
      autoFocus,
      disabled: !minRole,
      disabledText: 'You are not allowed to modify this form',
      onSubmit: data.onSubmit && this.onSubmit.bind(this),
      onCancel: this.onCancel.bind(this),
      onDelete: data.onDelete && this.onDelete.bind(this),
      variant: 'dialog',
      testPrefix: testPrefix || 'dialog-with-form',
      submitButtonTestId,
      cancelButtonTestId,
    };
  }

  async onDelete(): Promise<string | undefined | void> {
    if (!this.data.onDelete) {
      this.dialogRef.close();
      return;
    }
    const errorMessage = await this.data.onDelete();
    if (!errorMessage) {
      this.dialogRef.close();
      return;
    }
    return errorMessage;
  }

  async onSubmit(value: Record<string, unknown>): Promise<string | undefined | void> {
    if (!this.data.onSubmit) {
      this.dialogRef.close(value);
      return;
    }
    const errorMessage = await this.data.onSubmit(value);
    if (!errorMessage) {
      this.dialogRef.close(value);
      return;
    }

    return errorMessage;
  }

  async onCancel(): Promise<void> {
    this.dialogRef.close();
  }
}
