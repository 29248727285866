import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IntegrationId, IntegrationType } from '@squidcloud/client';
import { ApplicationService } from '../../application/application.service';
import { IntegrationUiService } from '../integration-ui.service';
import { IntegrationService } from '../integration.service';
import { Integrations, IntegrationTab, PopularIntegrations } from '../utils/content';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { map } from 'rxjs';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { typeGuard } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';

@Component({
  selector: 'current-integrations',
  templateUrl: './current-integrations.component.html',
  styleUrls: ['./current-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentIntegrationsComponent {
  IntegrationType = IntegrationType;

  popularIntegrations = PopularIntegrations;

  protected readonly CpIntegration = typeGuard<CpIntegration>();

  readonly integrationsObs = this.integrationService.observeIntegrations().pipe(
    map(integrations =>
      integrations.sort((a, b) => {
        const rankA =
          a.id === 'built_in_db' ? -3 : a.id === 'built_in_queue' ? -2 : a.id === 'built_in_storage' ? -1 : 0;
        const rankB =
          b.id === 'built_in_db' ? -3 : b.id === 'built_in_queue' ? -2 : b.id === 'built_in_storage' ? -1 : 0;
        if (rankA !== rankB) return rankA - rankB;
        return (a.creationDate || new Date()).getTime() - (b.creationDate || new Date()).getTime();
      }),
    ),
  );

  readonly currentApplicationObs = this.applicationService.observeCurrentApplication();

  constructor(
    private readonly integrationService: IntegrationService,
    private readonly integrationUiService: IntegrationUiService,
    private readonly applicationService: ApplicationService,
    private readonly analytics: AnalyticsService,
  ) {}

  showDeleteIntegrationDialog(integrationId: IntegrationId): void {
    this.integrationUiService.showDeleteIntegrationDialog(integrationId).then();
  }

  showApiScriptDialog(integrationId: IntegrationId): void {
    this.integrationUiService.showApiScriptDialog(integrationId).then();
  }

  showSchemaEmpty(integration: CpIntegration): boolean {
    if (
      ![IntegrationCategory.database, IntegrationCategory.api].includes(Integrations[integration.type].category) ||
      integration.type === IntegrationType.graphql ||
      integration.type === IntegrationType.built_in_db
    ) {
      return false;
    }
    return integration.schemaInitialized === false;
  }

  getTabs(integration: CpIntegration): Array<IntegrationTab> {
    return Integrations[integration.type].tabs || [];
  }

  handleAddIntegration(type: IntegrationType): void {
    void this.analytics.track('CTA Clicked', { title: 'Add Integration', type, location: 'current-integrations' });
  }

  isBuiltInIntegrationType(type: IntegrationType): boolean {
    return type.startsWith('built_in_');
  }
}
