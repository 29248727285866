<div *ngIf="bundleDataTableData as data" class="bundle_metadata_table_page">
  @if (hasChartData()) {
    <div class="charts_container">
      <app-chart [chart]="executionsChart!" class="chart chart1"></app-chart>
      <app-chart [chart]="latencyChart!" class="chart chart2"></app-chart>
      <app-chart [chart]="qpsChart!" class="chart chart3"></app-chart>
    </div>
  }

  <table mat-table [dataSource]="this.bundleDataTableData.rows" class="squid_mat_table bundle_data_table">
    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>

    @for (columnName of columnNames; track columnName; let i = $index) {
      <ng-container matColumnDef="{{columnName}}">
        <th mat-header-cell *matHeaderCellDef> {{ columnName }}</th>
        <td mat-cell *matCellDef="let row; typedBy: tableRowType">
          <a [ngClass]="[row.columns[i].link ? 'link' : '', row.columns[i].class || '']"
             [attr.href]="row.columns[i].link"
             [target]="row.columns[i].target ? row.columns[i].target : '_self'"
             [matTooltip]="row.columns[i].tooltip || ''">{{ row.columns[i].value }}
          </a>
        </td>
      </ng-container>
    }
  </table>
</div>
