<div class="dark page">
  <div class="card">
    <div class="card_content">
      <div class="logo_title_and_message">
        <div class="logo">
          <img [ngSrc]="iconType === 'error' ? 'assets/images/rudder_stressed.png' : 'assets/images/rudder_success.png'" alt="Logo" height="148" width="144" />
          <mat-icon *ngIf="iconType==='status'" svgIcon="short_logo_dark" class="logo_icon"></mat-icon>
        </div>
        <div class="title_and_message">
          <div class="title">{{ title }}</div>
          <div class="message">{{ message }}</div>
        </div>
      </div>
      <div class="button_row">
        <a mat-flat-button routerLink="/" class="primary_button large_button">{{ buttonLabel }}</a>
      </div>
    </div>
  </div>
</div>
