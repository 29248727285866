import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentReference } from '../document-reference';
import { DocumentReferenceFactory } from '../document-reference.factory';
import { BaseQueryBuilder, HasDereference, QueryBuilder, QueryBuilderFactory } from './query-builder.factory';
import { QuerySubscriptionManager } from './query-subscription.manager';
import {
  Alias,
  CollectionName,
  DocumentData,
  FieldName,
  FieldSort,
  IntegrationId,
  JoinCondition,
  Operator,
  PrimitiveFieldType,
  Query,
  SerializedJoinQuery,
  SimpleCondition,
} from '../public-types';
import { getSquidDocId } from '../../../internal-common/src/types/document.types';
import { cloneDeep, groupBy, mapValues } from '../../../internal-common/src/utils/object';
import { Pagination, PaginationOptions } from './pagination';
import { SnapshotEmitter } from './snapshot-emitter';

type WithDocumentReferences<T extends Record<any, DocumentData>> = {
  [k in keyof T]: DocumentReference<Required<T>[k]>;
};

export interface JoinFields<ReturnType> {
  left: FieldName;
  right: keyof ReturnType & FieldName;
}

export interface JoinOptions {
  leftAlias: Alias;
  isInner?: boolean;
}

type Grouped<
  Aliases extends Record<Alias, Alias[]>,
  ReturnType extends Record<Alias, any>,
  RootAlias extends Alias,
> = Aliases[RootAlias] extends []
  ? Required<ReturnType>[RootAlias]
  : Record<RootAlias, Required<ReturnType>[RootAlias]> & OtherGroups<Aliases, ReturnType, Aliases[RootAlias]>;

type OtherGroups<
  Aliases extends Record<Alias, Alias[]>,
  ReturnType extends Record<Alias, any>,
  ManyRootAliases extends Alias[],
> = ManyRootAliases extends [infer First extends Alias, ...infer Rest extends Alias[]]
  ? Record<First, Array<Grouped<Aliases, ReturnType, First>>> & OtherGroups<Aliases, ReturnType, Rest>
  : Record<Alias, never>;

// Interface used solely for @inheritDoc
interface HasGrouped {
  /**
   * Transforms this join query result to a nested data structure. For example, a join between teachers and students
   * normally returns a result of the form:
   *   [
   *     { teacher: {name: 'Mr. Smith'}, student: {name: 'John Doe'} },
   *     { teacher: {name: 'Mr. Smith'}, student: {name: 'Jane Smith'} },
   *     { teacher: {name: 'Mr. EmptyClass'}, student: undefined },
   *   ]
   * into a result of the form:
   *   [
   *    { teacher: {name: 'Mr. Smith'}, students: [
   *      { name: 'John Doe' },
   *      { name: 'Jane Smith' },
   *    ]},
   *    { teacher: {name: 'Mr. EmptyClass'}, students: [] },
   *   ]
   */
  grouped(): any;
}

/**
 * A query builder that can participate in a join.
 * To learn more about join queries, see the
 * {@link https://docs.squid.cloud/docs/development-tools/client-sdk/queries#joining-data-across-collections-and-integrations documentation}.
 */
export class JoinQueryBuilder<
    Aliases extends Record<Alias, Alias[]>,
    ReturnType extends Record<Alias, DocumentData>,
    LatestAlias extends Alias,
    RootAlias extends Alias,
  >
  extends BaseQueryBuilder<ReturnType>
  implements SnapshotEmitter<WithDocumentReferences<ReturnType>>, HasGrouped, HasDereference
{
  /**
   * @internal
   */
  constructor(
    private readonly collectionName: CollectionName,
    private readonly integrationId: IntegrationId,
    private readonly querySubscriptionManager: QuerySubscriptionManager,
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly queryBuilderFactory: QueryBuilderFactory,
    /** @internal */
    readonly rootAlias: RootAlias,
    private readonly latestAlias: Alias,
    /** @internal */
    readonly leftToRight: Aliases,
    private readonly joins: Record<Alias, Query<DocumentData>>,
    private readonly joinConditions: Record<Alias, JoinCondition>,
    private readonly queryBuilder: QueryBuilder<ReturnType[RootAlias]>,
  ) {
    super();
  }

  /**
   * Adds a condition to the query.
   *
   * @param fieldName The name of the field to query
   * @param operator The operator to use
   * @param value The value to compare against
   * @returns The query builder
   */
  where(
    fieldName: (keyof ReturnType[LatestAlias] & FieldName) | string,
    operator: Operator | 'in' | 'not in',
    value: PrimitiveFieldType | Array<PrimitiveFieldType>,
  ): this {
    this.queryBuilder.where(fieldName, operator, value);
    return this;
  }

  /**
   * Sets a limit to the number of results returned by the query. The maximum limit is 20,000 and the default is 1,000
   * if none is provided.
   *
   * @param limit The maximum number of results to return
   * @returns The query builder
   */
  limit(limit: number): this {
    this.queryBuilder.limit(limit);
    return this;
  }

  getLimit(): number {
    return this.queryBuilder.getLimit();
  }

  /**
   * Adds a sort order to the query. You can add multiple sort orders to the query. The order in which you add them
   * determines the order in which they are applied.
   * @param fieldName The name of the field to sort by
   * @param asc Whether to sort in ascending order. Defaults to true.
   * @returns The query builder
   */
  sortBy(fieldName: (keyof ReturnType[RootAlias] & FieldName) | string, asc = true): this {
    this.queryBuilder.sortBy(fieldName, asc);
    return this;
  }

  /**
   * Joins this query with another join query and return a new query builder that can be used to query the joined
   * documents.
   * @param queryBuilder The query builder to join with
   * @param alias TODO
   * @param joinFields TODO
   * @param options TODO
   * @returns A new query builder that can be used to query the joined documents
   */
  join<
    NewAlias extends string,
    NewReturnType extends DocumentData,
    LeftAlias extends Extract<keyof ReturnType, Alias>,
    IsInner extends boolean = false,
  >(
    queryBuilder: QueryBuilder<NewReturnType>,
    alias: Exclude<NewAlias, keyof ReturnType>,
    joinFields: {
      left: keyof Required<ReturnType>[LeftAlias] & FieldName;
      right: keyof NewReturnType & FieldName;
    },
    options: { leftAlias: LeftAlias; isInner?: IsInner },
  ): Omit<
    JoinQueryBuilder<
      Omit<Aliases, LeftAlias> & Record<LeftAlias, [...Aliases[LeftAlias], NewAlias]> & Record<NewAlias, []>,
      ReturnType & (IsInner extends true ? Record<NewAlias, NewReturnType> : Partial<Record<NewAlias, NewReturnType>>),
      NewAlias,
      RootAlias
    >,
    'limit' | 'getLimit'
  >;
  join<NewAlias extends string, NewReturnType extends DocumentData, IsInner extends boolean = false>(
    queryBuilder: QueryBuilder<NewReturnType>,
    alias: Exclude<NewAlias, keyof ReturnType>,
    joinFields: {
      left: keyof Required<ReturnType>[LatestAlias] & FieldName;
      right: keyof NewReturnType & FieldName;
    },
    options?: { isInner?: IsInner },
  ): Omit<
    JoinQueryBuilder<
      Omit<Aliases, LatestAlias> & Record<LatestAlias, [...Aliases[LatestAlias], NewAlias]> & Record<NewAlias, []>,
      ReturnType & (IsInner extends true ? Record<NewAlias, NewReturnType> : Partial<Record<NewAlias, NewReturnType>>),
      NewAlias,
      RootAlias
    >,
    'limit' | 'getLimit'
  >;
  join<NewAlias extends string, NewReturnType extends DocumentData>(
    queryBuilder: QueryBuilder<NewReturnType>,
    alias: Exclude<NewAlias, keyof ReturnType>,
    joinFields: JoinFields<NewReturnType>,
    options?: JoinOptions,
  ): Omit<
    JoinQueryBuilder<
      Record<Alias, Alias[]>,
      ReturnType & Partial<Record<NewAlias, NewReturnType>>,
      NewAlias,
      RootAlias
    >,
    'limit' | 'getLimit'
  > {
    const leftAlias = options?.leftAlias ?? this.latestAlias;
    const joinCondition: JoinCondition = {
      ...joinFields,
      leftAlias,
      isInner: options?.isInner ?? false,
    };

    const newAliases = {
      ...this.leftToRight,
      [alias]: [],
    };
    newAliases[leftAlias].push(alias);

    return new JoinQueryBuilder<
      Record<Alias, Alias[]>,
      ReturnType & Partial<Record<NewAlias, NewReturnType>>,
      NewAlias,
      RootAlias
    >(
      this.collectionName,
      this.integrationId,
      this.querySubscriptionManager,
      this.documentReferenceFactory,
      this.queryBuilderFactory,
      this.rootAlias,
      alias,
      newAliases,
      {
        ...this.joins,
        [alias]: queryBuilder.build(),
      },
      {
        ...this.joinConditions,
        [alias]: joinCondition,
      },
      this.queryBuilder as QueryBuilder<(ReturnType & Partial<Record<NewAlias, NewReturnType>>)[RootAlias]>,
    );
  }

  /** @inheritDoc */
  snapshot(): Promise<Array<WithDocumentReferences<ReturnType>>> {
    return firstValueFrom(this.snapshots(false));
  }

  /** @inheritDoc */
  snapshots(subscribe = true): Observable<Array<WithDocumentReferences<ReturnType>>> {
    if (this.queryBuilder.containsEmptyInCondition) {
      return new BehaviorSubject([]);
    }
    return this.querySubscriptionManager
      .processQuery(
        this.build(),
        this.rootAlias,
        cloneDeep(this.joins),
        cloneDeep(this.joinConditions),
        subscribe,
        false,
      )
      .pipe(
        map(docs =>
          docs.map(docRecord => {
            const result: Record<Alias, DocumentReference<DocumentData> | undefined> = {};
            for (const [alias, doc] of Object.entries(docRecord)) {
              const collectionName = alias === this.rootAlias ? this.collectionName : this.joins[alias].collectionName;
              const integrationId = alias === this.rootAlias ? this.integrationId : this.joins[alias].integrationId;
              const squidDocId = doc ? getSquidDocId(doc.__docId__, collectionName, integrationId) : undefined;
              result[alias] = squidDocId
                ? this.documentReferenceFactory.create(squidDocId, this.queryBuilderFactory)
                : undefined;
            }
            return result as WithDocumentReferences<ReturnType>;
          }),
        ),
      );
  }

  /**
   * @inheritDoc
   */
  peek(): Array<WithDocumentReferences<ReturnType>> {
    throw new Error('peek is not currently supported for join queries');
  }

  /** @inheritDoc */
  grouped(): GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    return new GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this);
  }

  /** @inheritDoc */
  dereference(): DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    return new DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this);
  }

  /**
   * @internal
   */
  build(): Query {
    return this.queryBuilder.build();
  }

  getSortOrders(): Array<FieldSort<any>> {
    return this.queryBuilder.getSortOrders();
  }

  clone(): JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias> {
    const res = new JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias>(
      this.collectionName,
      this.integrationId,
      this.querySubscriptionManager,
      this.documentReferenceFactory,
      this.queryBuilderFactory,
      this.rootAlias,
      this.latestAlias,
      cloneDeep(this.leftToRight),
      cloneDeep(this.joins),
      cloneDeep(this.joinConditions),
      this.queryBuilder.clone(),
    );
    return res;
  }

  addCompositeCondition(
    conditions: Array<SimpleCondition>,
  ): JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias> {
    this.queryBuilder.addCompositeCondition(conditions);
    return this;
  }

  flipSortOrder(): JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias> {
    this.queryBuilder.flipSortOrder();
    return this;
  }

  extractData(data: WithDocumentReferences<ReturnType>): Required<ReturnType>[RootAlias] {
    return data[this.rootAlias].dataRef;
  }

  serialize(): SerializedJoinQuery {
    return {
      type: 'join',
      grouped: false,
      dereference: false,
      root: {
        alias: this.rootAlias,
        query: this.build(),
      },
      leftToRight: this.leftToRight,
      joins: this.joins,
      joinConditions: this.joinConditions,
    };
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<WithDocumentReferences<ReturnType>> {
    if (this.hasIsInner()) {
      throw Error('Cannot paginate on joins when isInner is enabled.');
    }
    return new Pagination<WithDocumentReferences<ReturnType>>(this, options);
  }

  hasIsInner(): boolean {
    return !!Object.values(this.joinConditions).find(v => v.isInner);
  }
}

class DereferencedJoin<
    Aliases extends Record<Alias, Alias[]>,
    ReturnType extends Record<Alias, DocumentData>,
    RootAlias extends Alias,
    LatestAlias extends Alias,
  >
  implements SnapshotEmitter<ReturnType>, HasGrouped
{
  constructor(private readonly joinQueryBuilder: JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias>) {}

  /** @inheritDoc */
  grouped(): SnapshotEmitter<Grouped<Aliases, ReturnType, RootAlias>> {
    return this.joinQueryBuilder.grouped().dereference();
  }

  /** @inheritDoc */
  snapshot(): Promise<Array<ReturnType>> {
    return firstValueFrom(this.snapshots(false));
  }

  /** @inheritDoc */
  snapshots(subscribe?: boolean): Observable<Array<ReturnType>> {
    return this.joinQueryBuilder
      .snapshots(subscribe)
      .pipe(map(docs => docs.map(doc => mapValues(doc, fieldDoc => fieldDoc?.data))));
  }

  /**
   * @inheritDoc
   */
  peek(): Array<ReturnType> {
    throw new Error('peek is not currently supported for join queries');
  }

  getSortOrders(): Array<FieldSort<any>> {
    return this.joinQueryBuilder.getSortOrders();
  }

  clone(): DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    return new DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this.joinQueryBuilder.clone());
  }

  addCompositeCondition(
    conditions: Array<SimpleCondition>,
  ): DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.addCompositeCondition(conditions);
    return this;
  }

  flipSortOrder(): DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.flipSortOrder();
    return this;
  }

  /** @internal/ */
  limit(limit: number): DereferencedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.limit(limit);
    return this;
  }

  extractData(data: ReturnType): any {
    return data[this.joinQueryBuilder.rootAlias];
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<ReturnType> {
    if (this.joinQueryBuilder.hasIsInner()) {
      throw Error('Cannot paginate on joins when isInner is enabled.');
    }
    return new Pagination<ReturnType>(this, options);
  }

  serialize(): SerializedJoinQuery {
    return { ...this.joinQueryBuilder.serialize(), dereference: true };
  }

  /** @internal/ */
  getLimit(): number {
    return this.joinQueryBuilder.getLimit();
  }
}

class DereferencedGroupedJoin<
  Aliases extends Record<Alias, Alias[]>,
  ReturnType extends Record<Alias, DocumentData>,
  RootAlias extends Alias,
  LatestAlias extends Alias,
> implements SnapshotEmitter<Grouped<Aliases, ReturnType, RootAlias>>
{
  constructor(private groupedJoin: GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias>) {}

  /** @inheritDoc */
  snapshot(): Promise<Array<Grouped<Aliases, ReturnType, RootAlias>>> {
    return firstValueFrom(this.snapshots(false));
  }

  /** @inheritDoc */
  snapshots(subscribe?: boolean): Observable<Array<Grouped<Aliases, ReturnType, RootAlias>>> {
    return this.groupedJoin.snapshots(subscribe).pipe(
      map(docs => {
        return docs.map(doc => {
          return this.dereference(doc, this.groupedJoin.joinQueryBuilder.rootAlias);
        });
      }),
    );
  }

  /**
   * @inheritDoc
   */
  peek(): Array<Grouped<Aliases, ReturnType, RootAlias>> {
    throw new Error('peek is not currently supported for join queries');
  }

  private dereference(doc: Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>, rootAlias: Alias): any {
    const rights = this.groupedJoin.joinQueryBuilder.leftToRight[rootAlias];
    if (rights.length) {
      const ret: any = { [rootAlias]: (doc as any)[rootAlias].data };
      for (const right of rights) {
        ret[right] = (doc as any)[right].map((doc1: any) => this.dereference(doc1, right));
      }
      return ret;
    } else {
      return doc.data;
    }
  }

  /** @internal */
  getSortOrders(): Array<FieldSort<any>> {
    return this.groupedJoin.getSortOrders();
  }

  /** @internal */
  clone(): DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    return new DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this.groupedJoin.clone());
  }

  /** @internal */
  addCompositeCondition(
    conditions: Array<SimpleCondition>,
  ): DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.groupedJoin.addCompositeCondition(conditions);
    return this;
  }

  /** @internal */
  flipSortOrder(): DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.groupedJoin.flipSortOrder();
    return this;
  }

  /** @internal */
  limit(limit: number): DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.groupedJoin.limit(limit);
    return this;
  }

  /** @internal */
  getLimit(): number {
    return this.groupedJoin.getLimit();
  }

  /** @internal */
  extractData(data: Grouped<Aliases, ReturnType, RootAlias>): DocumentData {
    return data[this.groupedJoin.joinQueryBuilder.rootAlias];
  }

  serialize(): SerializedJoinQuery {
    return { ...this.groupedJoin.joinQueryBuilder.serialize(), dereference: true, grouped: true };
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<Grouped<Aliases, ReturnType, RootAlias>> {
    if (this.groupedJoin.joinQueryBuilder.hasIsInner()) {
      throw Error('Cannot paginate on joins when isInner is enabled.');
    }
    return new Pagination<Grouped<Aliases, ReturnType, RootAlias>>(this, options);
  }
}

class GroupedJoin<
    Aliases extends Record<Alias, Alias[]>,
    ReturnType extends Record<Alias, DocumentData>,
    RootAlias extends Alias,
    LatestAlias extends Alias,
  >
  implements SnapshotEmitter<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>>, HasDereference
{
  /** internal */
  constructor(readonly joinQueryBuilder: JoinQueryBuilder<Aliases, ReturnType, LatestAlias, RootAlias>) {}

  /** @inheritDoc */
  snapshot(): Promise<Array<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>>> {
    return firstValueFrom(this.snapshots(false));
  }

  /** @inheritDoc */
  snapshots(subscribe?: boolean): Observable<Array<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>>> {
    return this.joinQueryBuilder.snapshots(subscribe).pipe(
      map(docs => {
        return this.groupData(docs, this.joinQueryBuilder.rootAlias);
      }),
    );
  }

  /**
   * @inheritDoc
   */
  peek(): Array<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>> {
    throw new Error('peek is not currently supported for join queries');
  }

  /** @inheritDoc */
  dereference(): SnapshotEmitter<Grouped<Aliases, ReturnType, RootAlias>> {
    return new DereferencedGroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this);
  }

  private groupData(input: Array<Record<Alias, any>>, rootAlias: Alias): any {
    const oneLevelGroup = groupBy(input, inputRow => inputRow[rootAlias]?.squidDocId);
    return Object.values(oneLevelGroup)
      .filter(value => {
        return value[0][rootAlias] !== undefined;
      })
      .map(value => {
        const rights = this.joinQueryBuilder.leftToRight[rootAlias];
        const actualValue = value[0][rootAlias];

        if (rights.length === 0) {
          return actualValue;
        }

        const ret = {
          [rootAlias]: actualValue,
        };
        for (const right of rights) {
          ret[right] = this.groupData(value, right);
        }
        return ret;
      });
  }

  getSortOrders(): Array<FieldSort<any>> {
    return this.joinQueryBuilder.getSortOrders();
  }

  clone(): GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    return new GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias>(this.joinQueryBuilder.clone());
  }

  addCompositeCondition(conditions: Array<SimpleCondition>): GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.addCompositeCondition(conditions);
    return this;
  }

  flipSortOrder(): GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.flipSortOrder();
    return this;
  }

  /** @internal/ */
  limit(limit: number): GroupedJoin<Aliases, ReturnType, RootAlias, LatestAlias> {
    this.joinQueryBuilder.limit(limit);
    return this;
  }

  /** @internal/ */
  getLimit(): number {
    return this.joinQueryBuilder.getLimit();
  }

  extractData(data: Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>): any {
    /**
     * The Grouped result is of the form:
     * 1 - If there is only one join query, the result is just the document/document reference
     * 2 - If there are more than one join queries, the result is an object that has the root alias as a key and the
     * value is the document/document reference
     */
    if (Object.keys(this.joinQueryBuilder.leftToRight).length > 1) {
      return (data as any)[this.joinQueryBuilder.rootAlias].dataRef;
    } else {
      return data.dataRef;
    }
  }

  serialize(): SerializedJoinQuery {
    return { ...this.joinQueryBuilder.serialize(), grouped: true };
  }

  paginate(
    options?: Partial<PaginationOptions>,
  ): Pagination<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>> {
    if (this.joinQueryBuilder.hasIsInner()) {
      throw Error('Cannot paginate on joins when isInner is enabled.');
    }
    return new Pagination<Grouped<Aliases, WithDocumentReferences<ReturnType>, RootAlias>>(this, options);
  }
}
