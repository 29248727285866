export const CONTACT_LIST_COLLECTION = 'contactList';

export type CpContact = {
  email: string;
  name?: string;
  phone?: string;
  message?: string;
  referralSource?: string;
  captcha: string;
  location?: ContactLocation;
  reason?: ContactReason;
  submissionDate: Date;
};

export type ContactReason = 'demo' | 'request_access' | 'contact_developers' | 'billing_or_quota';

export type ContactLocation = 'ai-chat' | 'welcome' | 'contact-page' | 'contact-us-dialog';
