<mat-dialog-content class="data_schema_field_dialog" *ngVar="('ADMIN' | minRole) as isAdmin">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_title">{{ isEdit ? 'Edit ' + data!.name : 'Add field' }}</div>
  <form (ngSubmit)="submitFieldData()" [formGroup]="form" class="form">
    <div class="fields" [matTooltip]="!isAdmin ? 'You need to be an admin to edit a schema' : ''"
         [matTooltipPosition]="'above'">
      <mat-form-field *ngIf="form.controls['name'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      [matTooltip]="control.touched && !control.valid ? control?.errors?.['invalidFieldName'] : ''"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Field name</mat-label>
        <input autocomplete="off" formControlName="name" matInput name="field_name">
        <div class="suffix_required_text" matSuffix>Required</div>
        <mat-icon class="suffix_check_icon" matSuffix svgIcon="check_icon"></mat-icon>
      </mat-form-field>

      <mat-form-field *ngVar="form.controls['type'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Field type</mat-label>
        <mat-select class="select_box gray_select"
                    formControlName="type"
                    panelClass="select_box_panel">
          @for (type of SUPPORTED_FIELD_TYPES_ARRAY; track type) {
            <mat-option [value]="type">{{ type | titlecase }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div *ngIf="['string', 'number', 'integer'].includes(form.controls['type'].value)" class="min_max_container">
        <mat-form-field *ngIf="form.controls['min'] as control"
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Min {{ form.controls['type'].value === 'string' ? 'length' : 'value' }}</mat-label>
          <input [min]="form.controls['type'].value === 'string' ? 0 : null" autocomplete="off" formControlName="min"
                 matInput name="field_name"
                 type="number">
        </mat-form-field>
        <mat-form-field *ngIf="form.controls['max'] as control"
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Max {{ form.controls['type'].value === 'string' ? 'length' : 'value' }}</mat-label>
          <input [min]="form.controls['min'].value" autocomplete="off" formControlName="max" matInput name="field_name"
                 type="number">
        </mat-form-field>
      </div>

      <ng-container *ngIf="form.controls['defaultValue'] as control">
        <div class="section_toggle" style="display: flex; align-items: flex-start; margin: 20px 0;">
          <div style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 10px; width: 70px; height: 60px; margin-top: -10px;">
            <div class="title_and_description" style="text-align: left; white-space: nowrap;">
              <div class="title">Add Default</div>
            </div>
            <div class="form_field section_toggle_button_container">
              <mat-slide-toggle
                formControlName="defaultToggled"
                class="toggle_button"
                color="primary"
                [matTooltip]="form.controls['primaryKey']?.value === true ? 'Cannot add default to a primary key.' : ''"
              ></mat-slide-toggle>
            </div>
          </div>
          <div style="flex-grow: 1; display: flex; align-items: center;">
            <mat-form-field *ngIf="form.get('defaultToggled')?.value"
                            [hideRequiredMarker]="true"
                            [ngClass]="{valid: control.valid && control.touched}"
                            [matTooltip]="control.touched && !control.valid ? control?.errors?.['invalidDefaultValue'] : ''"
                            appearance="outline"
                            class="form_field dialog_form_field"
                            style="margin-left: 10px; width: 100%;">
              <mat-label>Default value</mat-label>
              <ng-container [ngSwitch]="form.get('type')?.value">
                <mat-select class="select_box gray_select"
                            *ngSwitchCase="'boolean'"
                            formControlName="defaultValue"
                            panelClass="select_box_panel">
                  @for (bool of [false, true]; track bool) {
                    <mat-option [value]="bool">{{ bool ? 'True' : 'False' }}</mat-option>
                  }
                </mat-select>
                <input *ngSwitchCase="'integer'" autocomplete="off" formControlName="defaultValue" matInput name="field_name" type="number">
                <input *ngSwitchCase="'number'" autocomplete="off" formControlName="defaultValue" matInput name="field_name" type="number">
                <input *ngSwitchDefault autocomplete="off" formControlName="defaultValue" matInput name="field_name">
              </ng-container>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <div class="primary_and_required_container">
        <mat-form-field *ngIf="form.controls['primaryKey'] as control"
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Primary key</mat-label>
          <mat-select class="select_box gray_select"
                      formControlName="primaryKey"
                      panelClass="select_box_panel"
                      (selectionChange)="primaryKeyChanged($event.value)">
            @for (bool of [false, true]; track bool) {
              <mat-option [value]="bool">{{ bool ? 'Yes' : 'No' }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="form.controls['required'] as control"
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Required</mat-label>
          <mat-select class="select_box gray_select"
                      formControlName="required"
                      panelClass="select_box_panel">
            @for (bool of [false, true]; track bool) {
              <mat-option [value]="bool">{{ bool ? 'Yes' : 'No' }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="form.controls['description'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field">
        <mat-label>Description (used for AI queries)</mat-label>
        <textarea class="textarea" autocomplete="off" [maxlength]="4000" formControlName="description" matInput
                  name="description"></textarea>
      </mat-form-field>

    </div>
    <div *ngIf="errorMessage" class="dialog_error">{{ errorMessage }}</div>
    <div class="dialog_buttons">
      <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
        Cancel
      </button>
      <div class="inline_block" [matTooltip]="!isAdmin ? 'You need to be an admin to edit a schema' : (form.controls['defaultValue']?.errors?.['invalidDefaultValue'] || '')">
        <button [disabled]="form.disabled || form.invalid" class="confirm_button primary_button xl_button"
                mat-flat-button
                type="submit">
          {{ isEdit ? 'Update' : 'Add field' }}
        </button>
      </div>

    </div>
  </form>
</mat-dialog-content>
