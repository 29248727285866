<mat-dialog-content class="update_secret_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_title">Update secret</div>
  <form (ngSubmit)="submitUpdateSecret()" [formGroup]="form" class="form">
    <div class="form_fields">
      <mat-form-field *ngVar="form.controls['key'] as control"
                      [hideRequiredMarker]="true"
                      appearance="outline"
                      class="form_field dialog_form_field key_form_field valid">
        <mat-label labelWithPrefix>Secret key</mat-label>
        <input [readonly]="true" autocomplete="off" formControlName="key" matInput name="secret_key" type="text">
        <mat-icon class="lock_icon" matPrefix svgIcon="lock_icon"></mat-icon>
      </mat-form-field>
      <mat-form-field *ngVar="form.controls['value'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field">
        <mat-label>Value</mat-label>
        <textarea autocomplete="off" class="secret_value_textarea" formControlName="value" matInput
                  name="secret_value"></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="errorMessage" class="dialog_error">{{errorMessage}}</div>
    <div class="dialog_buttons">
      <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
        Cancel
      </button>
      <button [disabled]="form.invalid" class="confirm_button primary_button xl_button" mat-flat-button type="submit">
        Update
      </button>
    </div>
  </form>
</mat-dialog-content>
