import { AiGenerateImageOptions } from './public-types';
import { RpcManager } from './rpc.manager';
import { AiGenerateImageRequest } from '../../internal-common/src/types/ai-chatbot.types';

export class AiImageClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async generate(prompt: string, options: AiGenerateImageOptions): Promise<string> {
    const request: AiGenerateImageRequest = {
      prompt,
      options,
    };
    return this.rpcManager.post<string>('ai/image/generate', request);
  }
}
