import { RpcManager } from './rpc.manager';
import { BlobAndFilename } from './types';
import { DocumentTextDataResponse } from '../../internal-common/src/public-types/extraction.public-types';

export class ExtractionClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async extractDataFromDocumentFile(file: File | BlobAndFilename): Promise<DocumentTextDataResponse> {
    return await this.rpcManager.post<DocumentTextDataResponse>('documentExtraction/extractDataFromDocumentFile', {}, [
      file,
    ]);
  }
}
