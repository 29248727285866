import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationId } from '@squidcloud/client';
import { Observable } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { getTabMaps } from '../../global/utils/tabs';
import { IntegrationService } from '../integration.service';
import { Integrations } from '../utils/content';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getPageParameter,
  getRequiredPageParameter,
  INTEGRATION_ID_PARAMETER,
  TAB_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';

const TabAliases: Record<string, string> = {
  details: 'edit',
  'readonly-schema': 'schema',
};

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationComponent {
  selectedTabId: string | undefined;

  private readonly integrationId: IntegrationId;
  readonly integrationObs: Observable<CpIntegration>;

  tabs: Array<string> = [];
  tabIdToIndexMap: Record<string, number> = {};
  indexToTabIdMap: Record<number, string> = {};
  readonly indexToHeaderTemplateMap: Record<number, TemplateRef<unknown>> = {};

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationService,
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, activatedRoute.snapshot);
    this.integrationObs = this.integrationService.observeIntegration(this.integrationId);

    this.integrationObs.pipe(takeUntilDestroyed()).subscribe(integration => {
      this.tabs = (Integrations[integration.type].tabs || []).map(tab => TabAliases[tab] || tab);
      const { tabIdToIndexMap, indexToTabIdMap } = getTabMaps(this.tabs);
      this.tabIdToIndexMap = tabIdToIndexMap;
      this.indexToTabIdMap = indexToTabIdMap;
      this.cdr.markForCheck();
    });

    activatedRoute.params.subscribe(() => {
      this.selectedTabId = getPageParameter(TAB_ID_PARAMETER, activatedRoute.snapshot);
      this.cdr.markForCheck();
    });
  }

  get selectedIndex(): number {
    if (!this.selectedTabId) return 0;
    return this.tabIdToIndexMap[this.selectedTabId] || 0;
  }

  async changeUrl(index: number | null): Promise<void> {
    if (index === null) index = 0;

    const tab = this.indexToTabIdMap[index];
    if (tab === 'how-to-use') return;

    await this.router.navigate([
      '/application',
      this.applicationService.getCurrentApplicationOrFail().appId,
      'integration',
      this.integrationId,
      tab,
    ]);
  }

  handleHeaderTemplateChange(tabId: string, template: TemplateRef<unknown>): void {
    const index = this.tabIdToIndexMap[tabId];
    this.indexToHeaderTemplateMap[index] = template;
  }
}
