<mat-dialog-content class="db_browser_filter_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_title">Filter documents</div>
  <form (ngSubmit)="submitFilters()" [formGroup]="form" class="form">
    <div class="fields">
      <mat-form-field *ngIf="form.controls['name'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Filter by field</mat-label>
        <input autocomplete="off" formControlName="name" matInput name="field_name">
        <mat-error *ngIf="control?.errors?.['invalidFieldName']">
          Must start with a letter and contain only allowed characters.
        </mat-error>
      </mat-form-field>

      <div>
        <div class="pretext">Only show documents where the specified field is...</div>
        <mat-form-field *ngVar="form.controls['operator'] as control"
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Operator</mat-label>
          <mat-select class="select_box gray_select"
                      formControlName="operator"
                      panelClass="select_box_panel">
            @for (operator of ALL_OPERATORS; track $index) {
              <mat-option [value]="operator">{{ operator }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="type_and_value_container">
          <mat-form-field *ngIf="form.controls['type'] as control"
                          [hideRequiredMarker]="true"
                          [ngClass]="{valid: control.valid && control.touched}"
                          appearance="outline"
                          class="form_field dialog_form_field">
            <mat-label>Type</mat-label>
            <mat-select class="select_box gray_select"
                        formControlName="type"
                        panelClass="select_box_panel"
                        (selectionChange)="onTypeChanged($event)">
              @for (type of ALL_TYPES; track $index) {
                <mat-option [value]="type">{{ type | titlecase }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <ng-container *ngVar="form.controls['type'].value as valueType">
            <mat-form-field *ngIf="form.controls['value'] as control"
                            [hideRequiredMarker]="true"
                            [ngClass]="{valid: control.valid && control.touched}"
                            appearance="outline"
                            floatLabel="always"
                            class="form_field dialog_form_field">
              <mat-label>Value</mat-label>

              <ng-container [ngSwitch]="valueType">
                <mat-select class="select_box gray_select"
                            *ngSwitchCase="'boolean'"
                            formControlName="value"
                            panelClass="select_box_panel"
                            [value]="control.value">
                  @for (boolValue of ['false', 'true']; track $index) {
                    <mat-option [value]="boolValue">{{ boolValue }}</mat-option>
                  }
                </mat-select>
                <input autocomplete="off" formControlName="value" matInput name="value" *ngSwitchDefault
                       [type]="valueType === 'number' ? 'number' : 'text'" />
              </ng-container>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="errorMessage" class="dialog_error">{{ errorMessage }}</div>
    <div class="dialog_buttons">
      <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
        Cancel
      </button>
      <div class="inline_block">
        <button [disabled]="form.disabled || form.invalid" class="confirm_button primary_button xl_button"
                mat-flat-button
                type="submit">
          Apply
        </button>
      </div>

    </div>
  </form>
</mat-dialog-content>
