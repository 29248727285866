import {
  Alias,
  DocIdOrDocIdObj,
  DocumentData,
  FieldSort,
  IntegrationId,
  IntegrationType,
  SerializedMergedQuery,
  SimpleCondition,
} from './public-types';
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { DocumentReference } from './document-reference';
import { DocumentReferenceFactory } from './document-reference.factory';
import { JoinQueryBuilder } from './query/join-query-builder.factory';
import { QueryBuilder, QueryBuilderFactory } from './query/query-builder.factory';
import { QuerySubscriptionManager } from './query/query-subscription.manager';
import { generateId } from '../../internal-common/src/public-utils/id-utils';
import { normalizeJsonAsString } from '../../internal-common/src/utils/serialization';
import { getInPath } from '../../internal-common/src/utils/object';
import { getSquidDocId, SquidPlaceholderId } from '../../internal-common/src/types/document.types';
import { compareOperator } from '../../internal-common/src/types/query.types';
import { Pagination, PaginationOptions } from './query/pagination';
import { SnapshotEmitter } from './query/snapshot-emitter';
import { DataManager } from './data.manager';

/**
 * Represents a docId and the data to be inserted.
 * If the docId is not provided, it will be generated on the server in case the integration supports it.
 * Read more about docId in the
 * {@link https://docs.squid.cloud/docs/api-reference/client-sdk-reference/classes/CollectionReference#doc
 * documentation}.
 */
export interface DocIdAndData<T extends DocumentData> {
  id?: DocIdOrDocIdObj;
  data: T;
}

/**
 *  Holds a reference to a data collection. A collection reference is a reference to a collection in a database. You
 * can use it to read or write data to the collection. A collection can refer to a table in a relational database or a
 * collection in a NoSQL database.
 *
 * Read more about collection references in the
 * {@link https://docs.squid.cloud/docs/development-tools/client-sdk/collection-reference documentation}.
 * @typeParam T The type of the document data.
 */
export class CollectionReference<T extends DocumentData> {
  /** A string that uniquely identifies this collection reference. */
  refId: string;

  /**
   * @internal
   */
  constructor(
    private readonly collectionName: string,
    private integrationId: IntegrationId,
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly queryBuilderFactory: QueryBuilderFactory,
    private readonly querySubscriptionManager: QuerySubscriptionManager,
    private readonly dataManager: DataManager,
  ) {
    this.refId = generateId();
  }

  /**
   * Returns a document reference for the given document id.
   * The document id is an object that maps the primary keys of the collection (as defined in the Squid Cloud Console)
   * to their values. There are a few exceptions:
   * 1 - String document id is only supported for the `built_in_db` when a schema was not defined.
   * 2 - Not all the fields in the document id are required. If a field is not provided, it will be generated on the
   *     server once the document is created (if the integration supports it).
   * 3 - When a document id is not provided, it will be treated as an empty object or empty string.
   * 4 - When the document id is just a `string` and not provided (applies only for the `built_in_db`), it will be
   *    generated on the server.
   * 5 - If the collection in the `built_in_db` does not have a schema, the document id must be provided as a string.
   *
   * Examples:
   * ```typescript
   * // For a collection in the built_in_db that does not have a schema
   * const docRef = collectionRef.doc('my-doc-id');
   * const docRef = collectionRef.doc({id: my-doc-id'});
   *
   * // For a collection with a single primary key field called "id"
   * const docRef = collectionRef.doc({id: 'my-doc-id'});
   *
   * // For a collection with a composite primary key
   * const docRef = collectionRef.doc({id1: 'my-doc-id1', id2: 'my-doc-id2'});
   * const docRef = collectionRef.doc({id1: 'my-doc-id1'}); // id2 will be generated on the server if the integration
   * supports it
   *
   * // For a collection from the `built_in_db` without a defined schema when an id is not provided
   *  const docRef = collectionRef.doc(); // The id will be generated on the server
   * ```
   *
   * @param docId The document id as an object for the different fields in the primary key or a string.
   * @returns A document reference for the given document id.
   */
  doc(docId?: DocIdOrDocIdObj): DocumentReference<T> {
    if (docId && typeof docId !== 'string' && typeof docId !== 'object' && !Array.isArray(docId)) {
      throw new Error('Invalid doc id. Can be only object or string.');
    }

    if (this.integrationId !== IntegrationType.built_in_db) {
      if (!docId) {
        docId = { [SquidPlaceholderId]: generateId() };
      } else if (typeof docId !== 'object') {
        throw new Error(
          'Invalid doc id. String doc ids are only supported for the built_in_db integration. For all other integrations, the doc id must be an object.',
        );
      }
    } else if (!docId || typeof docId === 'string') {
      docId = { __id: docId || generateId() };
    } else {
      docId = { __id: normalizeJsonAsString(docId) };
    }

    const docIdAsJsonString = normalizeJsonAsString(docId);
    const squidDocId = getSquidDocId(docIdAsJsonString, this.collectionName, this.integrationId);
    return this.documentReferenceFactory.create(squidDocId, this.queryBuilderFactory);
  }

  /**
   * Inserts multiple documents into the collection.
   */
  async insertMany(docs: Array<DocIdAndData<T>>, txId?: string): Promise<void> {
    await this.dataManager.runInTransaction(async innerTxId => {
      for (const doc of docs) {
        await this.doc(doc.id).insert(doc.data, innerTxId);
      }
    }, txId);
  }

  /**
   * Deletes multiple documents from the collection.
   */
  async deleteMany(docIdsOrReferences: Array<DocIdOrDocIdObj | DocumentReference<T>>, txId?: string): Promise<void> {
    await this.dataManager.runInTransaction(async innerTxId => {
      for (const idOrRef of docIdsOrReferences) {
        if (idOrRef instanceof DocumentReference) {
          await idOrRef.delete(innerTxId);
        } else {
          await this.doc(idOrRef).delete(innerTxId);
        }
      }
    }, txId);
  }

  /**
   * Creates a `QueryBuilder` that can be used to query the collection.
   *
   * @returns A `QueryBuilder` that can be used to query the collection.
   */
  query(): QueryBuilder<T> {
    return this.queryBuilderFactory.get<T>(this.collectionName, this.integrationId);
  }

  /**
   * Creates a `JoinQueryBuilder` that can be used to query the collection
   * Note that when using a join query, you have to provide an alias for the query and for every other query
   * participating in the join.
   *
   * @param alias The alias for the query.
   * @returns A `JoinQueryBuilder` that can be used to query the collection and joins with other queries.
   */
  joinQuery<A extends Alias>(alias: A): JoinQueryBuilder<Record<A, []>, Record<A, T>, A, A> {
    return new JoinQueryBuilder(
      this.collectionName,
      this.integrationId,
      this.querySubscriptionManager,
      this.documentReferenceFactory,
      this.queryBuilderFactory,
      alias,
      alias,
      { [alias]: [] } as Record<A, []>,
      {},
      {},
      this.query(),
    );
  }

  /**
   * Performs `or` on a list of queries. All the queries need to be on the same collection.
   * The result will be a merge of all the queries sorted by the same sort condition of the first query.
   * Duplicate items will be removed.
   * @param builders The list of query builders to merge. (A query builder can be returned from the {@link query}
   *   method).
   * @returns A query builder that can be used to perform the `or` operation.
   */
  or(...builders: QueryBuilder<T>[]): SnapshotEmitter<DocumentReference<T>> {
    return new MergedQueryBuilder(...builders);
  }
}

/** @internal */
class MergedQueryBuilder<ReturnType> implements SnapshotEmitter<ReturnType> {
  private readonly snapshotEmitters: SnapshotEmitter<ReturnType>[];

  /** @internal */
  constructor(...snapshotEmitters: SnapshotEmitter<ReturnType>[]) {
    if (snapshotEmitters.length === 0) throw new Error('At least one query builder must be provided');
    this.snapshotEmitters = snapshotEmitters.map(builder => builder.clone());
    const maxLimit = Math.max(
      ...this.snapshotEmitters.map(builder => {
        const limit = builder.getLimit();
        return limit === -1 ? 1000 : limit;
      }),
    );
    this.snapshotEmitters.forEach(builder => builder.limit(maxLimit));

    const sortOrder = this.snapshotEmitters[0].getSortOrders();
    for (const builder of this.snapshotEmitters) {
      const builderSortOrder = builder.getSortOrders();
      if (builderSortOrder.length !== sortOrder.length) {
        throw new Error('All the queries must have the same sort order');
      }
      for (let i = 0; i < sortOrder.length; i++) {
        if (builderSortOrder[i].fieldName !== sortOrder[i].fieldName || builderSortOrder[i].asc !== sortOrder[i].asc) {
          throw new Error('All the queries must have the same sort order');
        }
      }
    }
  }

  /**
   * @inheritDoc
   */
  snapshot(): Promise<Array<ReturnType>> {
    return firstValueFrom(this.snapshots(false));
  }

  /**
   * @inheritDoc
   */
  snapshots(subscribe = true): Observable<Array<ReturnType>> {
    const observables = this.snapshotEmitters.map(builder => builder.snapshots(subscribe));
    return this.or(this.snapshotEmitters[0].getSortOrders(), ...observables);
  }

  /**
   * @inheritDoc
   */
  peek(): Array<ReturnType> {
    throw new Error('peek is not currently supported for merged queries');
  }

  private or(
    sort: Array<FieldSort<unknown>>,
    ...observables: Observable<Array<ReturnType>>[]
  ): Observable<Array<ReturnType>> {
    return combineLatest([...observables]).pipe(
      map(results => {
        const seenData = new Set<DocumentData>();
        const flatResult = results.flat();
        const result: Array<ReturnType> = [];
        for (const record of flatResult) {
          if (seenData.has(this.extractData(record))) {
            continue;
          }
          seenData.add(this.extractData(record));
          result.push(record);
        }
        return result
          .sort((a, b) => {
            for (const { fieldName, asc } of sort) {
              const aVal = getInPath(this.extractData(a), fieldName);
              const bVal = getInPath(this.extractData(b), fieldName);
              if (compareOperator(aVal, bVal, '==')) {
                continue;
              }
              if (compareOperator(bVal, aVal, '<')) {
                return asc ? -1 : 1;
              }
              return asc ? 1 : -1;
            }
            return 0;
          })
          .slice(0, this.getLimit());
      }),
    );
  }

  clone(): MergedQueryBuilder<ReturnType> {
    return new MergedQueryBuilder<ReturnType>(...this.snapshotEmitters.map(builder => builder.clone()));
  }

  getSortOrders(): FieldSort<any>[] {
    return this.snapshotEmitters[0].getSortOrders();
  }

  addCompositeCondition(conditions: SimpleCondition[]): MergedQueryBuilder<ReturnType> {
    for (const builder of this.snapshotEmitters) {
      builder.addCompositeCondition(conditions);
    }
    return this;
  }

  limit(limit: number): MergedQueryBuilder<ReturnType> {
    this.snapshotEmitters.forEach(builder => builder.limit(limit));
    return this;
  }

  getLimit(): number {
    return this.snapshotEmitters[0].getLimit();
  }

  flipSortOrder(): MergedQueryBuilder<ReturnType> {
    this.snapshotEmitters.forEach(builder => builder.flipSortOrder());
    return this;
  }

  serialize(): SerializedMergedQuery {
    return {
      type: 'merged',
      queries: this.snapshotEmitters.map(s => s.serialize()),
    };
  }

  extractData(data: ReturnType): any {
    return this.snapshotEmitters[0].extractData(data);
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<ReturnType> {
    return new Pagination(this, options);
  }
}
