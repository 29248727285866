import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../services/snack-bar.service';
import { Squid } from '@squidcloud/client';
import { CONTACT_LIST_COLLECTION, ContactReason, CpContact } from '@squidcloud/console-common/types/contact.types';
import { truthy } from 'assertic';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';

interface FormDetails {
  email: FormControl<string>;
  name: FormControl<string>;
  phone: FormControl<string>;
  message: FormControl<string>;
  requestDemo: FormControl<boolean>;
}

export interface ContactUsDialogComponentData {
  reason: ContactReason;
}

const GENERIC_SNACKBAR_ERROR_TEXT = `We could not process your request at this time. Please try again.`;

@Component({
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsDialogComponent {
  form = new FormGroup<FormDetails>({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    phone: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    message: new FormControl('', { nonNullable: true }),
    requestDemo: new FormControl(true, { nonNullable: true }),
  });

  constructor(
    private readonly dialogRef: MatDialogRef<ContactUsDialogComponent>,
    private readonly squid: Squid,
    private readonly snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) private readonly data: ContactUsDialogComponentData,
  ) {}

  async submit(captchaToken: string): Promise<void> {
    if (!this.form.valid) return;
    const { email, name, phone, message, requestDemo } = this.form.value;
    const contactDoc: Omit<CpContact, 'submissionDate'> = {
      email: truthy(email),
      name,
      phone,
      message: `${message}\nDemo requested: ${requestDemo}`,
      location: 'contact-us-dialog',
      reason: this.data.reason,
      captcha: truthy(captchaToken, 'INVALID_CAPTCHA_TOKEN'),
    };
    try {
      await this.squid.collection<Omit<CpContact, 'submissionDate'>>(CONTACT_LIST_COLLECTION).doc().insert(contactDoc);
      this.snackBar.success('Thank you for your interest in Squid Cloud!');
    } catch (error) {
      console.error('Unable to submit contact form', error);
      this.snackBar.warning(`${GENERIC_SNACKBAR_ERROR_TEXT} Error: ${getMessageFromError(error)}`);
    }
    this.dialogRef.close();
  }

  async captchaResolved(captchaToken: string | null): Promise<void> {
    if (captchaToken === null || captchaToken === '') {
      console.error(`Got unexpected result from captcha: '${captchaToken}'`);
      this.snackBar.warning(GENERIC_SNACKBAR_ERROR_TEXT);
      return;
    }
    await this.submit(captchaToken);
  }

  captchaError($event: unknown): void {
    console.warn('Got captcha error', $event);
    this.snackBar.warning(GENERIC_SNACKBAR_ERROR_TEXT);
  }

  static show(dialog: MatDialog, data: ContactUsDialogComponentData): MatDialogRef<unknown> {
    return dialog.open(ContactUsDialogComponent, {
      maxWidth: '600px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    });
  }
}
