import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationService } from '../../organization/organization.service';
import { OrganizationRole } from '@squidcloud/console-common/types/organization.types';

export function isMinRole(minRole: OrganizationRole, currentRole?: OrganizationRole): boolean {
  if (currentRole === 'ADMIN') return true;
  return minRole === 'DEVELOPER';
}

@Pipe({
  name: 'minRole',
})
export class MinRolePipe implements PipeTransform {
  private lastRole: OrganizationRole | undefined = undefined;

  constructor(organizationService: OrganizationService) {
    organizationService.observeRoleInCurrentOrg().subscribe(role => {
      this.lastRole = role;
    });
  }

  transform(minRole: OrganizationRole): boolean {
    return isMinRole(minRole, this.lastRole);
  }
}
