<div class="integrations_page" *ngVar="applicationObs | async as application">
  <div class="container">
    <page-header [title]="application ? application.name : 'No application'"
                 subtitle="Integrations"
                 description="Adding integrations allow Squid to control and modify various external databases, third party applications and services. If you do not see what you are looking for please request it."
                 [withBottomBorder]="!application">
    </page-header>

    <div class="tab_group_container" *ngIf="application">
      <mat-tab-group #tabGroup (selectedTabChange)="changeUrl(tabGroup.selectedIndex)" [(selectedIndex)]="selectedIndex"
                     animationDuration="0ms"
                     color="primary"
                     disableRipple="true" mat-align-tabs="start"
                     mat-stretch-tabs="false">
        <mat-tab>
          <ng-template mat-tab-label>
            <div data-testid="integrations-tab-label-current-integrations">Current Integrations</div>
          </ng-template>
          <current-integrations class="tab"></current-integrations>
        </mat-tab>
        <mat-tab class="tab">
          <ng-template mat-tab-label>
            <div data-testid="integrations-tab-label-available-integrations">Available Integrations</div>
          </ng-template>
          <available-integrations class="tab"></available-integrations>
        </mat-tab>
      </mat-tab-group>

      <div class="actions">
        <button (click)="showRequestIntegrationDialog()"
                class="request_integration_button secondary_button large_button"
                mat-flat-button>
          Request Integration
        </button>
      </div>
    </div>
  </div>

  <no-application-zero-state
    *ngIf="!application"
    title="Arrrr no integrations yet..."
    description="Integrations require an application, it looks like you do not have one yet.">
  </no-application-zero-state>
</div>
