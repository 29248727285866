<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="myRoleObs | async as myRole">
    <div *ngIf="organizationObs | async as organization" class="organization_members_container">
      <div *ngIf="membersAndInviteesObs | async as membersAndInvitees" class="members_table">
        <div class="row header_row">
          <div class="column">NAME</div>
          <div class="column">ROLE</div>
          <div class="column">CREATED</div>
          <div class="column"></div>
        </div>
        @for (memberOrInvite of membersAndInvitees; track memberOrInvite.id) {
          <div class="row">

            <div class="column name_column">
              <avatar [imageUrl]="memberOrInvite.picture" [name]="memberOrInvite.displayName" class="avatar"></avatar>
              <div class="name" [ngClass]="{invite: memberOrInvite.isInvitation}">
                {{ memberOrInvite.displayName }}
              </div>
            </div>

            <div class="column role_column">
              @if (myRole === 'ADMIN' && memberOrInvite.id !== user.id && !memberOrInvite.isInvitation) {
                <mat-select
                  (selectionChange)="!memberOrInvite.isInvitation && changeRole(memberOrInvite.id, $event.value)"
                  [value]="memberOrInvite.role"
                  class="select_box gray_select"
                  panelClass="select_box_panel"
                >
                  @for (role of ['ADMIN', 'DEVELOPER']; track role) {
                    <mat-option [value]="role">{{ role | titlecase }}</mat-option>
                  }
                </mat-select>
              } @else if (myRole !== 'ADMIN' || memberOrInvite.id === user.id || memberOrInvite.isInvitation) {
                <mat-select
                  [disabled]="true"
                  [value]="memberOrInvite.role"
                  class="select_box gray_select immutable_role"
                  panelClass="select_box_panel"
                >
                  <mat-option [value]="memberOrInvite.role">
                    {{ memberOrInvite.isInvitation ? "Invited" : "" }} {{ memberOrInvite.role | titlecase }}
                  </mat-option>
                </mat-select>
              }
            </div>

            <div class="column created_column">{{ memberOrInvite.creationDate | date: 'medium' }}</div>

            <div class="column menu_column">
              @if (myRole === 'ADMIN' && user.id !== memberOrInvite.id) {
                <mat-icon
                  [matMenuTriggerData]="{element: memberOrInvite}"
                  [matMenuTriggerFor]="memberOrInvite.isInvitation ? inviteMenu : memberMenu"
                  class="menu_icon"
                  svgIcon="menu_dots_icon"></mat-icon>
              }
            </div>

          </div>
        }
      </div>
    </div>
    <mat-menu #inviteMenu="matMenu"
              class="invite_menu"
              xPosition="before">
      <ng-template let-element="element" matMenuContent [typeGuard]="{element: OrganizationInvitation}">
        <button (click)="resendInvitation(element)" class="menu_item" mat-menu-item>Resend invitation</button>
        <div class="menu_divider"></div>
        <button (click)="revokeInvitation(element)" class="revoke_invitation red_menu_item" mat-menu-item>Revoke
          Invitation
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #memberMenu="matMenu"
              class="member_menu"
              xPosition="before">
      <ng-template let-element="element" matMenuContent [typeGuard]="{element: OrganizationMember}">
        <button (click)="revokeMemberAccess(element)" class="menu_item red_menu_item" mat-menu-item>Revoke Access
        </button>
      </ng-template>

    </mat-menu>
  </ng-container>
</ng-container>
