import { BlobAndFilename } from './types';
import { deserializeObj, serializeObj } from '../../internal-common/src/utils/serialization';
import { DebugLogger } from '../../internal-common/src/utils/global.utils';
import { SQUIDCLOUD_CLIENT_PACKAGE_VERSION } from './version';

export class RpcError<BodyType = unknown> extends Error {
  /** @internal */
  constructor(
    readonly statusCode: number,
    readonly statusText: string,
    readonly url: string,
    readonly headers: Record<string, string>,
    readonly body: BodyType,
    message?: string,
  ) {
    super(message || `RPC error ${statusCode} ${statusText} calling ${url}`);
  }
}

/** @internal */
export interface HttpPostInput<RequestBodyType = unknown> {
  url: string;
  headers: Record<string, string>;
  message: RequestBodyType;
  files: Array<File | BlobAndFilename>;
  filesFieldName: string;
  extractErrorMessage: boolean;
}

/** A response object with type T for the body. */
export interface HttpResponse<BodyType = unknown> {
  status: number;
  statusText: string;
  headers: Record<string, string>;
  body: BodyType;
}

/**
 * Runs a post request to the given URL.
 * @internal.
 */
export async function rawSquidHttpPost<ResponseType = unknown, RequestType = unknown>(
  input: HttpPostInput<RequestType>,
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest(input);
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

async function performFetchRequest({
  headers,
  files,
  filesFieldName,
  message: body,
  url,
  extractErrorMessage,
}: HttpPostInput): Promise<HttpResponse> {
  const requestOptionHeaders = new Headers(headers);
  requestOptionHeaders.append('x-squid-client-version', SQUIDCLOUD_CLIENT_PACKAGE_VERSION);
  const requestOptions: RequestInit = { method: 'POST', headers: requestOptionHeaders, body: undefined };
  if (files.length) {
    const formData = new FormData();
    for (const file of files) {
      const blob = file instanceof Blob ? file : (file as BlobAndFilename).blob;
      const filename = file instanceof Blob ? file.name : (file as BlobAndFilename).name;
      formData.append(filesFieldName, blob, filename);
    }
    formData.append('body', serializeObj(body));
    requestOptions.body = formData;
  } else {
    requestOptionHeaders.append('Content-Type', 'application/json');
    requestOptions.body = serializeObj(body);
  }

  try {
    const response = await fetch(url, requestOptions);
    const responseHeaders: Record<string, string> = {};
    response.headers.forEach((value, key) => {
      responseHeaders[key] = value;
    });
    if (!response.ok) {
      const rawBody = await response.text();
      const parsedBody = tryDeserializing<ResponseType>(rawBody);

      if (!extractErrorMessage) {
        // noinspection ExceptionCaughtLocallyJS
        throw new RpcError(response.status, response.statusText, url, responseHeaders, parsedBody, rawBody);
      }

      let message;
      try {
        message = typeof parsedBody === 'string' ? parsedBody : parsedBody?.['message'] || rawBody;
      } catch {}
      if (!message) message = response.statusText;
      // noinspection ExceptionCaughtLocallyJS
      throw new RpcError(response.status, response.statusText, url, responseHeaders, parsedBody, message);
    }

    const responseBody = await response.text();
    DebugLogger.debug(`received response from url ${url}: ${JSON.stringify(responseBody)}`);
    return {
      body: responseBody,
      headers: responseHeaders,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (e) {
    DebugLogger.debug(`Unable to perform fetch request to url: ${url}`, e);
    throw e;
  }
}

/**
 * @internal.
 *
 * note: this method hides potential error.
 * Every endpoint call should know what kind result to expect
 * and call 'deserializeObj' directly.
 */
export function tryDeserializing<T>(text: string | undefined): T | string | undefined {
  if (!text) return undefined;
  try {
    return deserializeObj(text);
  } catch {}
  return text;
}
