import { SquidRegion } from '@squidcloud/client';
import {
  DATADOG_CONSOLE_SERVICE_NAME,
  DATADOG_ENV_DEVELOPMENT,
  DATADOG_ENV_PRODUCTION,
  DATADOG_PUBLIC_KEY_FOR_LOGS,
} from '@squidcloud/internal-common/utils/datadog-utils';
import { Environment } from './environment.type';
import { Stage } from '@squidcloud/internal-common/types/stage';
import { getStageBySquidRegion } from '@squidcloud/console-common/clouds-and-regions';

declare const CONSOLE_URL: string;
declare const STRIPE_PUBLISHABLE_KEY: string;
declare const SQUID_REGION: SquidRegion;
declare const AUTH0_DOMAIN: string;
declare const AUTH0_CUSTOM_DOMAIN: string;
declare const AUTH0_CLIENT: string;
declare const SEGMENT_WRITE_KEY: string;
declare const CONSOLE_ENV: Stage;
declare const GIT_REVISION: string;
declare const BUILD_DATE: string;

const squidStage = getStageBySquidRegion(SQUID_REGION);

export const environment: Environment = {
  production: true,
  squidAppId: 'console',
  baseUrl: CONSOLE_URL,
  squidRegion: SQUID_REGION,
  auth0: {
    domain: AUTH0_DOMAIN,
    customDomain: AUTH0_CUSTOM_DOMAIN,
    clientId: AUTH0_CLIENT,
  },
  stripePublishableKey: STRIPE_PUBLISHABLE_KEY,
  recaptchaSiteKey: '6LcI-kspAAAAAHAmh-fLTqedlxpdHmMBAXtuKEKY',
  datadogConfig: {
    logs: {
      clientToken: DATADOG_PUBLIC_KEY_FOR_LOGS,
      site: 'us5.datadoghq.com',
      service: DATADOG_CONSOLE_SERVICE_NAME,
      env: squidStage === 'prod' ? DATADOG_ENV_PRODUCTION : DATADOG_ENV_DEVELOPMENT,
      forwardConsoleLogs: ['error', 'warn'],
      telemetrySampleRate: 0,
    },
  },
  segmentWriteKey: SEGMENT_WRITE_KEY,
  stage: CONSOLE_ENV,
  buildMetadata: { gitRevision: GIT_REVISION, buildDate: BUILD_DATE },
};
