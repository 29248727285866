import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IntegrationId } from '@squidcloud/client';
import { GlobalUiService } from '../global/services/global-ui.service';
import { SnackBarService } from '../global/services/snack-bar.service';
import { IntegrationService } from './integration.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class IntegrationUiService {
  constructor(
    private readonly integrationService: IntegrationService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {}

  async showDeleteIntegrationDialog(integrationId: IntegrationId): Promise<{ name: string } | undefined> {
    return this.globalUiService.showDeleteDialog(
      `Deleting <span class="bold">${integrationId}</span> cannot be undone or recovered, and will remove it from this application.`,
      async () => {
        await this.integrationService.deleteIntegration(integrationId);
        this.snackBar.success('Integration deleted');
      },
    );
  }

  async showApiScriptDialog(integrationId: IntegrationId): Promise<void> {
    const apiScript = await this.integrationService.getIntegrationCreationScript(integrationId);
    this.globalUiService.showDocDialog(
      {
        mdFilePath: 'assets/integrations/api-script-template.md',
        params: {
          apiScript: apiScript,
          integrationId: integrationId,
        },
      },
      900,
    );
  }

  async navigate(commands: string[], integrationId?: IntegrationId): Promise<void> {
    const integrationCommands = integrationId ? ['integration', integrationId] : ['integrations'];
    await this.router.navigate([
      '/application',
      this.integrationService.currentApplication.appId,
      ...integrationCommands,
      ...commands,
    ]);
  }
}
