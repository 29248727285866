import {
  ApplyNumericFnPropertyMutation,
  DeleteMutation,
  DocumentData,
  InsertMutation,
  IntegrationType,
  PropertyMutation,
  SquidDocId,
  UpdateMutation,
} from './public-types';
import { truthy } from 'assertic';
import { map, Observable } from 'rxjs';
import { DataManager } from './data.manager';
import { QueryBuilderFactory } from './query/query-builder.factory';
import { TransactionId } from './types';
import { generateId } from '../../internal-common/src/public-utils/id-utils';
import { deserializeObj } from '../../internal-common/src/utils/serialization';
import { DeepRecord, Paths } from '../../internal-common/src/public-types/typescript.public-types';
import { parseSquidDocId, SquidPlaceholderId } from '../../internal-common/src/types/document.types';
import { IdResolutionMap } from '../../internal-common/src/types/mutation.types';
import { cloneDeep } from '../../internal-common/src/utils/object';

/**
 * Holds a reference to a document. A document reference is a reference to a specific record in a collection. You can
 * use it to read or write data to the document. A document can refer to a row in a table in a relational database or a
 * document in a NoSQL database. Additionally, a document reference can refer to a non-existent document, which you can
 * use to create a new document.
 *
 * Read more about document references in the
 * {@link https://docs.squid.cloud/docs/development-tools/client-sdk/document-reference documentation}.
 * @typeParam T The type of the document data.
 */
export class DocumentReference<T extends DocumentData = any> {
  /** A string that uniquely identifies this document reference. */
  refId: string;

  /**
   * @internal
   */
  constructor(
    private _squidDocId: SquidDocId,
    private readonly dataManager: DataManager,
    private readonly queryBuilderFactory: QueryBuilderFactory,
  ) {
    this.refId = generateId();
  }

  /**
   * @internal
   */
  get squidDocId(): SquidDocId {
    return this._squidDocId;
  }

  /**
   * Returns the document data. Throws an error if the document does not exist.
   *
   * @returns The document data.
   * @throws Error if the document does not exist.
   */
  get data(): T {
    return cloneDeep(this.dataRef);
  }

  /**
   * Returns a read-only internal copy of the document data. This works similar to `this.data`, except it does not
   * perform a defensive copy. The caller may not modify this object, on penalty of unexpected behavior.
   *
   * @returns The document data.
   * @throws Error if the document does not exist.
   */
  get dataRef(): T {
    const getError = (): string => {
      const { collectionName, integrationId, docId } = parseSquidDocId(this.squidDocId);
      return `No data found for document reference: ${JSON.stringify(
        {
          docId,
          collectionName,
          integrationId,
        },
        null,
        2,
      )}`;
    };
    return truthy(this.dataManager.getProperties(this.squidDocId) as T | undefined, getError);
  }

  /**
   * Returns whether data has been populated for this document reference. Data
   * will not present if a document has not been queried, does not exist, or
   * has been deleted.
   *
   * @returns Whether the document has data.
   */
  get hasData(): boolean {
    return !!this.dataManager.getProperties(this.squidDocId);
  }

  /**
   * A promise that resolves with the latest data from the server or undefined if the document does not exist on the
   * server.
   *
   * @returns A promise that resolves with latest data from the server or undefined if the document does not exist on
   * the server.
   */
  async snapshot(): Promise<T | undefined> {
    if (this.hasSquidPlaceholderId()) {
      throw new Error(
        'Cannot invoke snapshot of a document that was created locally without storing it on the server.',
      );
    }

    if (this.isTracked() && this.hasData) return this.data;

    const results = await this.queryBuilderFactory.getForDocument<T>(this.squidDocId).dereference().snapshot();
    truthy(results.length <= 1, 'Got more than one doc for the same id:' + this.squidDocId);
    return results.length ? results[0] : undefined;
  }

  /**
   * Returns an observable that emits the latest data from the server or undefined if the document is deleted or does
   * not exist on the server.
   *
   * @returns An observable that emits the latest data from the server or undefined if the document is deleted or does
   * not exist on the server.
   */
  snapshots(): Observable<T | undefined> {
    return this.queryBuilderFactory
      .getForDocument<T>(this.squidDocId)
      .dereference()
      .snapshots()
      .pipe(
        map(results => {
          truthy(results.length <= 1, 'Got more than one doc for the same id:' + this.squidDocId);
          return results.length ? results[0] : undefined;
        }),
      );
  }

  /**
   * Returns the data that is currently available on the client or undefined if data has not yet been populated.
   *
   * @returns The data that is currently available on the client or undefined if data has not yet been populated.
   */
  peek(): T | undefined {
    return this.isTracked() && this.hasData ? this.data : undefined;
  }

  /**
   * Returns whether the locally available version of the document may not be the latest version on the server.
   *
   * @returns Whether the locally available version of the document may not be the latest version on the server.
   */
  isDirty(): boolean {
    return this.dataManager.isDirty(this.squidDocId);
  }

  private isTracked(): boolean {
    return this.dataManager.isTracked(this.squidDocId);
  }

  /**
   * Updates the document with the given data.
   * The `update` will be reflected optimistically locally and will be applied to the server later.
   * If a transactionId is provided, the `update` will be applied to the server as an atomic operation together with
   * the rest of the operations in the transaction and the `update` will not reflect locally until the transaction is
   * completed locally.
   *
   * The returned promise will resolve once the `update` has been applied to the server or immediately if the `update`
   * is part of a transaction.
   *
   * @param data The data to update - can be partial.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  async update(data: Partial<DeepRecord<T>>, transactionId?: TransactionId): Promise<void> {
    const properties: { [key in keyof T & string]?: Array<PropertyMutation<T[key]>> } = {};
    Object.entries(data).forEach(([fieldName, value]) => {
      const propertyMutation: PropertyMutation<keyof T> =
        value !== undefined
          ? {
              type: 'update',
              value: value as any,
            }
          : {
              type: 'removeProperty',
            };
      (properties as any)[fieldName] = [propertyMutation];
    });
    const mutation: UpdateMutation = {
      type: 'update',
      squidDocIdObj: parseSquidDocId(this.squidDocId),
      properties,
    };
    return this.dataManager.applyOutgoingMutation(mutation, transactionId);
  }

  /**
   * Similar to {@link update}, but only updates the given path.
   * @param path The path to update.
   * @param value The value to set at the given path.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  async setInPath<K extends Paths<T>>(path: K, value: DeepRecord<T>[K], transactionId?: TransactionId): Promise<void> {
    // Not sure why TypeScript doesn't understand that `path` below is restricted, but we need the explicit type
    // assertion to make it compile.
    return this.update({ [path]: cloneDeep(value) } as DeepRecord<T>, transactionId);
  }

  /**
   * Similar to `update`, but only deletes the given path.
   * @param path The path to delete.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  async deleteInPath(path: Paths<T>, transactionId?: TransactionId): Promise<void> {
    return this.update({ [path]: undefined } as DeepRecord<T>, transactionId);
  }

  /**
   * Increments the value at the given path by the given value. The value may be both positive and negative.
   * @param path The path to the value to increment.
   * @param value The value to increment by.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  incrementInPath(path: Paths<T>, value: number, transactionId?: TransactionId): Promise<void> {
    const propertyMutation: ApplyNumericFnPropertyMutation = {
      type: 'applyNumericFn',
      fn: 'increment',
      value,
    };

    const mutation: UpdateMutation = {
      type: 'update',
      squidDocIdObj: parseSquidDocId(this.squidDocId),
      properties: {
        [path]: [propertyMutation],
      },
    };
    return this.dataManager.applyOutgoingMutation(mutation, transactionId);
  }

  /**
   * Decrements the value at the given path by the given value. The value may be both positive and negative.
   * @param path The path to the value to decrement.
   * @param value The value to decrement by.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  decrementInPath(path: Paths<T>, value: number, transactionId?: TransactionId): Promise<void> {
    return this.incrementInPath(path, -value, transactionId);
  }

  /**
   * Inserts the document with the given data. If the document already exists, the operation will be treated as
   * `upsert`. The `insert` will be reflected optimistically locally and will be applied to the server later. If a
   * transactionId is provided, the `insert` will be applied to the server as an atomic operation together with the
   * rest
   * of the operations in the transaction and the `insert` will not reflect locally until the transaction is completed
   * locally.
   *
   * The returned promise will resolve once the `insert` has been applied to the server or immediately if the `insert`
   * is part of a transaction.
   *
   * @param data The data to insert.
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  async insert(data: Omit<T, '__id'>, transactionId?: TransactionId): Promise<void> {
    const squidDocIdObj = parseSquidDocId(this.squidDocId);
    const integrationId = squidDocIdObj.integrationId;

    // Exclude the generated client id from the mutation properties.
    let docIdProps = deserializeObj(squidDocIdObj.docId);
    if (docIdProps[SquidPlaceholderId]) docIdProps = {};

    // Destructure composite __ids for the built_in_db.
    if (integrationId === IntegrationType.built_in_db && docIdProps.__id) {
      try {
        const idProps = deserializeObj(docIdProps.__id);
        docIdProps = { ...docIdProps, ...idProps };
      } catch {}
    }

    const mutation: InsertMutation = {
      type: 'insert',
      squidDocIdObj,
      properties: { ...data, __docId__: squidDocIdObj.docId, ...docIdProps },
    };
    return this.dataManager.applyOutgoingMutation(mutation, transactionId);
  }

  /**
   * Deletes the document.
   * The `delete` will be reflected optimistically locally and will be applied to the server later.
   * If a transactionId is provided, the `delete` will be applied to the server as an atomic operation together with
   * the rest of the operations in the transaction and the `delete` will not reflect locally until the transaction is
   * completed locally.
   *
   * The returned promise will resolve once the `delete` has been applied to the server or immediately if the `delete`
   * is part of a transaction.
   *
   * @param transactionId The transaction to use for this operation. If not provided, the operation will be applied
   *   immediately.
   */
  async delete(transactionId?: TransactionId): Promise<void> {
    const mutation: DeleteMutation = {
      type: 'delete',
      squidDocIdObj: parseSquidDocId(this.squidDocId),
    };
    return this.dataManager.applyOutgoingMutation(mutation, transactionId);
  }

  /**
   * @internal
   */
  migrateDocIds(idResolutionMap: IdResolutionMap): void {
    const newSquidDocId = idResolutionMap[this.squidDocId];
    if (newSquidDocId) {
      this._squidDocId = newSquidDocId;
    }
  }

  private hasSquidPlaceholderId(): boolean {
    const obj = deserializeObj(this._squidDocId);
    if (typeof obj === 'object' && !!obj.docId) {
      const docIdObj = deserializeObj(obj.docId);
      if (typeof docIdObj === 'object' && Object.keys(docIdObj).includes(SquidPlaceholderId)) {
        return true;
      }
    }
    return false;
  }
}
