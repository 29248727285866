import { AiChatbotClient } from './ai-chatbot-client';
import { RpcManager } from './rpc.manager';
import { SocketManager } from './socket.manager';
import { IntegrationId } from './public-types';

/** @internal */
export class AiChatbotClientFactory {
  private readonly chatbotsMap = new Map<IntegrationId, AiChatbotClient>();

  constructor(
    private readonly rpcManager: RpcManager,
    private readonly socketManager: SocketManager,
  ) {}

  getChatbot(aiIntegrationId: IntegrationId): AiChatbotClient {
    let client = this.chatbotsMap.get(aiIntegrationId);
    if (client) return client;
    client = new AiChatbotClient(this.rpcManager, this.socketManager, aiIntegrationId);
    this.chatbotsMap.set(aiIntegrationId, client);
    return client;
  }
}
