import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged, ReplaySubject, switchMap } from 'rxjs';
import { OrganizationId } from '@squidcloud/console-common/types/organization.types';
import { assertTruthy } from 'assertic';
import { Squid } from '@squidcloud/client';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { trackById } from '@squidcloud/console-web/app/utils/angular-utils';
import { formatShortBillId } from '@squidcloud/console-common/utils/m3-utils';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

@Component({
  selector: 'organization-bills-list',
  templateUrl: './organization-bills-list.component.html',
  styleUrls: ['./organization-bills-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationBillsListComponent implements OnInit {
  @Input({ required: true }) set organizationId(id: OrganizationId) {
    this.organizationId$.next(id);
  }

  readonly organizationId$ = new ReplaySubject<OrganizationId>(1);
  readonly bills$ = this.organizationId$.pipe(
    distinctUntilChanged(),
    switchMap(organizationId => this.getBills(organizationId)),
  );
  readonly orderedTableColumnIds = ['date', 'id', 'billTotal'];

  constructor(
    private readonly squid: Squid,
    private readonly snackBarService: SnackBarService,
  ) {}

  ngOnInit = (): void => assertTruthy(this.organizationId, 'organizationId is not set');

  private async getBills(organizationId: string): Promise<Array<BillTableRow>> {
    try {
      const { bills } = await callBackendExecutable(this.squid, 'getOrganizationM3Bills', organizationId);
      return bills.map<BillTableRow>(b => ({
        id: b.id,
        formattedBillId: formatShortBillId(b.id),
        billDate: b.billDate,
        billTotal: `${b.billTotal.toFixed(2)}`,
      }));
    } catch (error) {
      console.error(`Error fetching organization bills: ${organizationId}`, error);
      this.snackBarService.warning('Unexpected error occurred while fetching bills');
      return [];
    }
  }

  readonly trackById = trackById;
}

/** Single row in the table. */
interface BillTableRow {
  id: string;
  formattedBillId: string;
  billDate: string;
  billTotal: string;
}
