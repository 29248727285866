<div class="file_upload_container">
  <div class="before_file_selected" *ngIf="!selectedFile">
    <mat-icon class="file_plus_icon" svgIcon="file_plus_icon"></mat-icon>
    <input #fileSelector type="file" name="file" id="file" class="inputfile" [hidden]="true"
           (change)="fileInputChanged($event)" />
    <div class="text1">Drag and drop file, or <span class="browse_button" (click)="fileSelector.click()">Browse</span>
    </div>
    <div *ngIf="fileTypes" class="text2">Supported file types: {{fileTypes}}</div>
  </div>
  <div class="after_file_selected" *ngIf="selectedFile">
    <div class="icon_and_filename">
      <mat-icon class="file_doc_icon" svgIcon="file_doc_icon"></mat-icon>
      <div class="fileName">{{selectedFile.name}}</div>
    </div>

    <div class="buttons">
      <button (click)="fileChanged(undefined)"
              title="Delete"
              class="medium_menu_button secondary_icon_button" mat-flat-button>
        <mat-icon class="icon" svgIcon="trash_icon"></mat-icon>
      </button>
    </div>
  </div>

</div>
