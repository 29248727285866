<mat-dialog-content class="dialog_with_form" data-testid="dialog-with-form">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_title" [ngClass]="{ no_text: !data.textLines.length }">
    {{ data.title }}
  </div>
  @for (text of data.textLines; track $index) {
    <div [innerHTML]="text" class="dialog_normal_text"></div>
  }
  <magic-form [data]="formConfig"></magic-form>
</mat-dialog-content>
