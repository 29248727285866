<div *ngIf="userObs | async as user" class="profile_details">
  <div class="container">
    <page-header title="Profile" subtitle="Settings" [withBottomBorder]="false"></page-header>
    <div>
      <div class="description_and_input_container">
        <div class="left">
          <div class="title">Name</div>
          <div class="description">This will show in your profile and organization settings.</div>
        </div>
        <div class="right">
          <div class="fake_input_wrapper">
            <div class="fake_input">
              <div class="text">{{user.name}}</div>
              <mat-icon (click)="showUpdateProfileNameDialog(user)" class="icon_action" svgIcon="edit_icon"
                        title="Edit"></mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="description_and_input_container">
        <div class="left">
          <div class="title">Email</div>
          <div class="description">Your email address will not be used for marketing purposes.</div>
        </div>
        <div class="right">
          <div class="fake_input_wrapper">
            <div class="fake_input">
              <div class="text">{{user.email}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button (click)="signOut()" class="logout_button secondary_button large_button" mat-flat-button>Log Out of Squid
    </button>
  </div>
</div>
