import { FunctionName } from './bundle-data.public-types';

/** The supported OpenAI models */
export const OPENAI_CHAT_MODEL_NAMES = [
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-1106',
  'gpt-4',
  'gpt-4-turbo-preview',
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-4o-2024-08-06',
  'gpt-4-turbo',
] as const;

export const GEMINI_CHAT_MODEL_NAMES = ['gemini-pro'] as const;

export const ANTHROPIC_CHAT_MODEL_NAMES = [
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307',
  'claude-3-5-sonnet-20240620',
] as const;

/** The supported AI model names. */
export const AI_CHAT_MODEL_NAMES = [
  ...OPENAI_CHAT_MODEL_NAMES,
  ...ANTHROPIC_CHAT_MODEL_NAMES,
  ...GEMINI_CHAT_MODEL_NAMES,
] as const;

export const OPENAI_EMBEDDINGS_MODEL_NAMES = [
  'text-embedding-3-small',
  'text-embedding-3-large',
  'text-embedding-ada-002',
] as const;

export const AI_EMBEDDINGS_MODEL_NAMES = [...OPENAI_EMBEDDINGS_MODEL_NAMES] as const;

/** The supported AI image generation model names. */
export const OPENAI_IMAGE_MODEL_NAMES = ['dall-e-3'] as const;
export const OPENAI_AUDIO_MODEL_NAMES = ['whisper-1'] as const;
export const STABLE_DIFFUSION_MODEL_NAMES = ['stable-diffusion-core'] as const;
export const AI_IMAGE_MODEL_NAMES = [...OPENAI_IMAGE_MODEL_NAMES, ...STABLE_DIFFUSION_MODEL_NAMES] as const;
export const AI_AUDIO_MODEL_NAMES = [...OPENAI_AUDIO_MODEL_NAMES] as const;

export type AiChatModelName = (typeof AI_CHAT_MODEL_NAMES)[number];
export type AiEmbeddingsModelName = (typeof AI_EMBEDDINGS_MODEL_NAMES)[number];
export type OpenAiChatModelName = (typeof OPENAI_CHAT_MODEL_NAMES)[number];
export type GeminiChatModelName = (typeof GEMINI_CHAT_MODEL_NAMES)[number];
export type AnthropicChatModelName = (typeof ANTHROPIC_CHAT_MODEL_NAMES)[number];

export type AiImageModelName = (typeof AI_IMAGE_MODEL_NAMES)[number];
export type AiAudioModelName = (typeof AI_AUDIO_MODEL_NAMES)[number];
export type OpenAiImageModelName = (typeof OPENAI_IMAGE_MODEL_NAMES)[number];
export type OpenAiAudioModelName = (typeof OPENAI_AUDIO_MODEL_NAMES)[number];
export type StableDiffusionModelName = (typeof STABLE_DIFFUSION_MODEL_NAMES)[number];

export type AiGenerateImageOptions = DallEOptions | StableDiffusionCoreOptions;
export type AiAudioTranscribeOptions = WhisperOptions;

export type VectorDbType = 'pinecone' | 'postgres';

export interface BaseAiGenerateImageOptions {
  modelName: AiImageModelName;
}

export interface BaseAiAudioTranscribeOptions {
  modelName: AiAudioModelName;
}

export interface DallEOptions extends BaseAiGenerateImageOptions {
  modelName: 'dall-e-3';
  quality?: 'hd' | 'standard';
  size?: '1024x1024' | '1792x1024' | '1024x1792';
  numberOfImagesToGenerate?: 1;
}

export interface WhisperOptions extends BaseAiAudioTranscribeOptions {
  modelName: 'whisper-1';
  responseFormat?: 'json' | 'text' | 'srt' | 'verbose_json' | 'vtt';
  temperature?: number;
  prompt?: string;
}

export interface StableDiffusionCoreOptions extends BaseAiGenerateImageOptions {
  modelName: 'stable-diffusion-core';
  aspectRatio?: '16:9' | '1:1' | '21:9' | '2:3' | '3:2' | '4:5' | '5:4' | '9:16' | '9:21';
  negativePrompt?: string;
  seed?: number;
  stylePreset?:
    | 'analog-film'
    | 'anime'
    | 'cinematic'
    | 'comic-book'
    | 'digital-art'
    | 'enhance'
    | 'fantasy-art'
    | 'isometric'
    | 'line-art'
    | 'low-poly'
    | 'modeling-compound'
    | 'neon-punk'
    | 'origami'
    | 'photographic'
    | 'pixel-art'
    | 'tile-texture';
  outputFormat?: 'jpeg' | 'png' | 'webp';
}

/** The possible sources for the LLM provider API key. */
export type ApiKeySource = 'user' | 'system';

export type OpenAiResponseFormat = 'text' | 'json_object';

export type AiFileUrlType = 'image';

export interface AiFileUrl {
  type: AiFileUrlType;
  url: string;
}

/** The options for the AI chatbot chat method. */
export interface AiChatbotChatOptions {
  /** The maximum number of tokens to use when making the request to the AI model. Default to the max tokens the model can accept */
  maxTokens?: number;
  /** A unique chat ID, if the same chat ID is used again and history is not disabled, it will continue the conversation */
  chatId?: string;
  /** Whether to disable history for the chat. Default to false */
  disableHistory?: boolean;
  /** Whether to include references from the source context in the response. Default to false */
  includeReference?: boolean;
  /** The format of the response from the AI model. Note that not all models support JSON format. Default to  'text' */
  responseFormat?: OpenAiResponseFormat;
  /** Whether to response in a "smooth typing" way, beneficial when the chat result is displayed in a UI. Default to true */
  smoothTyping?: boolean;
  /** The temperature to use when sampling from the model. Default to 0.5 */
  temperature?: number;
  /** The top P value to use when sampling from the model. Default to 1 */
  topP?: number;
  /** The model to use for this chat. If not provided, the profile model will be used */
  overrideModel?: AiChatModelName;
  /** File URLs (only images supported at the moment) */
  fileUrls?: Array<AiFileUrl>;
  /** Functions to expose to the AI */
  functions?: Array<FunctionName>;
  /** A list of instructions to include with the prompt */
  instructions?: Array<string>;
  /** A set of filters that will limit the context the AI can access */
  contextMetadataFilter?: AiContextMetadataFilter;
}

export type AiChatbotMutationType = 'insert' | 'update' | 'delete';
export type AiChatbotResourceType = 'instruction' | 'context' | 'profile';

export type AiChatbotContextType = 'text' | 'url' | 'file';

export interface AiChatbotContextBase {
  type: AiChatbotContextType;
  data: string;
  metadata?: AiContextMetadata;
}

export interface AiChatbotTextContext extends AiChatbotContextBase {
  type: 'text';
}

export interface AiChatbotUrlContext extends AiChatbotContextBase {
  type: 'url';
  extractImages?: boolean;
  minImageWidth?: number;
  minImageHeight?: number;
}

// data is a file name
export interface AiChatbotFileContext extends AiChatbotContextBase {
  type: 'file';
  extractImages?: boolean;
  minImageWidth?: number;
  minImageHeight?: number;
}

export class AiChatbotChatContext {
  readonly prompt: string;
  readonly profileId: string;

  /**
   * @internal
   */
  constructor(prototype: AiChatbotChatContext) {
    this.profileId = prototype.profileId;
    this.prompt = prototype.prompt;
  }
}

export type AiChatbotContext = AiChatbotTextContext | AiChatbotUrlContext | AiChatbotFileContext;

export type AiContextMetadataValue = number | string | boolean | undefined;
export type AiContextMetadataValueArray = AiContextMetadataValue[];

export interface AiContextMetadataEqFilter {
  $eq: AiContextMetadataValue;
}

export interface AiContextMetadataNeFilter {
  $ne: AiContextMetadataValue;
}

export interface AiContextMetadataGtFilter {
  $gt: number;
}

export interface AiContextMetadataGteFilter {
  $gte: number;
}

export interface AiContextMetadataLtFilter {
  $lt: number;
}

export interface AiContextMetadataLteFilter {
  $lte: number;
}

export interface AiContextMetadataInFilter {
  $in: AiContextMetadataValueArray;
}

export interface AiContextMetadataNinFilter {
  $nin: AiContextMetadataValueArray;
}

export interface AiContextMetadataExistsFilter {
  $exists: boolean;
}

export type AiContextMetadataSimpleFilter =
  | AiContextMetadataEqFilter
  | AiContextMetadataNeFilter
  | AiContextMetadataGtFilter
  | AiContextMetadataGteFilter
  | AiContextMetadataLtFilter
  | AiContextMetadataLteFilter
  | AiContextMetadataInFilter
  | AiContextMetadataNinFilter
  | AiContextMetadataExistsFilter;

export interface AiContextMetadataFieldFilter {
  [field: string]: AiContextMetadataSimpleFilter | AiContextMetadataValue;
}

export interface AiContextMetadataAndFilter {
  $and: AiContextMetadataFilter[];
}

export interface AiContextMetadataOrFilter {
  $or: AiContextMetadataFilter[];
}

export type AiContextMetadataFilter =
  | AiContextMetadataFieldFilter
  | AiContextMetadataAndFilter
  | AiContextMetadataOrFilter;

export type AiContextMetadata = Record<string, AiContextMetadataValue>;

const INTERNAL_METADATA_KEYS = ['groupId', 'appId', 'integrationId', 'profileId', 'index', 'text', 'filePathInBucket'];

/** @internal */
export function validateAiContextMetadata(metadata: AiContextMetadata): void {
  for (const key in metadata) {
    const value = metadata[key];
    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean' && value !== undefined) {
      throw new Error(`Invalid metadata value for key ${key} - cannot be of type ${typeof value}`);
    }
    if (INTERNAL_METADATA_KEYS.includes(key)) {
      throw new Error(
        `Invalid metadata key ${key} - cannot be an internal key. Internal keys: ${INTERNAL_METADATA_KEYS.join(', ')}`,
      );
    }
    // Validate that a key can only contains letters, numbers, and underscores
    if (!/^[a-zA-Z0-9_]+$/.test(key)) {
      throw new Error(`Invalid metadata key ${key} - can only contain letters, numbers, and underscores`);
    }
  }
}

/** @internal */
export function validateAiContextMetadataFilter(filter: AiContextMetadataFilter): void {
  if (isAndFilter(filter)) {
    for (const subFilter of filter.$and) {
      validateAiContextMetadataFilter(subFilter);
    }
  } else if (isOrFilter(filter)) {
    for (const subFilter of filter.$or) {
      validateAiContextMetadataFilter(subFilter);
    }
  } else {
    for (const key in filter) {
      // Validate that a key can only contains letters, numbers, and underscores
      if (!/^[a-zA-Z0-9_]+$/.test(key)) {
        throw new Error(`Invalid metadata filter key ${key} - can only contain letters, numbers, and underscores`);
      }
      // Validate that the key is not an internal key
      if (INTERNAL_METADATA_KEYS.includes(key)) {
        throw new Error(
          `Invalid metadata filter key ${key} - cannot be an internal key. Internal keys: ${INTERNAL_METADATA_KEYS.join(
            ', ',
          )}`,
        );
      }
      const value = filter[key];
      if (
        typeof value !== 'object' &&
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        typeof value !== 'boolean'
      ) {
        throw new Error(`Invalid metadata filter value for key ${key} - cannot be of type ${typeof value}`);
      }
    }
  }
}

function isAndFilter(filter: AiContextMetadataFilter): filter is AiContextMetadataAndFilter {
  return '$and' in filter;
}

function isOrFilter(filter: AiContextMetadataFilter): filter is AiContextMetadataOrFilter {
  return '$or' in filter;
}
