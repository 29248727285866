<div *ngVar="applicationObs | async as application" class="app_logs" #topPage>
  <div class="container" *ngIf="logEntriesObs | async as logEntries">
    <page-header [title]="application?.name || 'No application'" subtitle="Logs" />
    <div class="filters_container">
      <div class="left">
        <mat-form-field class="form_field" appearance="outline">
          <mat-label class="filter_label">
            <mat-icon class="icon" svgIcon="calendar_icon"></mat-icon>
            Timestamp
          </mat-label>
          <mat-select
            class="squid_select"
            (selectionChange)="handleTimestampChanged($event.value)"
            [value]="currentTimestamp">

            @for (timestamp of allTimestampOptions; track timestamp.value) {
              <mat-option [value]="timestamp.value">{{ timestamp.label }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form_field" appearance="outline">
          <mat-label class="filter_label">
            <mat-icon class="icon" svgIcon="log_level_icon"></mat-icon>
            Level
          </mat-label>
          <mat-select
            class="squid_select"
            (selectionChange)="handleLogLevelChanged($event.value)"
            [value]="currentLogLevel">
            @for (level of allLogLevelOptions; track level.value) {
              <mat-option [value]="level.value">{{ level.label }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-icon *ngIf="serverRequestInProgress || (refreshInProgressSubject | async)" svgIcon="spinner_icon" />
      </div>
      <div class="right">
        <mat-form-field class="search_form_field form_field" appearance="outline">
          <mat-label class="filter_label">
            <mat-icon class="icon" svgIcon="search_icon"></mat-icon>
            Keyword search
          </mat-label>
          <input matInput type="text" (input)="onSearchKeywordChanged($event)" />
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="receivedDataFromServer || !application">
      <div class="logs_table" [ngClass]="{server_request_in_progress: serverRequestInProgress}">
        <div class="row header_row">
          <div class="cell">Timestamp</div>
          <div class="cell">Level</div>
          <div class="cell">Service</div>
          <div class="cell">Message</div>
          <div class="cell"></div>
        </div>

        <ng-container *ngIf="logEntries.length">
          @for (logEntry of logEntries; track logEntry.id) {
            <div class="row">
              <div class="cell timestamp_cell">
                <div class="log_level_indicator" [ngClass]="logEntry.level"></div>
                {{ logEntry.timestamp | date: 'medium' }}
              </div>
              <div class="cell">
                <log-level [level]="logEntry.level" />
              </div>
              <div class="cell">{{ logEntry.service }}</div>
              <div class="cell message">{{ logEntry.message | slice:0:1024 }}</div>
              <div class="cell actions">
                <button class="eye_button secondary_icon_button" mat-flat-button (click)="showLogEntryDialog(logEntry)">
                  <mat-icon class="eye_icon" svgIcon="eye_icon"></mat-icon>
                </button>
              </div>
            </div>
          }
        </ng-container>

        <div class="no_logs_outer_container" *ngIf="!logEntries.length">
          <div class="no_logs_inner_container">
            <mat-icon class="file_dotted_icon" svgIcon="file_dotted_icon"></mat-icon>
            <div class="title">No logs to display</div>
            <div class="description">Try a different filter combination</div>
          </div>
        </div>
      </div>

      <div class="logs_footer" *ngIf="logEntries.length">
        <div class="center_buttons">
          <button class="button secondary_button medium_button" mat-flat-button
                  [disabled]="!hasPreviousPage || serverRequestInProgress"
                  (click)="previousPage()">
            <mat-icon class="icon prev_icon" svgIcon="chevron_right_icon"></mat-icon>
            Prev
          </button>
          <button class="button secondary_button medium_button" mat-flat-button
                  [disabled]="!hasNextPage || serverRequestInProgress"
                  (click)="nextPage()">
            Next
            <mat-icon class="icon" svgIcon="chevron_right_icon" iconPositionEnd></mat-icon>
          </button>
        </div>
        <div class="right">
          <span class="back_to_top_button" (click)="topPage.scrollIntoView({behavior: 'smooth'})">Back to top</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
