import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../../global/services/theme.service';
import { OrganizationService } from '../../organization/organization.service';
import { OnboardingService } from '../onboarding.service';
import { CreateApplicationFormComponent } from '../../application/create-application-form/create-application-form.component';
import { ApplicationService } from '../../application/application.service';
import { truthy } from 'assertic';
import { getPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import {
  ONBOARDING_FLOW_ID_PARAMETER,
  ONBOARDING_FLOW_IDS,
  OnboardingFlowId,
} from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { appIdWithEnvironmentId } from '@squidcloud/internal-common/types/communication.types';
import { QuotedActionDialogComponent } from '@squidcloud/console-web/app/global/components/quoted-action-dialog/quoted-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { QuotaService } from '@squidcloud/console-web/app/organization/quota-service';
import { firstValueFrom } from 'rxjs';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { Organization } from '@squidcloud/console-common/types/organization.types';

@Component({
  templateUrl: './onboarding-application.component.html',
  styleUrls: ['./onboarding-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingApplicationComponent {
  @ViewChild(CreateApplicationFormComponent)
  readonly createApplicationFormComponent?: CreateApplicationFormComponent;

  submitCreateApplicationInProgress = false;
  readonly flow: OnboardingFlowId;

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly onboardingService: OnboardingService,
    readonly themeService: ThemeService,
    private readonly applicationService: ApplicationService,
    activatedRoute: ActivatedRoute,
    private readonly quotaService: QuotaService,
    private readonly dialog: MatDialog,
    private readonly snackBar: SnackBarService,
  ) {
    const flowId = getPageParameter<OnboardingFlowId>(ONBOARDING_FLOW_ID_PARAMETER, activatedRoute.snapshot);
    this.flow = ONBOARDING_FLOW_IDS.includes(flowId as OnboardingFlowId) ? (flowId as OnboardingFlowId) : 'archer-lite';
  }

  async submitCreateApplication(): Promise<void> {
    try {
      this.submitCreateApplicationInProgress = true;
      if (!this.onboardingService.onboardingOrganizationId) {
        // For every onboarding process create a new organization.
        // This way we know that the user is ADMIN in the organization and no random (unrelated to onboarding)
        // organization is used.
        const allOrganizations = await firstValueFrom(this.organizationService.observeOrganizations());
        const organizationName = generateUniqueOnboardingOrganizationName(allOrganizations || []);
        const orgId = await this.organizationService.createOrganization(organizationName);
        await this.organizationService.switchOrganization(orgId);
        this.onboardingService.onboardingOrganizationId = orgId;
      }

      if (!(await this.quotaService.checkCanCreateApplication())) {
        QuotedActionDialogComponent.show(this.dialog, { quotaName: 'maxNumberOfApplications' });
        return;
      }
      const formDetails = truthy(this.createApplicationFormComponent?.getFormDetails(), 'NO_FORM_DETAILS');

      const appName = formDetails.name;
      const appsInOrg = await firstValueFrom(this.applicationService.observeApplicationsForCurrentOrganization());
      if (appsInOrg?.find(app => app.name.toLowerCase().trim() === appName.toLowerCase().trim())) {
        this.snackBar.warning('An application with this name already exists in your organization');
        return;
      }
      const region = formDetails.region;
      const appId = await this.applicationService.createApplication(appName, region);
      await this.applicationService.switchApplication(appIdWithEnvironmentId(appId, 'dev'));

      await this.router.navigate(['onboarding', 'getting-started', this.flow]);
    } finally {
      this.submitCreateApplicationInProgress = false;
      this.cdr.markForCheck();
    }
  }
}

function generateUniqueOnboardingOrganizationName(existingOrgs: Array<Organization>): string {
  const prefix = 'My Organization';
  let result = prefix;
  for (let i = 1; ; i++) {
    const hasDuplicates = existingOrgs.some(o => o.name.toLowerCase() === result.toLowerCase());
    if (!hasDuplicates) {
      break;
    }
    result = `${prefix} ${i}`;
  }
  return result;
}
