<!-- Do not show any inner structure until web component is loaded and can handle it safely. -->
@if (isChatWebElementLoaded) {
  <div class="rudder_chat">
    <!--suppress HtmlUnknownAttribute -->
    <squid-chat-widget-with-fab-button
      #widgetRef
      class="squid_chat_widget"
      header-title="Squid AI"
      intro-text="Hi, I am Rudder, an AI from Squid. How can I help?"
      squid-app-id="console"
      squid-region="us-east-1.aws"
      squid-environment-id="prod"
      squid-ai-integration-id="rudder"
      squid-ai-profile-id="squid.cloud"
      menu-items-json='[{"title": "Schedule a demo", "slotName": "demo"}, {"title": "Contact developers", "slotName": "contact"}]'
    >
      <div slot="demo" class="calendly_demo_container" #calendlyElementRef>
        @if (isCalendlyIframeAddedToDom) {
          <div class="calendly_demo">
            <iframe src="https://calendly.com/d/2vk-v5f-jxj/squid-demo" width="100%" height="100%"></iframe>
          </div>
        }
      </div>
      <div slot="contact" class="contact_container">
        <widget-contact [widgetRef]="widgetRef"></widget-contact>
      </div>
    </squid-chat-widget-with-fab-button>
  </div>
}
