import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { filter, map, Observable, Subject, take } from 'rxjs';
import { ControlValueAccessor, FormBuilder, FormControl, NgControl, Validators } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SecretUiService } from '../secret-ui.service';
import { SecretService } from '../secret.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CpSecret } from '@squidcloud/console-common/types/application.types';

@Component({
  selector: 'select-secret',
  templateUrl: './select-secret.component.html',
  styleUrls: ['./select-secret.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: SelectSecretComponent }],
  host: { '[id]': 'id' },
})
/**
 * See https://material.angular.io/guide/creating-a-custom-form-field-control to understand why we implemented the
 * methods below
 */
export class SelectSecretComponent implements MatFormFieldControl<string>, OnDestroy, ControlValueAccessor, OnInit {
  allSecretsObs: Observable<Array<CpSecret>> = this.secretService
    .observeSecrets()
    .pipe(map(secrets => Object.values(secrets)));
  static nextId = 0;
  @ViewChild('secretSelect') selectElement!: HTMLElement;
  parts = this.formBuilder.group({ secret: ['', []] });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'secret-select';
  id = `secret-select-${SelectSecretComponent.nextId++}`;
  @Input('aria-describedby') userAriaDescribedBy?: string;

  @Input()
  defaultValue?: string;

  constructor(
    private formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private readonly secretUiService: SecretUiService,
    private readonly secretService: SecretService,
    private readonly destroyRef: DestroyRef,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    // Change required validator
    this.parts.setControl(
      'secret',
      new FormControl(this.defaultValue || '', this.required ? [Validators.required] : []),
    );
  }

  get empty(): boolean {
    const {
      value: { secret },
    } = this.parts;
    return !secret;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  private _placeholder = '';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    if (this.parts.valid) {
      const {
        value: { secret },
      } = this.parts;
      return secret || null;
    }
    return null;
  }

  set value(secret: string | null) {
    this.parts.setValue({ secret });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  // NOTE: DO NOT REMOVE THIS PARAMETER. IT IS REQUIRED BY THE INTERFACE. See: this.registerOnChange
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_onChangeEvent: unknown): void => {
    return;
  };

  onTouched = (): void => {
    return;
  };

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elementRef.nativeElement.querySelector('.form');
    if (controlElement) controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    if (this.parts.controls.secret.valid) {
      this.focusMonitor.focusVia(this.selectElement, 'program');
    }
  }

  writeValue(secret: string | null): void {
    this.value = secret;
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleSelectChanged(): void {
    this.onChange(this.value);
  }

  showCreateSecretDialog(): void {
    const reference = this.secretUiService.showCreateSecretDialog();
    reference
      .afterClosed()
      .pipe(take(1), filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(secret => {
        if (secret.key) {
          this.value = secret.key;
          this.handleSelectChanged();
        }
      });
  }
}
