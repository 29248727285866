import { CpUserId } from './account.types';
import { BillingPlan, OrganizationBillingType } from './billing.types';
import { OrganizationFeatureId } from '@squidcloud/internal-common/features/organization-features';
import { QuotaName } from '@squidcloud/internal-common/quota.types';
import { generateConsoleEntityId } from '@squidcloud/console-common/utils/entity-utils';
import { AppId } from '@squidcloud/client';

export const ORGANIZATION_COLLECTION = 'organization';
export const ORGANIZATION_ARCHIVE_COLLECTION = 'organizationArchive';

export type OrganizationId = string;
export const ORGANIZATION_ROLES = ['ADMIN', 'DEVELOPER'] as const;
export type OrganizationRole = (typeof ORGANIZATION_ROLES)[number];
export type InvitationKey = string;

export interface Organization {
  id: OrganizationId;
  name: string;
  creationDate: Date;
  updateDate: Date;
  /**
   * Date when the organization was deleted.
   * Only organizations in the 'organizationArchive' collection have this field.
   */
  archiveDate?: Date;
  members: Record<CpUserId, OrganizationMember>;
  invitations?: Record<InvitationKey, OrganizationInvitation>;

  /** Payment type: string, AWS, GCP. Defines how organizations are billed. */
  billingType: OrganizationBillingType;

  /**
   * ID of the data structure that stores external billing details.
   * Always defined when `billingType` is defined.
   * For example, for the GCP Marketplace `billingEntityId` is an GCP entitlement ID (entitlement.orderId).
   */
  billingEntityId?: string;

  /**
   * M3ter account ID for the organization.
   * Created when the organization is created or during the first usage submission to M3ter for old organizations.
   * TODO: migrate old organizations and make this field required.
   */
  m3terAccountId?: string;

  /**
   * Date the payment method was updated (added/removed) in Stripe.
   * The details of the payment method are stored in Stripe.
   *
   * This method should not be used as a proof if the payment method is present or not in Stripe,
   * because it is updated as a result of the webhook, and we do not know how reliable/fast this webhook is.
   * Query Stripe directly by 'stripeCustomerId' when a payment method details are needed.
   */
  paymentMethodUpdateDate?: Date;

  /**
   * Billing plan of the organization.
   * Defines quotas applied to the organization in Squid and an account plan in M3ter (a real billing configuration).
   */
  billingPlan: BillingPlan;

  /**
   * The date when organization quotas will be renewed.
   * Based on the billing plan of the organization.
   */
  nextQuotaRenewDate?: Date;

  /** List of features enabled for the organization. */
  features?: Array<OrganizationFeatureId>;

  /** If true, the organization is known to have been created by an E2E test account. */
  isCreatedByE2eTest?: boolean;
}

export interface OrganizationInvitation {
  email: string;
  invitationKey: InvitationKey;
  role: OrganizationRole;
  creationDate: Date;
}

export interface OrganizationMember {
  userId: string;
  name: string;
  email: string;
  picture: string | undefined;
  creationDate: Date;
  role: OrganizationRole;
}

export const ORGANIZATION_DELETION_ERROR_CODES = [
  'deletion-is-not-allowed',
  'has-unpaid-bill',
  'external-billing-type',
] as const;

/**
 * Error code returned to a client when deleteOrganization API method fails.
 * - `deletion-is-not-allowed` - the organization can never be deleted.
 * - `has-unpaid-bill` - the organization can't be deleted right now because it has unpaid bill.
 */
export type OrganizationDeletionErrorCode = (typeof ORGANIZATION_DELETION_ERROR_CODES)[number];

export type QuotaNotificationId = string;

/** A record to track the state of Quota notification. */
export interface QuotaNotification {
  /** Unique ID of the notification. */
  id: QuotaNotificationId;

  /** Organization to notify. Only members with ADMIN roles will receive the notification. */
  organizationId: OrganizationId;

  /** Application this quota related to. If not defined the quota is organization-level quota. */
  applicationId?: AppId;

  /** The date when the notification was created. */
  creationDate: Date;

  /**
   * The date when the notification was marked as "obsolete".
   * An obsolete notification are "outdated" and won't be processed.
   * Example of an obsolete notification: a notification for the previous (finished and already reset) usage period.
   */
  markedAsObsoleteDate?: Date;

  /** List of notified ADMIN members. */
  notifiedUserIds: string[];

  /**
   * The date when notification is considered as completed and no further processing will be done.
   * It happens when all ADMIN members were notified or there is some non-recoverable notification error.
   */
  completionDate?: Date;

  /**
   * A free text that contains completion state details.
   * Defined only for notifications completed with an error.
   */
  error?: string;

  /**
   * The field is only defined for archived notifications.
   * When an organization is archived all its notifications are also archived.
   */
  archiveDate?: Date;

  /** Name of the quota the notification is about. */
  quota: QuotaName;

  /** Maximum quota value in the plan. */
  planValue: number;

  /** Current quota value in Squid. */
  squidValue: number;
}

export const ORGANIZATION_QUOTA_NOTIFICATION_ID_PREFIX = 'qn';

export function generateOrganizationQuotaNotificationId(): string {
  return generateConsoleEntityId(ORGANIZATION_QUOTA_NOTIFICATION_ID_PREFIX);
}

/** ID of the Console organization in all environments. */
export const CONSOLE_ORGANIZATION_ID = 'squid_org';

/** ID of the Console application in all environments. */
export const CONSOLE_APPLICATION_ID = 'console';
