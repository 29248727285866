/** Absolute path of the error page. */
export const ERROR_PAGE_PATH = 'error';
/** Title (header) of the error. */
export const STATUS_PAGE_TITLE_QUERY_PARAMETER = 'title';
/** Pre-formatted plain text error message. */
export const STATUS_PAGE_MESSAGE_QUERY_PARAMETER = 'message';
/** Button text label passed as parameter. Default: 'Back to Home'. */
export const STATUS_PAGE_BUTTON_LABEL_QUERY_PARAMETER = 'button';
/** Icon type query parameter. Default: 'error'. */
export const STATUS_PAGE_ICON_QUERY_PARAMETER = 'icon';
/**
 * Kind of the status message.
 * Can affect styles or derived default value of other fields.
 * Use success=false to render errors.
 */
export const STATUS_PAGE_SUCCESS_STATE_QUERY_PARAMETER = 'success';
