import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ALL_OPERATORS, Operator } from '@squidcloud/client';
import { MatSelectChange } from '@angular/material/select';

export type DbBrowserFilterType = 'string' | 'number' | 'boolean' | 'date' | 'null';

export interface DbBrowserFilter {
  name: string;
  operator: Operator;
  type: DbBrowserFilterType;
  value: string;
}

@Component({
  selector: 'app-db-browser-filter-dialog',
  templateUrl: './db-browser-filter-dialog.component.html',
  styleUrl: './db-browser-filter-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbBrowserFilterDialogComponent {
  readonly form: FormGroup;
  errorMessage?: string;
  readonly ALL_OPERATORS = ALL_OPERATORS;
  readonly ALL_TYPES = ['string', 'number', 'boolean', 'date', 'null'];

  constructor(
    private readonly dialogRef: MatDialogRef<unknown>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: DbBrowserFilter,
  ) {
    this.form = this.formBuilder.group({
      name: new FormControl(data?.name || '', [Validators.required, this.fieldNameValidator()]),
      type: new FormControl(data?.type || 'string', [Validators.required]),
      operator: new FormControl(data?.operator || '==', []),
      value: new FormControl(data?.value || '', [Validators.required]),
    });
  }

  onTypeChanged($event: MatSelectChange): void {
    const control = this.form.controls['value'];
    if ($event.value === 'null') {
      control.disable();
      control.setValue('null');
    } else {
      if (control.value === 'null') {
        control.setValue('');
      }
      control.enable();
    }

    if ($event.value === 'boolean') {
      control.setValue('false');
    }
  }

  submitFilters(): void {
    if (!this.form.valid) return;
    const details: DbBrowserFilter = this.form.value;
    details.name = details.name.trim();
    this.dialogRef.close(details);
  }

  private fieldNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      // Regular expression for validation
      const regex = /^[a-zA-Z][a-zA-Z0-9!@#$%^&*~_,.]{0,49}$/;
      const valid = regex.test(control.value);
      return valid ? null : { invalidFieldName: { value: control.value } };
    };
  }
}
