import { Stage } from '@squidcloud/internal-common/types/stage';

/** Returns M3ter organization id per Squid service deployment: local/dev/prod. */
export function getM3terOrganizationId(stage: Stage): string {
  switch (stage) {
    case 'prod':
      return 'c90ec9da-3d94-4a1f-b71c-2717be5602e0';
    case 'dev':
      return 'eef3557a-e1e1-48df-89c8-87a31ef1e143';
    case 'sandbox':
      return 'eef3557a-e1e1-48df-89c8-87a31ef1e143';
    case 'local':
      return 'bd9171f9-52f9-41f6-b8c7-1dff2a8e48b4';
  }
}

/** Formats bill id in a user-friendly way keeping only 4 digits of UUID, like INV-ab12. */
export function formatShortBillId(billId: string): string {
  return `INV-${billId.slice(-4)}`;
}
