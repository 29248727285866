import {
  QueryMetricRequestCommon,
  QueryMetricResultGroup,
  QueryMetricResultPoint,
} from '../public-types/metric.public-types';
import { assertTruthy } from 'assertic';

export type QueryMetricRequestIntervals = Required<
  Pick<
    QueryMetricRequestCommon,
    | 'fillValue'
    | 'groupByTags'
    | 'periodEndSeconds'
    | 'periodStartSeconds'
    | 'pointIntervalAlignment'
    | 'pointIntervalSeconds'
    | 'tagDomains'
    | 'noDataBehavior'
  >
>;

/** Adds missed known tag domain groups and fills all missed points with a request.fillValue. */
export function fillMissedPoints(
  request: QueryMetricRequestIntervals,
  resultGroups: Array<QueryMetricResultGroup>,
): void {
  const { pointIntervalSeconds, noDataBehavior } = request;
  const fillValue = request.fillValue === undefined ? null : request.fillValue;
  const firstPointStartSeconds = calculateFirstPointStartSeconds(request);
  const lastPointStartSeconds = calculateLastPointStartSeconds(request);
  const tagDomains = request.tagDomains || {};
  const tagDomainEntries = Object.entries(tagDomains);

  // Handle empty result first.
  if (resultGroups.length === 0) {
    if (tagDomainEntries.length > 0) {
      // Add initial state to resultGroups: the rest of resultGroups filling code depends on it.
      const tagValues: string[] = [];
      for (let i = 0; i < request.groupByTags.length; i++) {
        const tagName = request.groupByTags[i];
        const value = tagDomains[tagName]?.[0] || '';
        tagValues.push(value);
      }
      resultGroups.push({ tagValues, points: [] });
    } else if (noDataBehavior === 'return-result-group-with-default-values') {
      // Add a group with empty tags.
      resultGroups.push({ tagValues: request.groupByTags.map(() => ''), points: [] });
    }
  }
  for (const [tag, tagValueSet] of Object.entries(tagDomains)) {
    const tagValueIndex = request.groupByTags.indexOf(tag);
    if (tagValueIndex < 0) {
      // There was no group by the tag. This should not happen and handled on request validation.
      continue;
    }
    for (let i = 0; i < resultGroups.length; i++) {
      const groupI = resultGroups[i];
      const missedTagValuesPerOtherTagsGroup = new Set<string>(tagValueSet);
      for (let j = 0; j < resultGroups.length; j++) {
        const groupJ = resultGroups[j];
        // Check all tags except the per-mutated one.
        const isGroupJHasEqualTagsWithGroupI = groupI.tagValues.every(
          (value, index) => index === tagValueIndex || value === groupJ.tagValues[index],
        );
        if (isGroupJHasEqualTagsWithGroupI) {
          missedTagValuesPerOtherTagsGroup.delete(groupJ.tagValues[tagValueIndex]);
        }
      }
      if (missedTagValuesPerOtherTagsGroup.size === 0) {
        continue;
      }
      for (const missedTagValue of missedTagValuesPerOtherTagsGroup) {
        const newResultGroup: QueryMetricResultGroup = { tagValues: [...groupI.tagValues], points: [] };
        newResultGroup.tagValues[tagValueIndex] = missedTagValue;
        resultGroups.push(newResultGroup); // Points will be filled on the next step.
      }
    }
  }
  for (const resultGroup of resultGroups) {
    if (resultGroup.points.length !== 0) {
      const firstResultPointStartSeconds = resultGroup.points[0][0];
      const lastResultPointStartSeconds = resultGroup.points[resultGroup.points.length - 1][0];
      assertTruthy(
        firstResultPointStartSeconds >= firstPointStartSeconds,
        () => `Invalid first point time: ${firstResultPointStartSeconds}`,
      );
      assertTruthy(
        lastResultPointStartSeconds <= lastPointStartSeconds,
        () => `Invalid last point time: ${lastResultPointStartSeconds}`,
      );
    }
    const continuousPoints: Array<QueryMetricResultPoint> = [];
    let resultPointIndex = 0;
    for (
      let currentPointStartSeconds = firstPointStartSeconds;
      currentPointStartSeconds <= lastPointStartSeconds;
      currentPointStartSeconds += pointIntervalSeconds
    ) {
      const resultPoint = resultGroup.points[resultPointIndex];
      if (resultPoint) {
        if (resultPoint[0] === currentPointStartSeconds) {
          continuousPoints.push(resultPoint);
          resultPointIndex++;
        } else {
          assertTruthy(
            resultPoint[0] > currentPointStartSeconds,
            () => `Result point has invalid time: ${resultPoint[0]}`,
          );
          continuousPoints.push([currentPointStartSeconds, fillValue]);
        }
      } else {
        continuousPoints.push([currentPointStartSeconds, fillValue]);
      }
    }
    resultGroup.points = continuousPoints;
  }
}

function calculateFirstPointStartSeconds({
  periodStartSeconds,
  periodEndSeconds,
  pointIntervalSeconds,
  pointIntervalAlignment,
}: QueryMetricRequestIntervals): number {
  if (pointIntervalAlignment === 'align-by-start-time') {
    return periodStartSeconds;
  }
  const totalDuration = periodEndSeconds - periodStartSeconds;
  const totalIntervals = Math.floor(totalDuration / pointIntervalSeconds);
  let alignedPeriodStartSeconds = periodEndSeconds - totalIntervals * pointIntervalSeconds;
  if (alignedPeriodStartSeconds > periodStartSeconds) {
    alignedPeriodStartSeconds -= pointIntervalSeconds;
  }
  return alignedPeriodStartSeconds;
}

function calculateLastPointStartSeconds({
  periodStartSeconds,
  periodEndSeconds,
  pointIntervalSeconds,
  pointIntervalAlignment,
}: QueryMetricRequestIntervals): number {
  if (pointIntervalAlignment === 'align-by-end-time') {
    return periodEndSeconds - pointIntervalSeconds;
  }
  const totalDuration = periodEndSeconds - periodStartSeconds;
  const totalIntervals = Math.floor(totalDuration / pointIntervalSeconds);
  let alignedPeriodEndSeconds = periodStartSeconds + totalIntervals * pointIntervalSeconds;
  if (alignedPeriodEndSeconds >= periodEndSeconds) {
    alignedPeriodEndSeconds -= pointIntervalSeconds; // Must be a start point of the last interval.
  }
  return alignedPeriodEndSeconds;
}
