<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="currentOrgObs | async as currentOrg">
    <ng-container *ngIf="allOrgsObs | async as allOrgs">
      <ng-container *ngVar="allAppsObs | async as allApps">
        <ng-container *ngVar="currentAppObs | async as currentApp">
          <ng-container *ngVar="currentEnvironmentIdObs | async as currentEnvironmentId">
            <div class="header">
              <div class="container">
                <div class="left">
                  <div class="org_and_app_selectors">
                    <div [matMenuTriggerFor]="organizationDropdown" class="selector org_selector">{{ currentOrg.name }}
                      <mat-icon class="chevron" svgIcon="chevron_right_icon"></mat-icon>
                    </div>
                    @if (currentApp && isApplicationPage) {
                      <div [matMenuTriggerFor]="applicationDropdown" class="selector app_selector">{{ currentApp.name }}
                        <mat-icon class="chevron" svgIcon="chevron_right_icon"></mat-icon>
                      </div>
                    }

                    @if (currentApp && isApplicationPage) {
                      <div [matMenuTriggerFor]="environmentDropdown" class="selector environment_selector">
                        <div class="env_block" [ngClass]="currentEnvironmentId">{{ currentEnvironmentId }}</div>
                        <mat-icon class="chevron" svgIcon="chevron_right_icon"></mat-icon>
                      </div>
                    }

                    <button *ngIf="!currentApp && ('ADMIN' | minRole)"
                            class="outline_button medium_button create_app_button"
                            mat-stroked-button
                            (click)="showCreateApplicationDialog()">
                      <mat-icon class="plus_icon" svgIcon="plus_icon"></mat-icon>
                      Create Application
                    </button>
                  </div>

                </div>
                <div class="right">
                  <avatar [imageUrl]="user.picture" [matMenuTriggerFor]="userDropDown" [name]="user.name"
                          class="avatar"></avatar>
                </div>
              </div>
            </div>

            <ng-container *ngIf="isOverQuotaObs | async">
              <div class="quota_limit_reached_banner"
                   *ngIf="!user.uiPreferences?.dismissedPaymentDetailsBanner">
                <div class="container">
                  <div class="text_wrapper">
                    <mat-icon class="warning_icon" svgIcon="warning_icon" />
                    <div class="text">
                      <span class="title">Warning:</span>
                      <span
                        class="description">You have hit your free quota limit and have no credit card on file</span>
                    </div>

                    <a [routerLink]="'/organization/' + currentOrg.id + '/billing'"
                       (click)="showPaymentDetailsDialog()">
                      <button class="outline_button medium_button update_payment_details_button" mat-stroked-button>
                        Update payment details
                      </button>
                    </a>

                  </div>
                  <mat-icon class="dismiss_button" svgIcon="close_icon"
                            (click)="dismissPaymentDetailsBanner()"
                            data-testid="close-quick-start-flyout-button"
                  ></mat-icon>
                </div>
              </div>
            </ng-container>

            <mat-menu #userDropDown class="user_dropdown" xPosition="before">
              <header-user-dropdown />
            </mat-menu>

            <mat-menu #organizationDropdown="matMenu"
                      class="organization_dropdown dropdown_menu"
                      xPosition="after">
              @for (organization of allOrgs; track organization.id) {
                <button (click)="switchOrganization(organization)"
                        [ngClass]="{with_icon_on_right: currentOrg.id === organization.id}"
                        class="menu_item" mat-menu-item>
                  {{ organization.name }}
                  @if (currentOrg.id === organization.id) {
                    <mat-icon class="check_icon" svgIcon="check_icon"></mat-icon>
                  }
                </button>
              }
              @if (allOrgs.length) {
                <div class="menu_divider"></div>
              }
              <button (click)="showCreateOrganizationModal()"
                      class="create_org_or_app_button"
                      mat-menu-item>
                <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
                Create Organization
              </button>
            </mat-menu>

            <mat-menu #applicationDropdown="matMenu"
                      class="application_dropdown dropdown_menu"
                      xPosition="after">
              @for (application of allApps; track application.appId) {
                <button (click)="switchApplication(application.appId)"
                        [ngClass]="{with_icon_on_right: currentApp?.appId === application.appId}"
                        class="menu_item" mat-menu-item>
                  {{ application.name }}
                  @if (application.appId === currentApp?.appId) {
                    <mat-icon class="check_icon" svgIcon="check_icon"></mat-icon>
                  }
                </button>
              }
              <ng-container *ngIf="('ADMIN' | minRole)">
                @if (allOrgs.length) {
                  <div class="menu_divider"></div>
                }
                <button (click)="showCreateApplicationDialog()"
                        class="create_org_or_app_button"
                        mat-menu-item>
                  <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
                  Create Application
                </button>
              </ng-container>
            </mat-menu>

            <mat-menu #environmentDropdown="matMenu"
                      class="environment_dropdown dropdown_menu"
                      xPosition="after">
              @for (environmentId of allEnvironmentIds; track environmentId) {
                <button (click)="switchEnvironment(currentApp.appId, environmentId)"
                        [ngClass]="{with_icon_on_right: currentEnvironmentId === environmentId}"
                        class="menu_item" mat-menu-item>
                  {{ environmentId }}
                  @if (environmentId === currentEnvironmentId) {
                    <mat-icon class="check_icon" svgIcon="check_icon"></mat-icon>
                  }
                </button>
              }
            </mat-menu>

          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
