import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { OrganizationId } from '@squidcloud/console-common/types/organization.types';
import { StatusPageComponent } from '@squidcloud/console-web/app/global/components/status-page/status-page.component';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { filter, firstValueFrom, map } from 'rxjs';
import { AccountService } from '../../account/account.service';
import { ApplicationService } from '../../application/application.service';
import { OrganizationService } from '../../organization/organization.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AcceptInvitationGuard implements CanActivate {
  constructor(
    private readonly accountService: AccountService,
    private readonly applicationService: ApplicationService,
    private readonly organizationService: OrganizationService,
    private readonly localStorageService: LocalStorageService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    await firstValueFrom(this.accountService.observeUser().pipe(filter(Boolean)));

    const invitationKey = this.localStorageService.getItem<string>('invitationKey');
    let invitationOrganizationId: OrganizationId | undefined;

    if (invitationKey) {
      try {
        invitationOrganizationId = await this.organizationService.acceptInvitation(invitationKey);
      } catch (e) {
        const message = getMessageFromError(e);
        return this.router.parseUrl(StatusPageComponent.buildErrorPagePath(message, 'Error Accepting Invitation'));
      }
    }

    if (invitationOrganizationId) {
      const org = await firstValueFrom(
        this.organizationService.observeOrganizations().pipe(
          map(organizations => organizations?.find(organization => organization.id === invitationOrganizationId)),
          filter(Boolean),
        ),
      );
      this.snackBar.success(`You have been added to the ${org.name} organization`);
      return this.router.createUrlTree(['/organization', invitationOrganizationId]);
    }
    return true;
  }
}
