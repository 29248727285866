import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';

@Component({
  selector: 'app-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggersComponent extends OnDestroyComponent {
  bundleDataTableDataObs: Observable<BundleDataTableData | undefined>;

  constructor(private readonly backendService: BackendService) {
    super();
    this.backendService.reportBackendMetadataAvailable(false);

    this.bundleDataTableDataObs = this.backendService.getMetricsObs(this.onDestroy).pipe(
      map(metricsResponse => {
        const metrics = metricsResponse.functionsExecutionCounts?.['trigger'];
        if (!metrics) return undefined;
        const rows: Array<BundleDataTableRow> = metrics.functions
          .sort((m1, m2) => {
            const extras1 = m1.functionData.extras;
            const extras2 = m2.functionData.extras;
            const integrationId1 = extras1['integrationId'] as string;
            const integrationId2 = extras2['integrationId'] as string;
            let result = integrationId1.localeCompare(integrationId2);
            if (result === 0) {
              const collectionName1 = extras1['collectionName'] as string;
              const collectionName2 = extras2['collectionName'] as string;
              result = collectionName1.localeCompare(collectionName2);
              if (result === 0) {
                const mutations1 = extras1['mutationTypes'] as string[];
                const mutations2 = extras2['mutationTypes'] as string[];
                result = mutations1.join().localeCompare(mutations2.join());
                if (result === 0) {
                  const functionName1 = m1.functionData.serviceFunctionName;
                  const functionName2 = m2.functionData.serviceFunctionName;
                  result = functionName1.localeCompare(functionName2);
                }
              }
            }
            return result;
          })
          .map(metric => {
            const functionData = metric.functionData;
            const counts = metric.counts;
            const extras = functionData.extras;
            const functionName = functionData.serviceFunctionName;
            const integrationId = extras['integrationId'] as string;
            const collectionName = extras['collectionName'] as string;
            const mutationTypes = (extras['mutationTypes'] as string[]).join(', ');
            const columns: Array<BundleDataTableColumn> = [
              { value: integrationId },
              { value: collectionName },
              { value: mutationTypes },
              { value: `${counts.success}` },
              { value: `${counts.errors}`, class: 'error' },
              { value: functionName },
            ];
            return { columns };
          });

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length) return undefined;
        return {
          headerRow: ['Integration', 'Collection', 'Mutations', 'Executions', 'Errors', 'Function'],
          rows,
          metrics: metrics.charts,
        };
      }),
    );
  }
}
