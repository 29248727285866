import { QUOTA_NAMES, QuotaName } from '@squidcloud/internal-common/quota.types';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { BillingPlan, BillingPlanTemplate } from '../types/billing.types';
import { CpApplication } from '../types/application.types';
import { isIntegrationExcludedFromQuotas } from '@squidcloud/console-common/types/billing-plans';

/** Returns a quota value if it exists. Searches in both period and lifetime quotas. */
export function getQuotaValue(
  { quotas, lifetimeQuotas }: BillingPlanTemplate,
  quotaName: QuotaName,
): number | undefined {
  return quotas[quotaName] ?? lifetimeQuotas?.[quotaName];
}

/** Returns a number of applications that should count towards the 'maxNumberOfApplications' quota*/
export function countQuotedApps(allOrgApps: Array<CpApplication>): number {
  return allOrgApps.filter(app => parseAppId(app.appId).environmentId === 'prod').length;
}

export function countQuotedIntegrations(application: CpApplication): number {
  let quotedIntegrationCount = 0;
  for (const integrationConfig of Object.values(application.integrations)) {
    if (!isIntegrationExcludedFromQuotas(integrationConfig)) {
      quotedIntegrationCount++;
    }
  }
  return quotedIntegrationCount;
}

/** Returns display text by quota type according to the billing plan: lifetime, periodic or unknown by the plan. */
export function getQuotaRenewTypeDisplayText(quota: QuotaName, billingPlan: BillingPlan): string {
  if (billingPlan.lifetimeQuotas?.[quota] !== undefined) {
    return 'Lifetime';
  }
  if (billingPlan.quotas[quota] === undefined) {
    return 'Unknown';
  }
  return `${billingPlan.billingPeriod.intervalCount} ${billingPlan.billingPeriod.interval}`;
}

/** Returns true if the list contains at least one resettable (Redis) quota. */
export function hasResettableQuotas(quotas: Array<QuotaName>): boolean {
  return quotas.some(q => !isQuotaManagedByConsole(q));
}

/**
 * Returns a new record with all quotas managed by the Console removed from `quotas` record.
 * The quotas managed by Console are not present in Redis.
 * Example of the quota managed by console: 'maxNumberOfApplications'.
 */
export function removeQuotasManagedByConsole<T>(quotas: Partial<Record<QuotaName, T>>): Partial<Record<QuotaName, T>> {
  const result: Partial<Record<QuotaName, T>> = { ...quotas };
  for (const quota of QUOTA_NAMES.filter(isQuotaManagedByConsole)) {
    delete result[quota];
  }
  return result;
}

export function isQuotaManagedByConsole(quota: QuotaName): boolean {
  switch (quota) {
    case 'maxNumberOfApplications':
    case 'maxNumberOfIntegrations':
      return true;
    case 'maxAiContextBytes':
    case 'maxMessageQueueBytes':
    case 'maxNumberOfConcurrentConnections':
    case 'numberOfAiAudioTranscriptions':
    case 'numberOfAiImagesGenerated':
    case 'numberOfClaude2Chats':
    case 'numberOfClaude2Tokens':
    case 'numberOfConcurrentLocks':
    case 'numberOfGeminiProChats':
    case 'numberOfGeminiProTokens':
    case 'numberOfGpt35Chats':
    case 'numberOfGpt35Tokens':
    case 'numberOfGpt4Chats':
    case 'numberOfGpt4Tokens':
    case 'numberOfLocks':
    case 'numberOfMetrics':
    case 'numberOfSecretMutations':
    case 'numberOfSecretQueries':
    case 'numberOfSquidActions':
      return false;
  }
}
