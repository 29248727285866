<div *ngIf="organizationObs | async as organization" class="organization_page">
  <div class="container" *ngVar="orgRoleObs | async as orgRole">
    <page-header [title]="organization.name"
                 subtitle="Overview"
                 [description]="'Created: '+(organization.creationDate | date: 'mediumDate')"
                 [withBottomBorder]="false"
    >
    </page-header>
    <div class="tab_group_container">
      <mat-tab-group animationDuration="0ms" color="primary" [disableRipple]="true" mat-align-tabs="start"
                     #tabGroup
                     (click)="updateLocation()" [(selectedIndex)]="selectedTabIndex"
                     mat-stretch-tabs="false">
        <mat-tab label="Details">
          <ng-template matTabContent>
            <organization-details />
          </ng-template>
        </mat-tab>
        <mat-tab label="Members">
          <ng-template matTabContent>
            <organization-members />
          </ng-template>
        </mat-tab>
        @if (isUsageTabVisible) {
          <mat-tab label="Usage">
            <ng-template matTabContent>
              <quotas mode="organization" />
            </ng-template>
          </mat-tab>
        }
        <mat-tab label="Billing" *ngIf="orgRole === 'ADMIN'">
          <ng-template matTabContent>
            <organization-billing />
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <div class="actions">
        <span [title]="orgRole === 'ADMIN' ? 'Invite Member' : 'Only admins can invite members'">
          <button (click)="showInviteMemberDialog()" *ngIf="tabGroup.selectedIndex === 1"
                  [disabled]="orgRole !== 'ADMIN'"
                  class="invite_member_button secondary_button large_button"
                  mat-flat-button>
            <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
            Invite Member
        </button>
      </span>
      </div>
    </div>

  </div>
</div>
