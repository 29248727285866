<ng-container *ngIf="integrationObs | async as integration">
  <div class="schema_page">
    <div class="container">
      <ng-container [ngSwitch]="integration.type">
        <graphql-schema
          *ngSwitchCase="IntegrationType.graphql"
          class="schema_contents"
          (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        <api-schema
          *ngSwitchCase="IntegrationType.api"
          [isNewSchema]="isNewSchema"
          class="schema_contents"
          (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        <ai-chatbot-profiles
          *ngSwitchCase="IntegrationType.ai_chatbot"
          class="schema_contents"
          (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        <app-data-schema
          *ngSwitchDefault
          [isNewSchema]="isNewSchema"
          class="schema_contents"
          (headerTemplateChange)="handleHeaderTemplateChange($event)" />
      </ng-container>
    </div>
  </div>
</ng-container>
