import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AccountService } from '../../account/account.service';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { OrganizationService } from '../organization.service';
import {
  OrganizationInvitation,
  OrganizationMember,
  OrganizationRole,
} from '@squidcloud/console-common/types/organization.types';
import { typeGuard } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';

@Component({
  selector: 'organization-members',
  templateUrl: './organization-members.component.html',
  styleUrls: ['./organization-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationMembersComponent extends OnDestroyComponent {
  readonly organizationObs = this.organizationService.observeCurrentOrganization();
  readonly myRoleObs = this.organizationService.observeRoleInCurrentOrg();
  readonly userObs = this.accountService.observeUser();
  readonly membersAndInviteesObs: Observable<Array<MemberOrInvitation>> = this.organizationService
    .observeCurrentOrganization()
    .pipe(
      map(organization => {
        const result: Array<MemberOrInvitation> = [];
        result.push(
          ...[...Object.values(organization?.members || {})]
            .sort((a, b) => {
              return a.creationDate.getTime() - b.creationDate.getTime();
            })
            .map(member => ({
              isInvitation: false,
              id: member.userId,
              displayName: member.name,
              picture: member.picture,
              role: member.role,
              creationDate: member.creationDate,
            })),
        );
        result.push(
          ...[...Object.values(organization?.invitations || {})]
            .sort((a, b) => {
              return a.creationDate.getTime() - b.creationDate.getTime();
            })
            .map(invitation => ({
              isInvitation: true,
              id: invitation.invitationKey,
              displayName: invitation.email,
              picture: undefined,
              role: invitation.role,
              creationDate: invitation.creationDate,
            })),
        );
        return result;
      }),
    );

  protected readonly OrganizationInvitation = typeGuard<OrganizationInvitation>();
  protected readonly OrganizationMember = typeGuard<OrganizationMember>();

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly accountService: AccountService,
    private readonly snackBar: SnackBarService,
    private readonly globalUiService: GlobalUiService,
  ) {
    super();
  }

  changeRole(userId: string, organizationRole: OrganizationRole): void {
    this.organizationService.changeRole(userId, organizationRole);
    this.snackBar.success('Role changed');
  }

  resendInvitation(invitation: OrganizationInvitation): void {
    this.organizationService.resendInvitation(invitation.invitationKey);
    this.snackBar.success('Invitation resent');
  }

  revokeInvitation(invitation: OrganizationInvitation): void {
    this.globalUiService.showConfirmationDialog(
      'Revoke invitation',
      'Are you sure you want to revoke this invitation?',
      'Revoke',
      () => {
        this.organizationService.deleteInvitation(invitation.invitationKey);
      },
    );
  }

  revokeMemberAccess(member: OrganizationMember): void {
    this.globalUiService.showConfirmationDialog(
      'Revoke member access',
      `Are you sure you want to revoke this member's access?`,
      'Revoke',
      () => {
        this.organizationService.deleteMember(member.userId);
      },
    );
  }

  isInvite(value: OrganizationMember | OrganizationInvitation): value is OrganizationInvitation {
    return (value as OrganizationInvitation).invitationKey !== undefined;
  }
}

interface MemberOrInvitation {
  /** User ID for members, invitation key for invites. */
  id: string;
  isInvitation: boolean;
  displayName: string;
  picture: string | undefined;
  role: OrganizationRole;
  creationDate: Date;
}
