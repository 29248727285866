<page-header
  [title]="title"
  [subtitle]="subtitleMessage"
  [secondaryTitle]="getSecondaryTitle()"
  [secondaryDescription]="secondaryDescription"
  [image]="'/assets/integrations/' + integrationType + '.svg'"
  [withBottomBorder]="!withTabs"
>
  <div class="buttons" [ngClass]="{ tabs: withTabs }">
    <ng-content select="[buttons]"></ng-content>
  </div>
</page-header>

