import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { CollectionReference, Squid } from '@squidcloud/client';
import { CpUser, CreateUserRequest } from '@squidcloud/console-common/types/account.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { UserFeatureId } from '@squidcloud/internal-common/features/user-features';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { checkArraysHaveEqualElements, truthy } from 'assertic';
import { SegmentService } from 'ngx-segment-analytics';
import { BehaviorSubject, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { StatusPageComponent } from '../global/components/status-page/status-page.component';
import {
  forgetMpAccountSetupEventId,
  getActiveGcpMpAccountSetupEventId,
} from '@squidcloud/console-web/app/utils/marketplace-web-utils';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly currentUserSubject = new BehaviorSubject<CpUser | undefined>(undefined);
  private userObs: Observable<CpUser | undefined> = this.auth0Service.user$.pipe(
    switchMap(auth0User => {
      if (!auth0User) return of(undefined);
      const userId = truthy(auth0User.sub, 'INVALID_USER_ID');
      return this.getUserCollection()
        .query()
        .where('id', '==', userId)
        .snapshots()
        .pipe(
          map(results => results[0]?.data),
          tap(async cpUser => {
            if (cpUser) return;
            try {
              const createUserRequest: CreateUserRequest = {
                id: truthy(auth0User.sub, 'INVALID_USER_ID'),
                name: truthy(auth0User.name, 'INVALID_NAME'),
              };
              await callBackendExecutable(this.squid, 'createUser', createUserRequest);
            } catch (error) {
              console.log('Failed to create a user', error);
              // TODO: it can be any error here (including internal errors).
              const errorMessage = `Sign up failed: ${getMessageFromError(error)}.\nPlease try again later.`;
              // Signing out so that the user won't be stuck not being able to sign out.
              this.auth0Service.logout({
                openUrl: () => StatusPageComponent.navigateToErrorPage(this.router, errorMessage),
              });
            }
          }),
        );
    }),
    shareReplay(1),
  );

  /**
   * Time of the last marketplace purchase activation start.
   * Successful purchase activation results to organization creation.
   */
  mpPurchaseActivationEventTime = -1;

  constructor(
    private readonly auth0Service: Auth0Service,
    private readonly squid: Squid,
    private readonly router: Router,
    private readonly segment: SegmentService,
  ) {
    this.userObs.subscribe(async cpUser => {
      this.currentUserSubject.next(cpUser);
      // On every user sign-in activate pending GCP & AWS Marketplace orders.
      if (cpUser) {
        const gcpMpAccountSetupEventId = getActiveGcpMpAccountSetupEventId();
        if (gcpMpAccountSetupEventId) {
          try {
            this.mpPurchaseActivationEventTime = Date.now();
            await callBackendExecutable(this.squid, 'activateGcpPurchase', { gcpMpAccountSetupEventId });
          } finally {
            forgetMpAccountSetupEventId(gcpMpAccountSetupEventId);
          }
        }
      }
    });
  }

  async updateName(newName: string): Promise<void> {
    await this.updateUser({ name: newName });
  }

  async updateUser(updates: Partial<CpUser>): Promise<void> {
    const cpUser = this.getUserOrFail();
    await this.getUserCollection().doc(cpUser.id).update(updates);
  }

  get user$(): Observable<CpUser | undefined> {
    return this.userObs;
  }

  observeUser(): Observable<CpUser | undefined> {
    return this.userObs;
  }

  /** Returns an observable over the list of the active user features. */
  get features$(): Observable<Array<UserFeatureId>> {
    return this.user$.pipe(
      map(u => u?.features || []),
      distinctUntilChanged((before, after) => checkArraysHaveEqualElements(before, after)),
    );
  }

  getUserOrFail(): CpUser {
    return truthy(this.currentUserSubject.value, 'NO_CURRENT_USER');
  }

  signOut(): void {
    this.segment.reset();
    this.auth0Service.logout({
      logoutParams: {
        // Return to the current domain, but not the first one in Auth0 config.
        returnTo: window.location.origin,
      },
    });
  }

  private getUserCollection(): CollectionReference<CpUser> {
    return this.squid.collection<CpUser>('user');
  }

  dismissPaymentDetailsBanner(): void {
    this.getUserCollection()
      .doc(this.getUserOrFail().id)
      .setInPath('uiPreferences.dismissedPaymentDetailsBanner', true)
      .then();
  }

  setSideBarCollapseState(collapsed: boolean): void {
    this.getUserCollection().doc(this.getUserOrFail().id).setInPath('uiPreferences.sideBarCollapsed', collapsed).then();
  }
}
