import { Injectable } from '@angular/core';
import { Squid } from '@squidcloud/client';
import { OrganizationService } from './organization.service';
import { environment } from '../../environments/environment';
import { assertTruthy } from 'assertic';
import { ThemeService } from '../global/services/theme.service';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
  GetPaymentDetailsRequest,
  GetPaymentDetailsResponse,
  GetSavePaymentDetailsClientSecretRequest,
} from '@squidcloud/console-common/types/billing.types';
import { Appearance, Stripe, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { AccountService } from '../account/account.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private _stripePromise?: Promise<Stripe>;
  private get stripePromise(): Promise<Stripe> {
    if (this._stripePromise === undefined) {
      this._stripePromise = loadStripe(environment.stripePublishableKey) as Promise<Stripe>;
    }
    return this._stripePromise;
  }

  private elements?: StripeElements;

  constructor(
    private readonly squid: Squid,
    private readonly organizationService: OrganizationService,
    private readonly themeService: ThemeService,
    private readonly accountService: AccountService,
  ) {}

  async mountSavePaymentDetailsElements(): Promise<void> {
    const theme = await firstValueFrom(this.themeService.theme$);
    const stripe = await this.stripePromise;

    const clientSecret = await this.getSavePaymentDetailsClientSecret();

    const appearance: Appearance = {
      theme: theme === 'dark' ? 'night' : 'stripe',
    };

    const loader = 'auto';
    this.elements = stripe.elements({ clientSecret, appearance, loader });
    const paymentElement = this.elements.create('payment', {});
    paymentElement.mount('#payment_details');
  }

  async onDetailsSubmitted(): Promise<string | undefined> {
    const stripe = await this.stripePromise;
    assertTruthy(this.elements, 'Elements not mounted');
    const result = await stripe.confirmSetup({ elements: this.elements, redirect: 'if_required' });
    return result.error?.message;
  }

  async getPaymentDetails(): Promise<GetPaymentDetailsResponse | undefined> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const request: GetPaymentDetailsRequest = { organizationId: organization.id };
    return await callBackendExecutable(this.squid, 'getPaymentDetails', request);
  }

  private async getSavePaymentDetailsClientSecret(): Promise<string> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const request: GetSavePaymentDetailsClientSecretRequest = { organizationId: organization.id };
    return await callBackendExecutable(this.squid, 'getSavePaymentDetailsClientSecret', request);
  }

  get showBillsFlag$(): Observable<boolean> {
    return combineLatest([this.organizationService.currentOrganizationFeatures$, this.accountService.features$]).pipe(
      map(
        ([orgFeatures, userFeatures]) =>
          orgFeatures.includes('FT_ORG_SHOW_BILLS') || userFeatures.includes('FT_USER_SHOW_BILLS'),
      ),
    );
  }
}
