import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationService } from '../../organization/organization.service';
import { CreateApplicationDialogComponent } from '@squidcloud/console-web/app/application/create-application-dialog/create-application-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'no-application-zero-state',
  templateUrl: './no-application-zero-state.component.html',
  styleUrls: ['./no-application-zero-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoApplicationZeroStateComponent {
  organizationObs = this.organizationService.observeCurrentOrganization();

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly dialog: MatDialog,
  ) {}

  @Input()
  title!: string;

  @Input()
  description!: string;

  createApplication(): void {
    CreateApplicationDialogComponent.show(this.dialog);
  }
}
