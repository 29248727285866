import {
  DeleteApiKeyRequest,
  DeleteSecretRequest,
  GenerateNewApiKeyRequest,
  GetApiKeyRequest,
  GetCustomSecretRequest,
  SetSecretRequest,
} from '../../internal-common/src/types/secret.types';
import { SecretEntry, SecretKey, SecretValue, SetSecretRequestEntry } from './public-types';
import { RpcManager } from './rpc.manager';

export class SecretClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async get(key: SecretKey): Promise<SecretEntry | undefined> {
    const request: GetCustomSecretRequest = { key };
    return (await this.rpcManager.post('secret/get', request)) || undefined;
  }

  getAll(): Promise<Record<SecretKey, SecretEntry>> {
    return this.rpcManager.post('secret/getAll', {});
  }

  upsert(key: SecretKey, value: SecretValue): Promise<SecretEntry> {
    return this.upsertMany([{ key, value }]).then(entries => entries[0]);
  }

  upsertMany(entries: Array<SetSecretRequestEntry>): Promise<Array<SecretEntry>> {
    const request: SetSecretRequest = { entries };
    return this.rpcManager.post('secret/upsert', request);
  }

  delete(key: SecretKey): Promise<void> {
    const request: DeleteSecretRequest = { keys: [key] };
    return this.rpcManager.post('secret/delete', request);
  }

  deleteMany(keys: Array<SecretKey>): Promise<void> {
    const request: DeleteSecretRequest = { keys };
    return this.rpcManager.post('secret/delete', request);
  }

  get apiKeys(): ApiKeysSecretClient {
    return new ApiKeysSecretClient(this.rpcManager);
  }
}

export class ApiKeysSecretClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  get(key: SecretKey): Promise<SecretEntry | undefined> {
    const request: GetApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/get', request);
  }

  getAll(): Promise<Record<SecretKey, SecretEntry>> {
    return this.rpcManager.post('secret/api-key/getAll', {});
  }

  upsert(key: SecretKey): Promise<SecretEntry> {
    const request: GenerateNewApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/upsert', request);
  }

  delete(key: SecretKey): Promise<void> {
    const request: DeleteApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/delete', request);
  }
}
