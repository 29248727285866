<ng-container *ngVar="selectedEndpointSubject | async as selectedEndpoint">
  <ng-container *ngIf="integrationObs | async as integration">
    <div *ngIf="modificationsObservable | async as modifications" class="schema">
      <ng-container *ngVar="schemaObs | async as schema">
        <portal class="flex_shrink_0" [emitter]="headerTemplateChange" [teleport]="!isNewSchema">
          <integration-header [integrationType]="integration.type"
                              [title]="isNewSchema ? 'Define schema' : integration.id"
                              [step]="isNewSchema ? [2, 2] : [0, 0]"
                              [withTabs]="!isNewSchema">
            <ng-container buttons>
              <button *ngIf="!isOpenApiIntegration"
                      (click)="showUploadSchemaViaFileUploadDialog()"
                      [disabled]="!schema"
                      class="discover_schema secondary_button large_button"
                      mat-flat-button
                      type="submit"
              >
                Discover from File
              </button>
              <button *ngIf="isOpenApiIntegration"
                      (click)="showRediscoverSchemaDialog()"
                      [disabled]="!schema"
                      class="discover_schema secondary_button large_button"
                      mat-flat-button
                      type="submit"
                      data-testid="rediscover-schema-button"
              >
                Rediscover Schema
              </button>
              <button (click)="saveSchema()"
                      [disabled]="!modifications.isModified()"
                      class="save_schema primary_button large_button"
                      mat-flat-button
                      type="submit"
                      data-testid="save-schema-button"
              >
                Save Schema
              </button>
            </ng-container>
          </integration-header>
        </portal>


        <ng-container *ngIf="schema">
          <div class="page_content">
            <div class="left">
              <div class="left_tables">
                <div class="left_table">
                  <schema-table-header
                    title="Base URL"
                    class="mb_16"
                    (edit)="showBaseUrlDialog()"
                    [hideEdit]="!schema.baseUrl"
                  />
                  <ng-container *ngIf="schema.baseUrl as baseUrl; else addBaseUrl">
                    <div content class="left_rows">
                      <schema-select-row
                        [title]="schema.baseUrl"
                        icon="globe_icon"
                        [mono]="true"
                        [isModified]="modifications.isPathModified(['baseUrl', baseUrl])"
                        [selected]="baseUrl === selectedBaseUrl"
                        (activate)="selectBaseUrl(baseUrl)"
                      />
                    </div>
                  </ng-container>
                  <ng-template #addBaseUrl>
                    <div class="left_button">
                      <button (click)="showBaseUrlDialog()"
                              class="primary_button medium_button"
                              mat-flat-button>
                        Add Base URL
                      </button>
                    </div>
                  </ng-template>
                </div>

                <div class="left_table">
                  <schema-table-header
                    title="Endpoints"
                    class="mb_16"
                    (add)="showEndpointDialog()"
                    (search)="searchEndpoints($event)"
                    [hideAdd]="!hasEndpoints"
                  />
                  <ng-container *ngIf="hasEndpoints; else addEndpoint">
                    <div class="left_rows" *ngVar="groupEndpointsByTag(schema.endpoints) as taggedEndpoints">
                      @for (tagOrEndpointId of getSortedKeys(taggedEndpoints); track tagOrEndpointId) {
                        <ng-container *ngVar="taggedEndpoints[tagOrEndpointId] as tagOrEndpoint">
                          @if (!isApiEndpoint(tagOrEndpoint)) {
                            <div class="folder_select_row" [attr.data-testid]="'folder-select-row-' + tagOrEndpointId">
                              <button class="name_and_icon" (click)="toggleFolder(tagOrEndpointId)">
                                @if (foldersOpen[tagOrEndpointId]) {
                                  <mat-icon class="icon" svgIcon="folder_open" />
                                  <mat-icon class="caret_icon rotate_open" svgIcon="arrow_down" />
                                } @else {
                                  <mat-icon class="icon" svgIcon="folder" />
                                  <mat-icon class="caret_icon" svgIcon="arrow_down" />
                                }
                                <span class="name">{{ tagOrEndpointId }}</span>
                              </button>
                            </div>
                            <ng-container *ngIf="foldersOpen[tagOrEndpointId]">
                              @for (endpoint of getEntries(tagOrEndpoint); track endpoint) {
                                <schema-select-row
                                  [title]="endpoint[0]"
                                  icon="api_icon"
                                  [isModified]="modifications.isPathModified(['endpoint', endpoint[0]])"
                                  (activate)="selectEndpoint(endpoint[0])"
                                  [selected]="endpoint[0] === selectedEndpoint"
                                  [inFolder]="true"
                                >
                                  <span class="outline_badge" [ngClass]="endpoint[1].method | lowercase">
                                    {{ endpoint[1].method | uppercase }}
                                  </span>
                                </schema-select-row>
                              }
                            </ng-container>
                          } @else if (isApiEndpoint(tagOrEndpoint)) {
                            <schema-select-row
                              [title]="tagOrEndpointId"
                              icon="api_icon"
                              [isModified]="modifications.isPathModified(['endpoint', tagOrEndpointId])"
                              (activate)="selectEndpoint(tagOrEndpointId)"
                              [selected]="tagOrEndpointId === selectedEndpoint"
                            >
                                <span class="outline_badge" [ngClass]="tagOrEndpoint.method | lowercase">
                                  {{ tagOrEndpoint.method | uppercase }}
                                </span>
                            </schema-select-row>
                          }
                        </ng-container>
                      }
                    </div>
                  </ng-container>

                  <ng-template #addEndpoint>
                    <div class="left_button">
                      <button (click)="showEndpointDialog()"
                              [ngClass]="schema.baseUrl ? 'primary_button' : 'secondary_button'"
                              class="medium_button "
                              mat-flat-button>
                        Add endpoint
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div class="right">
              <ng-container *ngIf="selectedFields; else nothingToSee">
                <ng-container *ngIf="selectedFields.endpoint as endpoint">
                  <div class="right_table mb_24">
                    <schema-table-header
                      class="mb_24"
                      [title]="endpoint.name"
                      [method]="endpoint.method"
                      icon="api_endpoint_icon"
                      [isModified]="modifications.isPathModified(['endpoint', selectedEndpoint, 'details'])"
                      (edit)="showEndpointDialog(selectedEndpoint)"
                    />
                    <div class="endpoint_table common_table">
                      <div class="row">
                        <div class="column url_column">URL</div>
                        <div class="column">{{ baseUrl }}<b>{{ endpoint.relativePath }}</b></div>
                        <div class="badge_and_buttons">
                          <button
                            (click)="copyUrl(baseUrl + endpoint.relativePath)"
                            class="copy_button secondary_icon_button"
                            mat-flat-button
                            title="Copy">
                            <mat-icon class="copy_icon" svgIcon="copy_icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <info-card
                  class="mb_24"
                  title="Why add request and response fields?"
                  description="These fields provide a simple way to pass and extract data when calling your endpoint from Squid. Not sending any data in your request or expecting any in your response? No problem! Just leave these sections blank."
                  (dismiss)="dismissInfoCard()"
                  [show]="showInfoCard()"
                >
                  <ng-container content>
                    <info-card
                      class="mb_24"
                      title="Why add request fields?"
                      description="These fields provide a simple way to pass data when calling your endpoint from Squid. Not sending any data in your request? No problem! Just leave this section blank."
                      (dismiss)="dismissInfoCard()"
                      [show]="showInfoCard('request')"
                    >
                      <ng-container content>
                        <ng-container *ngIf="selectedFields.request?.fields as requestFields">
                          <div content class="right_table mb_24">
                            <schema-table-header
                              [ngClass]="{'mb_24': requestFields.length}"
                              title="Request"
                              icon="api_request_icon"
                              [isModified]="!!selectedFields.request?.modified"
                              (add)="showFieldDialog('request')"
                            />
                            <ng-container *ngIf="requestFields.length">
                              <div class="request_table common_table">
                                <div class="row header_row">
                                  <div class="column">Field Name</div>
                                  <div class="column">Location</div>
                                  <div class="column">Description</div>
                                </div>
                                @for (row of requestFields; track row.name) {
                                  <div class="row">
                                    <div class="column">{{ row.name }}</div>
                                    <div class="column">{{ row.location }}</div>
                                    <div class="column">{{ row.description }}</div>
                                    <div class="column action_column">
                                      <schema-field-menu
                                        [element]="row.name"
                                        (edit)="showFieldDialog('request', $event)"
                                        (delete)="showDeleteFieldDialog('request', $event)"
                                        (duplicate)="duplicateField('request', $event)" />
                                    </div>
                                  </div>
                                }
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>

                        <injection-table
                          *ngIf="selectedEndpoint"
                          [schema]="schema.endpoints[selectedEndpoint].injectionSchema || {}"
                          [isModified]="modifications.isPathModified(['endpoint', selectedEndpoint, 'injection'])"
                          (schemaChanged)="setInjectionSchema($event)" />
                        <injection-table
                          *ngIf="selectedBaseUrl"
                          [schema]="schema.injectionSchema || {}"
                          [isModified]="modifications.isPathModified(['baseUrl', selectedBaseUrl, 'injection'])"
                          (schemaChanged)="setInjectionSchema($event)" />
                      </ng-container>
                    </info-card>

                    <ng-container *ngIf="selectedFields.response?.fields as responseFields">
                      <info-card
                        title="Why add response fields?"
                        description="These fields provide a simple way to extract data when calling your endpoint from Squid. Not expecting data any in your response? No problem! Just leave this section blank."
                        (dismiss)="dismissInfoCard()"
                        [show]="showInfoCard('response')"
                      >
                        <div content class="right_table">
                          <schema-table-header
                            [ngClass]="{'mb_24': responseFields.length}"
                            title="Response"
                            icon="api_response_icon"
                            [isModified]="!!selectedFields.response?.modified"
                            (add)="showFieldDialog('response')"
                          />
                          <ng-container *ngIf="responseFields.length">
                            <div class="common_table response_table">
                              <div class="row header_row">
                                <div class="column">Field Name</div>
                                <div class="column">Location</div>
                                <div class="column">Field Path</div>
                                <div class="column">Description</div>
                              </div>
                              @for (row of responseFields; track row.name) {
                                <div class="row">
                                  <div class="column">{{ row.name }}</div>
                                  <div class="column">{{ row.location }}</div>
                                  <div class="column">{{ row.path }}</div>
                                  <!-- TODO: full desc on hover -->
                                  <div class="column">{{ row.description }}</div>
                                  <div class="column action_column">
                                    <schema-field-menu
                                      [element]="row.name"
                                      (edit)="showFieldDialog('response', $event)"
                                      (delete)="showDeleteFieldDialog('response', $event)"
                                      (duplicate)="duplicateField('response', $event)" />
                                  </div>
                                </div>
                              }
                            </div>
                          </ng-container>
                        </div>
                      </info-card>
                    </ng-container>
                  </ng-container>
                </info-card>
              </ng-container>

              <mini-backend-functions
                class="mb_24"
                [title]="selectedEndpoint || selectedBaseUrl || ''"
                [apiInput]="(selectedEndpoint || selectedBaseUrl) ? { integrationId, endpointId: selectedEndpoint } : undefined" />
              <ng-template #nothingToSee>
                <nothing-to-see />
              </ng-template>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
