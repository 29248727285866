<div class="schema_table_header" *ngVar="('ADMIN' | minRole) as isAdmin">
  <div class="title_row">
    <div *ngIf="icon" class="icon_wrapper title_icon">
      <mat-icon class="icon" [svgIcon]="icon" />
    </div>
    <div *ngIf="!isSearching" class="title truncate {{ icon && 'title_with_icon' }}">{{ title }}</div>
    <input matInput type="text" *ngIf="isSearching" class="search_input" placeholder="Search {{ title }}"
           (input)="searchAction($event)" />
    <div class="badge_and_buttons">
      <ng-content select="[badge]"></ng-content>
      <div *ngIf="isModified" class="badge">Unsaved</div>
      <button *ngIf="generateTypeScriptInterface.observed && !hideGenerateTypeScriptInterface"
              (click)="generateTypeScriptInterface.emit()"
              class="edit_button secondary_icon_button"
              mat-flat-button
              title="Generate TypeScript interface for {{title}}">
        <mat-icon class="typescript_icon" svgIcon="typescript" />
      </button>
      <div *ngIf="edit.observed && !hideEdit && method" class="column outline_badge" [ngClass]="method | lowercase">
        {{ method | uppercase }}
      </div>
      <button *ngIf="edit.observed && !hideEdit"
              (click)="edit.emit()"
              class="edit_button secondary_icon_button"
              mat-flat-button
              title="Edit {{title}}">
        <mat-icon class="edit_icon" svgIcon="edit_icon" />
      </button>
      <button
        *ngIf="search.observed"
        (click)="onSearchClick()"
        [disabled]="!isAdmin"
        class="plus_button secondary_icon_button"
        mat-flat-button
        title="Add item">
        <mat-icon class="edit_icon {{ this.isSearching && 'close_icon' }}"
                  svgIcon="{{ this.isSearching ? 'close_icon' : 'search_icon' }}" />
      </button>
      <div *ngIf="add.observed && !hideAdd" [matTooltip]="isAdmin ? '' : 'You need to be an admin to add items'">
        <button
          (click)="add.emit()"
          [disabled]="!isAdmin"
          class="edit_button secondary_icon_button"
          mat-flat-button
          title="Add item">
          <mat-icon class="edit_icon" svgIcon="plus_icon" />
        </button>
      </div>
    </div>
  </div>
</div>

