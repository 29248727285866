import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { CreateSecretDialogComponent } from './create-secret-dialog/create-secret-dialog.component';
import { SecretService } from './secret.service';
import { UpdateSecretDialogComponent } from './update-secret-dialog/update-secret-dialog.component';
import { CpSecret } from '@squidcloud/console-common/types/application.types';

@Injectable({
  providedIn: 'root',
})
export class SecretUiService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly globalUiService: GlobalUiService,
    private readonly secretService: SecretService,
    private readonly snackBar: SnackBarService,
  ) {}

  showCreateSecretDialog(): MatDialogRef<CreateSecretDialogComponent> {
    const config: MatDialogConfig = {
      maxWidth: '496px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
    };
    return this.dialog.open(CreateSecretDialogComponent, config);
  }

  async showCreationScriptDialog(): Promise<void> {
    const apiScript = await this.secretService.getCreationScript();
    this.globalUiService.showDocDialog(
      {
        params: {
          apiScript,
        },
        mdFilePath: 'assets/secrets/api-script-template.md',
      },
      900,
    );
  }

  showDeleteSecretDialog(secret: CpSecret): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting a secret cannot be undone or recovered. This will permanently delete the <span class="bold">${secret.key}</span> secret.`,
      async () => {
        try {
          await this.secretService.deleteSecret(secret.key);
          this.snackBar.success('Secret deleted');
        } catch (e) {
          console.error('Unable to delete secret', e);
          this.snackBar.warning('Unable to delete secret, please try again later');
        }
      },
    );
  }

  showUpdateSecretDialog(secret: CpSecret): void {
    this.dialog.open(UpdateSecretDialogComponent, {
      maxWidth: '496px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: secret,
    });
  }
}
