import { Injectable } from '@angular/core';
import { generateShortId, IntegrationSchemaType, Squid } from '@squidcloud/client';
import { truthy } from 'assertic';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { IntegrationService } from '../../integration.service';
import { BaseSchemaService } from '../base-schema.service';
import { findAvailableDuplicateFieldName } from '../utils';
import { DataSchemaFieldType } from '@squidcloud/internal-common/schema/schema.types';
import { DatabaseIntegrationConfig, isNoSqlDatabase } from '@squidcloud/internal-common/types/integrations/schemas';
import { IntegrationDataSchema } from '@squidcloud/internal-common/types/integrations/database.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { pascalCase } from 'change-case';
import { cloneDeep, isEqual, isNil } from '@squidcloud/internal-common/utils/object';

@Injectable({
  providedIn: 'root',
})
export class DataSchemaService<
  T extends DatabaseIntegrationConfig = DatabaseIntegrationConfig,
> extends BaseSchemaService<T, IntegrationDataSchema> {
  constructor(
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    snackBar: SnackBarService,
    squid: Squid,
  ) {
    super(applicationService, integrationService, snackBar, squid);
  }

  override getEmptySchema(): IntegrationDataSchema {
    return { type: IntegrationSchemaType.data, collections: {} };
  }

  override async discoverSchema(): Promise<IntegrationDataSchema | undefined> {
    return await this.discoverSchemaInternal();
  }

  setField(
    collectionName: string,
    currentName: string | undefined,
    newName: string,
    type: DataSchemaFieldType,
    min: number | undefined,
    max: number | undefined,
    defaultValue: string | number | boolean | null | undefined,
    primaryKey: boolean,
    required: boolean,
    description: string | undefined,
  ): void {
    const schema = this.getSchema();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    collection.properties = collection.properties || {};
    const properties = collection.properties;
    const isEdit = currentName !== undefined;

    if (isEdit) {
      const requiredArray = (collection.required || []) as string[];
      const index = requiredArray.findIndex(requiredField => requiredField === currentName);
      if (index >= 0) {
        requiredArray.splice(index, 1);
      }
      if (newName !== currentName) {
        properties[newName] = properties[currentName];
        delete properties[currentName];
      }
    }

    // TODO: Add data types.
    const field = properties[newName] || {};
    if (type === 'objectId') {
      field.type = 'string';
      field.dataType = 'objectId';
    } else if (type === 'date') {
      field.type = 'object';
      field.isDate = true;
    } else if (type === 'json') {
      field.type = 'object';
      field.isJSON = true;
    } else {
      field.type = type;
    }

    if (min !== undefined || max !== undefined) {
      if (type === 'string') {
        field.minLength = min;
        field.maxLength = max;
      } else {
        field.minimum = min;
        field.maximum = max;
      }
    }

    field.default = defaultValue;
    field.primaryKey = primaryKey;
    field.description = description;

    if (required) {
      const requiredArray = (collection.required || []) as string[];
      requiredArray.push(newName);
      collection.required = requiredArray;
    } else {
      field.nullable = true;
    }

    properties[newName] = field;

    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, newName]);
    this.schemaSubject.next(schema);
  }

  toggleHiddenField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    collection.properties = collection.properties || {};
    const properties = collection.properties;

    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, fieldName]);

    const field = properties[fieldName] || {};
    field.hidden = !field.hidden;

    this.schemaSubject.next(schema);
  }

  addCollectionToSchema(collectionName: string): void {
    const schema = this.getSchema();
    schema.collections[collectionName] = {};
    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  deleteCollectionFromSchema(collectionName: string): void {
    const schema = this.getSchema();
    delete schema.collections[collectionName];
    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  duplicateField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collection = schema.collections[collectionName];
    const properties = collection.properties || {};
    const duplicateName = findAvailableDuplicateFieldName(properties, fieldName);
    properties[duplicateName] = cloneDeep(properties[fieldName]);
    if (collection.required?.includes(fieldName)) {
      collection.required = [...collection.required, duplicateName];
    }
    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, duplicateName]);
    this.schemaSubject.next(schema);
  }

  deleteCollectionField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collectionSchema = schema.collections[collectionName];
    delete collectionSchema.properties?.[fieldName];

    // Delete the `required` field if it exists
    const requiredSet = new Set(collectionSchema.required || []);
    requiredSet.delete(fieldName);
    collectionSchema.required = Array.from(requiredSet);

    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  generateTypeScriptTypes(collectionName: string): string | undefined {
    const schema = this.schemaSubject.value;
    if (!schema) {
      return undefined;
    }

    const collectionSchema = schema.collections[collectionName];
    if (!collectionSchema) {
      return undefined;
    }

    const properties = collectionSchema.properties || {};
    const required = collectionSchema.required || [];
    const types = Object.entries(properties).map(([fieldName, fieldSchema]) => {
      const requiredField = required.includes(fieldName);
      let type = fieldSchema.type === 'object' && fieldSchema.isDate ? 'Date' : fieldSchema.type || 'any';
      if (type === 'array') {
        type = '[]';
      }

      const optional = !requiredField ? '?' : '';
      return `\t${fieldName}${optional}: ${type};`;
    });
    return `export interface ${pascalCase(collectionName)} {\n${types.join('\n')}\n}`;
  }

  private titleCase(str: string): string {
    return (
      str
        .toLowerCase()
        // Split by spaces or underscores
        .split(/[\s_]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        // Join words with a space, replace this with '_' if you want to retain underscores
        .join(' ')
    );
  }

  updateCollection(
    currentCollectionName: string,
    newName: string,
    allowExtraFields = false,
    description?: string,
  ): void {
    const schema = this.getSchema();

    if (currentCollectionName !== newName) {
      this.modifications.clearPath(currentCollectionName);
      schema.collections[newName] = schema.collections[currentCollectionName];
      delete schema.collections[currentCollectionName];
    }

    const newSchema = schema.collections[newName];
    newSchema.additionalProperties = allowExtraFields;
    newSchema.description = description;
    this.modifications.modifyPath(newName);
    this.schemaSubject.next(schema);
  }

  async deleteBuiltInCollection(collectionName: string): Promise<void> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    await callBackendExecutable(this.squid, 'deleteBuiltInCollection', {
      organizationId: application.organizationId,
      applicationId: application.appId,
      integrationId: truthy(this.integration?.id, 'Error retrieving the integrationId.'),
      collectionName,
    });
  }

  private async discoverSchemaInternal(): Promise<IntegrationDataSchema | undefined> {
    const application = this.applicationService.getCurrentApplicationOrFail();

    const discovery = await callBackendExecutable(this.squid, 'discoverDataConnectionSchema', {
      id: `discoverDataConnectionSchema_${generateShortId()}`,
      type: truthy(this.integration?.type),
      configuration: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        connectionOptions: truthy(this.integration as any).configuration?.connectionOptions,
      },
      appId: application.appId,
    });

    const isSql = !isNoSqlDatabase(truthy(this.integration?.type));

    const currentSchema = this.schemaSubject.value;
    const schema: IntegrationDataSchema = currentSchema || { type: IntegrationSchemaType.data, collections: {} };
    for (const [collectionName, discoveredSchema] of Object.entries(discovery.schema?.collections || {})) {
      const currentCollectionSchema = currentSchema?.collections?.[collectionName];
      if (!currentCollectionSchema) {
        schema.collections[collectionName] = discoveredSchema;
        this.modifications.modifyPath(collectionName);
      } else if (!isEqual(currentCollectionSchema, discoveredSchema)) {
        let modificationMade = false;

        const existingSchema = schema.collections[collectionName];

        // Adds any properties that are not in the existing schema. For SQL db's, it will only update type, required,
        // and primaryKey values if they do not match, as well as remove fields which are not in the discovered schema.
        const properties = existingSchema?.properties;
        if (properties) {
          Object.entries(discoveredSchema.properties || {}).forEach(([key, value]) => {
            const currentValue = properties?.[key];
            if (!currentValue) {
              properties[key] = value;
              if (discoveredSchema.required?.includes(key) && !existingSchema.required?.includes(key)) {
                existingSchema.required = (existingSchema.required ?? []).concat(key);
              }
              modificationMade = true;
              this.modifications.modifyPath([collectionName, key]);
            } else if (isSql) {
              if (discoveredSchema.required?.includes(key) && !existingSchema.required?.includes(key)) {
                existingSchema.required = (existingSchema.required ?? []).concat(key);
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              } else if (!discoveredSchema.required?.includes(key) && existingSchema.required?.includes(key)) {
                existingSchema.required = existingSchema.required.filter(element => element !== key);
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }

              if (currentValue['type'] !== value['type']) {
                properties[key]['type'] = value['type'];
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }
              if (!!currentValue['primaryKey'] !== !!value['primaryKey']) {
                properties[key]['primaryKey'] = value['primaryKey'];
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }
            }
          });

          // For SQL integrations, remove any collections which are not discovered.
          if (isSql) {
            Object.keys(properties || {}).forEach(key => {
              if (isNil(discoveredSchema.properties?.[key])) {
                delete properties?.[key];
                modificationMade = true;
              }
            });
          }
        }

        if (modificationMade) {
          this.modifications.modifyPath(collectionName);
        }
      }
    }

    if (isSql) {
      Object.entries(currentSchema?.collections || {}).forEach(([collectionName]) => {
        if (!discovery.schema?.collections[collectionName]) {
          delete currentSchema?.collections[collectionName];
        }
      });
    }

    this.schemaSubject.next(schema);
    return schema;
  }
}
