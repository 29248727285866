/** A type alias for an application id. */
export type AppId = string;
export const ENVIRONMENT_IDS = ['dev', 'prod'] as const;
export type EnvironmentId = (typeof ENVIRONMENT_IDS)[number];
export type SquidDeveloperId = string;

// noinspection JSUnusedGtypeslobalSymbols
/** @deprecated. Use ENVIRONMENT_IDS. */
export const allEnvironmentIds: Array<EnvironmentId> = [...ENVIRONMENT_IDS];

/** A type alias for an integration id. */
export type IntegrationId = string;

export type ClientId = string;

export type SquidDocId = string;

export type ClientRequestId = string;
