import { normalizeJsonAsString } from '../utils/serialization';
import { AppId, ClientId, ClientRequestId } from '../public-types/communication.public-types';
import { Condition, Operator, Query } from '../public-types/query.public-types';
import { FieldType, PrimitiveFieldType } from '../public-types/document.public-types';
import { isEqual, isNil } from '../utils/object';
import { truthy } from 'assertic';

/** @internal */
export type QuerySubscriptionId = `${ClientId}_${ClientRequestId}`;

/** @internal */
export interface QueryRequest {
  clientRequestId: ClientRequestId;
  query: Query;
  subscribe: boolean;
}

/** @internal */
export interface QueryUnsubscribeRequest {
  clientRequestId: ClientRequestId;
}

/** @internal */
interface QueryMetadata {
  condCount: number;
}

/**
 * Example query mapping:
 * Queries:
 *    // All people
 *    cid_123 = collection('person').query()
 *    // People with age 10, 11 or 12
 *    cid_321 = collection('person').query().in('age', [10, 11, 12])
 *    // People with age > 20
 *    cid_456 = collection('person').query().gt('age', 20)
 *    // People with age between 20 and 30
 *    cid_789 = collection('person').query().gt('age', 20).lt('age', 30)
 *
 *    // Anyone who in sort-order <name, reverse age> is after 10-year-old Yossi
 *    cid_sort_order = collection('person').query().composite(['name', '>', 'Yossi'], ['age', '<', 10])
 *
 *    // People who aren't Yossi or Nir
 *    cid_not_in = collection('person').query().not_in('name', ['Yossi', 'Nir'])
 */
/** @internal */
export function encodeCondition(condition: Condition<any>): EncodedCondition {
  return normalizeJsonAsString(condition);
}

/* @internal */
export type EncodedCondition = string;
/* @internal */
export type QueryMapping<QueryReferenceType extends string> = {
  unconditional: Array<QueryReferenceType>;
  conditional: Record<EncodedCondition, Array<QueryReferenceType>>;
  queriesMetadata: Record<QueryReferenceType, QueryMetadata>;
};

/** @internal */
export abstract class QueryMappingManager<T> {
  abstract addQuery(appId: AppId, query: Query, key: T): Promise<void>;

  abstract removeQuery(appId: AppId, key: T): Promise<Query | undefined>;

  abstract removeLocalDevAppData(appId: AppId): Promise<void>;
}

/** @internal */
export function compareOperator(conditionValue: FieldType, valueInDocument: FieldType, operator: Operator): boolean {
  conditionValue = conditionValue instanceof Date ? conditionValue.getTime() : conditionValue ?? null;
  valueInDocument = valueInDocument instanceof Date ? valueInDocument.getTime() : valueInDocument ?? null;

  if (operator === '==') {
    return isEqual(conditionValue, valueInDocument);
  }
  if (operator === '!=') {
    return !isEqual(conditionValue, valueInDocument);
  }

  // Nulls can only be compared for (in)equality, not other operators.
  switch (operator) {
    case '<':
      if (isNil(conditionValue)) return false;
      if (isNil(valueInDocument)) return true;
      return valueInDocument < conditionValue;
    case '<=':
      if (isNil(valueInDocument)) {
        return true;
      }
      if (isNil(conditionValue)) {
        return false;
      }
      return valueInDocument <= conditionValue;
    case '>':
      if (isNil(valueInDocument)) return false;
      if (isNil(conditionValue)) return true;
      return valueInDocument > conditionValue;
    case '>=':
      if (isNil(conditionValue)) {
        return true;
      }
      if (isNil(valueInDocument)) {
        return false;
      }
      return valueInDocument >= conditionValue;
    case 'like':
      return (
        typeof valueInDocument === 'string' &&
        typeof conditionValue === 'string' &&
        isStringMatch(valueInDocument, conditionValue, false)
      );
    case 'not like':
      return !(
        typeof valueInDocument === 'string' &&
        typeof conditionValue === 'string' &&
        isStringMatch(valueInDocument, conditionValue, false)
      );
    case 'like_cs':
      return (
        typeof valueInDocument === 'string' &&
        typeof conditionValue === 'string' &&
        isStringMatch(valueInDocument, conditionValue, true)
      );
    case 'not like_cs':
      return !(
        typeof valueInDocument === 'string' &&
        typeof conditionValue === 'string' &&
        isStringMatch(valueInDocument, conditionValue, true)
      );
    case 'array_includes_some': {
      const valArray = valueInDocument as Array<PrimitiveFieldType>;
      return (
        Array.isArray(valueInDocument) &&
        Array.isArray(conditionValue) &&
        conditionValue.some(val => truthy(valArray, 'VALUE_CANNOT_BE_NULL').some(docVal => isEqual(docVal, val)))
      );
    }
    case 'array_includes_all': {
      const valArray = valueInDocument as Array<PrimitiveFieldType>;
      return (
        Array.isArray(conditionValue) &&
        Array.isArray(valueInDocument) &&
        conditionValue.every(val => truthy(valArray, 'VALUE_CANNOT_BE_NULL').some(docVal => isEqual(docVal, val)))
      );
    }
    case 'array_not_includes': {
      const valArray = valueInDocument as Array<PrimitiveFieldType>;
      return (
        Array.isArray(valueInDocument) &&
        Array.isArray(conditionValue) &&
        conditionValue.every(val => !truthy(valArray, 'VALUE_CANNOT_BE_NULL').some(docVal => isEqual(docVal, val)))
      );
    }
    default:
      throw new Error(`Unsupported operator comparison: ${operator}`);
  }
}

/** @internal */
function isStringMatch(str: string, pattern: string, caseSensitive: boolean): boolean {
  if (!caseSensitive) {
    str = str.toLowerCase();
    pattern = pattern.toLowerCase();
  }

  const regexPattern = replaceSpecialCharacters(pattern);

  // Create regex object and test if string matches
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(str);
}

/**
 * Generates the regex pattern, handling special characters as follows:
 *  - `_` is replaced with a `.`
 *  - `%` is replaced with `[\s\S]*`.
 *  - The above characters can be escaped with \, eg. `\_` is replaced with `_` and `\%` with `%`.
 *  - All special characters in regex (-, /, \, ^, $, *, +, ?, ., (, ), |, [, ], {, }) get escaped with \
 *
 *  Exported for testing purposes.
 * */
export function replaceSpecialCharacters(input: string): string {
  let result = '';

  for (let i = 0; i < input.length; ++i) {
    if (input[i] === '\\') {
      if (i + 1 < input.length && ['%', '_'].includes(input[i + 1])) {
        result += input[i + 1];
        i++;
      } else if (i + 1 < input.length && input[i + 1] === '\\') {
        result += '\\\\';
        i++;
      } else {
        result += '\\';
      }
    } else if (input[i] === '%') {
      // Replace '%' wildcard with regex equivalent. Note: this allows for newlines, unlike .*
      result += '[\\s\\S]*';
    } else if (input[i] === '_') {
      result += '[\\s\\S]';
    } else {
      // Escape special regex characters in the pattern
      // '\' is checked above and needs to be manually escaped by the user
      if ('/-\\^$*+?.()[]{}|'.includes(input[i])) {
        result += '\\';
      }
      result += input[i];
    }
  }

  return result;
}

/**
 * Returns a unique identifier for the query which includes both the client id and the client request id.
 * @internal
 */
export function getQuerySubscriptionId(clientId: string, clientRequestId: string): QuerySubscriptionId {
  return `${clientId}_${clientRequestId}`;
}

/** @internal */
export function parseQuerySubscriptionId(querySubscriptionId: QuerySubscriptionId): {
  clientId: string;
  clientRequestId: string;
} {
  const splitString = querySubscriptionId.split('_');
  return {
    clientId: splitString[0],
    clientRequestId: splitString[1],
  };
}

/** @internal */
export interface QueryRegisterRequest {
  clientRequestId: ClientRequestId;
  query: Query;
  parentClientRequestId: ClientRequestId;
}
