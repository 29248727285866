<div *ngVar="organizationObs | async as organization" class="application_details">
  <illustration
    class="mt_24"
    icon="no_applications"
    [title]="title"
    [description]="description"
  >
    <button (click)="createApplication()"
            class="primary_button large_button"
            mat-flat-button>
      <mat-icon class="plus_icon" svgIcon="plus_icon"></mat-icon>
      Create Application
    </button>
  </illustration>
</div>
