import { AiAudioTranscribeOptions } from './public-types';
import { RpcManager } from './rpc.manager';
import { BlobAndFilename } from './types';

export class AiAudioClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async transcribe(
    file: File | BlobAndFilename,
    options: AiAudioTranscribeOptions = { modelName: 'whisper-1' },
  ): Promise<string> {
    return this.rpcManager.post<string>('ai/audio/transcribe', options, [file]);
  }
}
