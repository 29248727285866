import { ServiceFunctionName } from '@squidcloud/internal-common/types/bundle-data.types';
import { AppId } from '@squidcloud/client';

export const BUNDLE_DATA_FUNCTION_TYPES = [
  'executable',
  'openapi',
  'scheduler',
  'security',
  'trigger',
  'webhook',
] as const;

export type BundleDataFunctionType = (typeof BUNDLE_DATA_FUNCTION_TYPES)[number];

export interface GetBackendMetricsRequest {
  appId: AppId;
}

export interface BackendFunctionExecutionCounts {
  errors: number;
  success: number;
}

export interface ExtractedBundleDataFunction {
  type: BundleDataFunctionType;
  serviceFunctionName: ServiceFunctionName;
  extras: Record<string, unknown>;
}

export interface BundleDataFunctionRow {
  functionData: ExtractedBundleDataFunction;
  counts: BackendFunctionExecutionCounts;
}

export interface BackendMetricsChartData {
  successExecutionCountSeries: Array<number>;
  errorExecutionCountSeries: Array<number>;
  averageExecutionTimeSeries: Array<number>;
  averageQpsSeries: Array<number>;
  timestampSeries: Array<string>;
  averageExecutionTime: number;
  averageQps: number;
  totalExecutions: number;
  totalErrors: number;
}

export interface BackendMetricsSection {
  functions: Array<BundleDataFunctionRow>;
  charts: BackendMetricsChartData;
}

export interface GetBackendMetricsResponse {
  appId: AppId;
  functionsExecutionCounts: Record<BundleDataFunctionType, BackendMetricsSection>;
}

/**
 * Returns service name part (class name) from the fully qualified service function name.
 * Example: MetricsApiService:migrateApplicationsToClickHouse -> MetricsApiService
 */
export function getServiceNameFromFunctionName(serviceName: string): string {
  return serviceName.split(':')[0];
}
