/*** Common components used by admin & app modules. */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoadingIndicatorComponent } from '@squidcloud/console-web/app/global/components/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from '@squidcloud/console-web/app/global/components/page-header/page-header.component';
import { MatCellDefTypedByDirective } from '@squidcloud/console-web/app/global/directives/mat-cell-def-typed-by.directive';
import { MatRowDefTypedByDirective } from '@squidcloud/console-web/app/global/directives/mat-row-def-typed-by.directive';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarMenuItemComponent } from '@squidcloud/console-web/app/global/components/sidebar-layout/sidebar-menu-item/sidebar-menu-item.component';
import { MatIconModule } from '@angular/material/icon';
import { DialogCloseButtonComponent } from '@squidcloud/console-web/app/global/components/dialog-close-button/dialog-close-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { HeaderUserDropdownComponent } from '@squidcloud/console-web/app/global/components/header-user-dropdown/header-user-dropdown.component';
import { AvatarComponent } from '@squidcloud/console-web/app/account/avatar/avatar.component';
import { MatMenuModule } from '@angular/material/menu';

const commonComponents = [
  DialogCloseButtonComponent,
  LoadingIndicatorComponent,
  MatCellDefTypedByDirective,
  MatRowDefTypedByDirective,
  PageHeaderComponent,
  SidebarMenuItemComponent,
  HeaderUserDropdownComponent,
  AvatarComponent,
];

@NgModule({
  declarations: commonComponents,
  exports: commonComponents,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgOptimizedImage,
    RouterModule,
  ],
})
export class ConsoleCommonModule {}
