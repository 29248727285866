import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { BackendService } from '../backend.service';

@Component({
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhooksComponent extends OnDestroyComponent {
  bundleDataTableDataObs: Observable<BundleDataTableData | undefined>;

  constructor(private readonly backendService: BackendService) {
    super();
    this.backendService.reportBackendMetadataAvailable(false);

    this.bundleDataTableDataObs = this.backendService.getMetricsObs(this.onDestroy).pipe(
      map(metricsResponse => {
        const metrics = metricsResponse.functionsExecutionCounts?.['webhook'];
        if (!metrics) return undefined;
        const rows: Array<BundleDataTableRow> = metrics.functions
          .sort((m1, m2) => {
            const extras1 = m1.functionData.extras;
            const extras2 = m2.functionData.extras;
            const link1 = extras1['url'] as string;
            const link2 = extras2['url'] as string;
            return link1.localeCompare(link2);
          })
          .map(metric => {
            const functionData = metric.functionData;
            const counts = metric.counts;
            const extras = functionData.extras;
            const link = extras['url'] as string;
            const linkPathStartIdx = link.indexOf('/webhooks/');
            const webhookPath = link.substring(linkPathStartIdx);
            const columns: Array<BundleDataTableColumn> = [
              {
                value: webhookPath,
                link,
                tooltip: link,
                target: '_blank',
              },
              { value: `${counts.success}` },
              { value: `${counts.errors}`, class: 'error' },
              {
                value: functionData.serviceFunctionName,
                tooltip: functionData.serviceFunctionName,
              },
            ];
            return { columns, link: '/someLink' };
          });

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length) return undefined;

        return {
          headerRow: ['Webhook URL', 'Executions', 'Errors', 'Function'],
          rows,
          metrics: metrics.charts,
        };
      }),
    );
  }
}
