<ng-container *ngIf="tasksObs | async as tasks">
  <div class="quick_start_flyout_outer" *ngVar="expandedObs | async as isExpanded" [ngClass]="{expanded: isExpanded}">
    <div class="quick_start_flyout_inner">
      <div class="header" *ngIf="getCounts(tasks) as counts">
        <mat-icon class="close_button" svgIcon="close_icon" (click)="closeFlyout()"></mat-icon>
        <mat-icon class="tasks_rudder" [svgIcon]="getRudderImageSvg(counts)"></mat-icon>
        <div class="metadata">
          <div class="title" data-testid="quick-start-tasks-title">Quick Start Tasks</div>
          <div class="count">{{ counts.completed }} of {{ counts.total }} completed</div>
        </div>
      </div>
      <div class="task_sections">
        <div class="section next_section" *ngIf="getNextTask(tasks) as nextTask">
          <div class="section_title">Next up</div>
          <div class="tasks">
            <quick-start-task [task]="nextTask"
                              taskDisplayType="next_task"
                              (markComplete)="markTaskComplete(nextTask)"
                              (startTask)="taskClicked(nextTask)">
            </quick-start-task>
          </div>
        </div>
        <ng-container *ngIf="getOpenTasks(tasks) as openTasks">
          <div class="section open_tasks_section" *ngIf="openTasks.length">
            <div class="section_title">Open tasks</div>
            <div class="tasks">
              @for (task of openTasks; track task.id) {
                <quick-start-task [task]="task"
                                  (markComplete)="markTaskComplete(task)"
                                  (startTask)="taskClicked(task)"
                                  taskDisplayType="task" />
              }
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="getCompletedTasks(tasks) as completedTasks">
          <div class="section completed_tasks_section" *ngIf="completedTasks.length">
            <div class="section_title">Completed Tasks</div>
            <div class="tasks">
              @for (task of completedTasks; track task.id) {
                <quick-start-task [task]="task" taskDisplayType="completed_task" />
              }
            </div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</ng-container>
