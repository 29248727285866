import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { MetricsService } from '../metric/metrics.service';
import { GetBackendMetricsResponse } from '@squidcloud/console-common/types/metrics.types';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  backendMetadataAvailableSubject = new BehaviorSubject<boolean>(false);
  private metricsObs: Observable<GetBackendMetricsResponse> | undefined;

  constructor(
    private readonly metricsService: MetricsService,
    private readonly applicationService: ApplicationService,
  ) {}

  reportBackendMetadataAvailable(isAvailable: boolean): void {
    this.backendMetadataAvailableSubject.next(isAvailable);
  }

  observeBackendMetadataAvailable(): Observable<boolean> {
    return this.backendMetadataAvailableSubject.asObservable();
  }

  getMetricsObs(destroySubject: Subject<void>): Observable<GetBackendMetricsResponse> {
    if (this.metricsObs) {
      return this.metricsObs;
    }
    this.metricsObs = timer(0, 10 * MILLIS_PER_SECOND).pipe(
      takeUntil(destroySubject),
      switchMap(() => this.applicationService.currentApplication$),
      filter(Boolean),
      map(app => app.appId),
      switchMap(appId => this.metricsService.getMetrics(appId)),
      shareReplay(1),
      finalize(() => {
        this.metricsObs = undefined;
      }),
    );
    return this.metricsObs;
  }
}
