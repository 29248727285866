export function convertToHumanReadableNumber(number: number): string {
  if (!number) return '0';
  if (Number.isInteger(number)) {
    return number.toLocaleString('en-US');
  } else {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

export function trimNumber(num: number, fractionDigits = 2): number {
  if (!num) return 0;
  if (Math.floor(num) !== num) {
    return parseFloat(num.toFixed(fractionDigits));
  } else {
    return num;
  }
}
