import { AWS_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER } from '@squidcloud/console-common/types/marketplace-aws.types';
import { GCP_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER } from '@squidcloud/console-common/types/marketplace-gcp.types';
import {
  MarketplaceAccountSetupEventLsEntry,
  MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY,
} from '@squidcloud/console-common/types/marketplace.types';
import { MILLIS_PER_HOUR } from '@squidcloud/internal-common/types/time-units';

function selectNotExpiredEvents(
  events: Array<MarketplaceAccountSetupEventLsEntry> | undefined,
): Array<MarketplaceAccountSetupEventLsEntry> {
  const expirationDate = Date.now() + MILLIS_PER_HOUR;
  return (events || []).filter(
    (e: MarketplaceAccountSetupEventLsEntry) => (e.timestamp || 0) < expirationDate, // Remove expired entries.
  );
}

export function captureMarketplaceParametersOnAppInit(): void {
  const urlParams = new URLSearchParams(window.location.search);

  // Store (AWS|GCP)_MARKETPLACE_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER if present.
  for (const [param, type] of [
    [AWS_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER, 'aws'],
    [GCP_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER, 'gcp'],
  ] as const) {
    const mpSetupEventId = urlParams.get(param);
    if (mpSetupEventId) {
      try {
        const previousEvents = JSON.parse(localStorage.getItem(MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY) || '[]');
        const newEvents = selectNotExpiredEvents(previousEvents);
        const newEntry: MarketplaceAccountSetupEventLsEntry = {
          eventId: mpSetupEventId,
          timestamp: Date.now(),
          type,
        };
        newEvents.push(newEntry);
        localStorage.setItem(MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY, JSON.stringify(newEvents));
      } catch (e) {
        console.error(`Failed to handle ${type} marketplace redirect`, e);
      }
    }
  }
}

/** Returns a valid (non-expired) event id for GCP marketplace account setup. */
export function getActiveGcpMpAccountSetupEventId(): string | undefined {
  const events = JSON.parse(localStorage.getItem(MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY) || '[]');
  const notExpiredEvents = selectNotExpiredEvents(events);
  if (notExpiredEvents.length === 0) {
    return undefined;
  }
  return notExpiredEvents.find(e => e.type === 'gcp')?.eventId;
}

/** Removes event by id from the marketplace events local browser storage. */
export function forgetMpAccountSetupEventId(eventId: string): void {
  const events = JSON.parse(localStorage.getItem(MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY) || '[]');
  const newEvents = selectNotExpiredEvents(events).filter(e => e.eventId !== eventId);
  localStorage.setItem(MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY, JSON.stringify(newEvents));
}
